import moment from "moment-timezone";

interface ClientData {
  timezone?: string;
}

// Function to format time unit to string
export function formatTimeUnit(
  targetTime: number,
  isDate: boolean,
  clientData: ClientData
): string {
  if (!isDate) {
    if (targetTime >= 3600) {
      return (
        moment.utc(Math.abs(targetTime) * 1000).format("HH") +
        "h" +
        moment.utc(Math.abs(targetTime) * 1000).format("mm") +
        "m"
      );
    } else {
      return (
        moment.utc(Math.abs(targetTime) * 1000).format("mm") +
        "m" +
        moment.utc(Math.abs(targetTime) * 1000).format("ss") +
        "s"
      );
    }
  } else {
    if (targetTime >= 3600 && clientData && clientData.timezone) {
      return (
        moment.tz(moment.unix(targetTime), clientData.timezone).format("HH") +
        "h" +
        moment.tz(moment.unix(targetTime), clientData.timezone).format("mm") +
        "m"
      );
    } else if (clientData && clientData.timezone) {
      return (
        moment.tz(moment.unix(targetTime), clientData.timezone).format("mm") +
        "m" +
        moment.tz(moment.unix(targetTime), clientData.timezone).format("ss") +
        "s"
      );
    } else {
      return "";
    }
  }
}

// Function to check if the timestamp date is before the current day
export function isDateBeforeToday(
  timestampDate: number,
  clientData: ClientData
): boolean {
  const timezone = clientData.timezone || moment.tz.guess();
  const today = moment.tz(timezone).startOf("day");
  const targetDate = moment
    .tz(moment.unix(timestampDate), timezone)
    .startOf("day");
  return targetDate.isBefore(today);
}

// Removes the marks from the input string and converts it to uppercase
export function normalizeString(str: string | null | undefined): string {
  if (!str) return "";
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toUpperCase();
}
