import { HttpRequest } from "@/services/api/http";

export default new (class AnchorsApi extends HttpRequest {
  constructor() {
    super();
  }

  public async getAnchor(anchorId: number) {
    return this.axiosClient.get(`/anchors/${anchorId}`);
  }

  public async getAnchorPredictionAvgs(anchorId: number) {
    return this.axiosClient.get(`/anchors/prediction_averages/${anchorId}`);
  }

  public async getLastFive(anchorId: number) {
    return this.axiosClient.get(`/anchors/last_five/${anchorId}`);
  }

  public async getRecentRoutes(anchorId: number) {
    return this.axiosClient.get(`/anchors/recent_routes/${anchorId}`);
  }

  public async getNextPosition(anchorId: number) {
    return this.axiosClient.get(`/anchors/next_position/${anchorId}`);
  }

  public async getAnchorSimplified(anchorId: number) {
    return this.axiosClient.get(`/anchors/${anchorId}/simplified`);
  }

  public async getEssentialInformation(anchorId: number) {
    return this.axiosClient.get(`/users/latest_anchor_information/${anchorId}`);
  }

  public async getGlobalInformation(clientId: number) {
    return this.axiosClient.get(`/users/latest_global_information/${clientId}`);
  }

  public async getAnchorRoutes(anchorId: number) {
    return this.axiosClient.get(`/anchors/${anchorId}`);
  }

  public async getRefreshStatus() {
    return this.axiosClient.get(`/anchors/refresh_status/`);
  }

  public async getFirmwareVersions() {
    return this.axiosClient.get(`/anchor_firmwares/`);
  }

  public async updateAnchor(
    label: string,
    anchorId: number,
    staticIp: string,
    gateway: string,
    subnet: string,
    dnsPrimary: string,
    dnsSecondary: string,
    buzzerDisable: boolean,
    firmwareId: number,
    setup: boolean,
    updateSsids: boolean,
    updateWifiOptions: boolean
  ) {
    return this.axiosClient.put(`/anchors/${anchorId}`, {
      label,
      staticIp,
      gateway,
      subnet,
      dnsPrimary,
      dnsSecondary,
      buzzerDisable,
      firmwareId,
      setup,
      updateSsids,
      updateWifiOptions
    });
  }

  public async getAnchorPrediction(anchorId: number) {
    return this.axiosClient.get(`/anchors/prediction/${anchorId}`);
  }
})();
