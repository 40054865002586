<template>
  <div class="user-faq">
    <div class="wrapper">
      <button @click="handleClick" v-if="!this.isButtonActive">
        <div class="el-icon-d-arrow-right"></div>
      </button>
      <div class="index" v-if="showIndex">
        <el-row>
          <h3>Index</h3>
          <ul>
            <li>
              <el-link href="#live_map">Live Map</el-link>
            </li>
            <ul>
              <li>
                <el-link href="#status_bar">Status Bar</el-link>
              </li>
              <li>
                <el-link href="#right_panel">Right Panel</el-link>
              </li>
              <li><el-link href="#map">Map</el-link></li>
              <li>
                <el-link href="#edit_beacon">Edit Beacon</el-link>
              </li>
              <ul>
                <li>
                  <el-link href="#child_beacons">Child Beacons</el-link>
                </li>
              </ul>
            </ul>
            <li>
              <el-link href="#routes">Routes</el-link>
            </li>
            <ul>
              <li>
                <el-link href="#create_route">Create a Route</el-link>
              </li>
            </ul>
            <li>
              <el-link href="#analytics">Analytics</el-link>
            </li>
            <ul>
              <li>
                <el-link href="#search">Search</el-link>
              </li>
              <li>
                <el-link href="#results">Results</el-link>
              </li>
            </ul>
            <li>
              <el-link href="#predictions">Predictions</el-link>
            </li>
            <li>
              <el-link href="#settings">Settings</el-link>
            </li>
            <ul>
              <li>
                <el-link href="#edit_beacons_location"
                  >Edit Beacons Location</el-link
                >
              </li>
              <li>
                <el-link href="#edit_gateway_settings"
                  >Edit Gateway Settings</el-link
                >
              </li>
              <li>
                <el-link href="#adding_wireless_networks"
                  >Adding Wireless Networks</el-link
                >
              </li>
              <li>
                <el-link href="#add_a_new_user">Add a new User</el-link>
              </li>
              <li>
                <el-link href="#update_layout_image"
                  >Update Layout Image</el-link
                >
              </li>
            </ul>
            <li>
              <el-link href="#global_view">Global View</el-link>
            </li>
          </ul>
          <el-divider class="horizontal-divider"></el-divider>
          <h3>Downloads</h3>
          <ul>
            <li>
              <el-link
                href="https://drive.google.com/uc?export=download&id=13u-abrCh3Zl5s8NsjsDe92d8QyfkEKJx"
                >Dashboard Manual <i class="el-icon-document"
              /></el-link>
            </li>
            <li>
              <el-link
                href="https://drive.google.com/uc?export=download&id=1mqi3y2uFLY167sZxP2DiCvLcauA0rWjG"
                >System Hardware Manual <i class="el-icon-document"
              /></el-link>
            </li>
            <li>
              <el-link
                href="https://drive.google.com/uc?export=download&id=1906XMwdiMGiSQ_cyW7HcOCjASHRRtpUE"
                >Beacon Datasheet <i class="el-icon-document"
              /></el-link>
            </li>
            <li>
              <el-link
                href="https://drive.google.com/uc?export=download&id=1ms2_1PtVWcPB7xA88wJEUk4EGVVAKxNb"
                >Gateway Datasheet <i class="el-icon-document"
              /></el-link>
            </li>
            <li>
              <el-link
                href="https://drive.google.com/uc?export=download&id=1Rh7akV_Z_J6CSbdIwkGIIMs8G0A338-O"
                >IVT-System and WiFi Requirements <i class="el-icon-document"
              /></el-link>
            </li>
          </ul>
        </el-row>
        <button @click="handleClick" v-if="showIndex">
          <div class="el-icon-d-arrow-left"></div>
        </button>
      </div>
      <el-divider class="divider" direction="vertical"></el-divider>
      <div class="manual">
        <el-row>
          <el-col :span="24"
            ><div class="grid-content bg-purple-dark">
              <h1 id="live_map">Live Map</h1>

              <span
                >The Live Map page shows the current state of two tractor and
                recent past information.</span
              >
              <p><img src="@/assets/settings-images/livemap_all.png" /></p>
              <h3 id="status_bar">Status Bar</h3>
              <p>
                The status bar, located at the top of the page, provides
                important information about the gateway's current status and
                activity. It indicates whether the gateway has an active
                connection to the server, as well as its current position and
                any ongoing laps.
              </p>
              <p>
                If the tow tractor is on a lap, the lap duration and delay will
                be displayed on the status bar. The lap delay is calculated
                using either the route target time or the average duration of
                the last two weeks' routes. Additionally, if the gateway's power
                connector is disconnected, the status bar will display a signal
                indicating this. Also, information about the WiFi signal
                strength connection is displayed besides the Gateway label.
              </p>
              <p>
                The right side of the status bar includes an input text form
                that allows you to search for a beacon ID or beacon label. This
                can be useful for quickly locating a specific beacon and
                checking its status.
              </p>
              <h3 id="right_panel">
                Right Panel (metrics)
              </h3>
              <p>
                The right side panel of the live map page provides valuable
                information about the tow tractor’s recent activity. At the top
                of the panel, you can compare the current day's metrics with the
                average of the last 15 days, allowing you to see how the tow
                tractor is performing over time. Below this, the panel displays
                a list of recently completed laps, along with the last positions
                where the tow tractor passed.
              </p>
              <h3 id="map">Map</h3>
              <p>
                The main feature of the Live Map page is an interactive map that
                shows the tow tractor's current position. If a route has been
                defined for the tow tractor, the map will display beacons with
                green or orange circles around them. The green circle indicates
                the beacon where the tow tractor is currently located or the
                last known position, while the orange circle indicates the next
                position on the route.
              </p>
              <p>
                This allows you to easily track the tow tractor's progress and
                monitor its movements in real time. By using the map, you can
                quickly identify any deviations from the planned route and take
                corrective action if necessary. Additionally, the map can be
                zoomed in and out to provide a more detailed or broader view of
                the tow tractor's surroundings.
              </p>
              <img src="@/assets/settings-images/livemap_map.png" />
              <h3 id="edit_beacon">Edit Beacon</h3>
              <p>
                When a beacon is selected on the Live Map, its label and ID are
                displayed on the Status Bar, along with an Edit button. To edit
                the beacon, simply click the Edit button and the Edit Beacon
                dialog box will appear.
              </p>
              <img src="@/assets/settings-images/livemap_editbeacons.png" />
              <p>
                The top of the dialog box displays the Beacon ID, current
                battery state, and the last time the beacon was detected.
              </p>
              <p>
                The bottom part of the dialog box is where the beacon properties
                can be set.
              </p>
              <p>
                To remove a beacon from the map, the user must disable it by
                unchecking the "Set Beacon State" checkbox.
              </p>
              <p>
                The "Set Beacon Range" option allows the user to change the
                detection range of the gateway for the beacon.
              </p>
              <p>
                If a beacon stops working, it can be replaced with a new one
                without losing any historical data. To replace the beacon, the
                user must select the new beacon from the list "Replace Beacon".
              </p>
              <h3 id="child_beacons">Child Beacons</h3>
              <p>
                A child beacon is a beacon that acts as an extension of its
                parent beacon, allowing for the use of multiple beacons to cover
                a larger area. This feature is useful for tracking multiple
                paths along a route as a single point.
              </p>
              <p>
                For example, in a warehouse with multiple parking zones for a
                tow tractor, all of the beacons in those parking zones can be
                set as children of the main point to track the tow tractor's
                movements as a single position.
              </p>
              <img
                src="@/assets/settings-images/livemap_editbeacons_childbeaconsexample1.png"
              />
              <p>
                In order to monitor the multiple parking zones, the user must
                first place beacons in those positions. The user can follow the
                instructions in the "Edit Beacon Location" chapter to learn how
                to properly place the beacons.
              </p>
              <p>
                Once the beacons are in place, two of them can be set as
                children of the remaining beacon, allowing for the tracking of
                multiple parking zones as a single point.
              </p>
              <img
                src="@/assets/settings-images/livemap_editbeacons_childbeaconsexample2.png"
              />
              <p>
                After selecting a beacon to be the parent beacon, the user can
                click on it and open the Edit Beacon dialog by clicking on the
                "Edit" icon in the status bar.
              </p>
              <p>
                In the Edit Beacon dialog, the user can select the child beacons
                from the list and then click the "Add" button. Only beacons that
                are currently on the Live Map and not already in use on a route
                can be added as child beacons.
              </p>
              <img
                src="@/assets/settings-images/livemap_editbeacons_childbeaconsexample3.png"
              />
              <p>
                A list of child beacons will be created once the user has added
                all of the desired beacons to the list.
              </p>
              <img
                src="@/assets/settings-images/livemap_editbeacons_childbeaconsexample4.png"
              />
              <p>
                To remove a beacon from the list of child beacons, the user can
                click on the "Delete" button next to the beacon's label.
              </p>
              <p>
                Once all desired changes have been made, the user can click the
                "Save" button to apply the changes. The child beacons will then
                be displayed on the Live Map as small circles.
              </p>
              <img
                src="@/assets/settings-images/livemap_editbeacons_childbeaconsexample5.png"
              />
              <h1 id="routes">Routes</h1>
              <p>
                The Routes page is where the user can define the route that the
                tow tractor should follow. Once a tow tractor has been assigned
                a route, multiple data points will be logged for later analysis
                on the Analytics page.
              </p>
              <p>
                To create or edit a route, the user must go to the Routes page
                and define the route by adding an ordered list of beacons to the
                route. It is possible to use the same beacon multiple times
                along the route, allowing for greater flexibility in defining
                the route.
              </p>
              <h3 id="create_route">Create a Route</h3>
              <p>Select "Routes" from the top menu to open the routes page.</p>
              <img src="@/assets/settings-images/routes.png" />
              <p>
                To define a route, the user must click on the desired beacons in
                the order that they should be followed along the route. Each
                time the user clicks on a beacon, it will be added to the Route
                Beacons List panel. The user can re-order the beacons in the
                Route Beacons List panel by dragging a row to the desired
                position. This allows the user to easily adjust the order of the
                beacons in the route as needed.
              </p>
              <p>
                The Route Settings panel allows the user to define several
                parameters for the route, including the Target Route Duration,
                Route Timeout, Route Position Tolerance, Start Route On, and
                Finish Route On.
              </p>
              <ul>
                <li>
                  The “Target Route Duration” is the expected amount of time it
                  should take for the tow tractor to complete the lap. This time
                  is used to calculate the delay, which is the difference
                  between the expected and actual duration of the route. If the
                  Target Route Duration is not defined, the delay is calculated
                  using the average duration of the last two weeks. This allows
                  the user to set a target completion time for the route and
                  monitor the tow tractor's progress to ensure that it stays on
                  schedule.
                </li>
                <li>
                  The “Route Timeout” is the maximum amount of time that the tow
                  tractor can take to complete the route before it is considered
                  to have failed. If the tow tractor takes longer than the
                  defined Route Timeout to complete the route, it will be marked
                  as having failed, and an error will be logged. Setting the
                  Route Timeout to 0 disables the timeout, allowing the tow
                  tractor to take as long as it needs to complete the route.
                  This can be useful if the route is expected to take a very
                  long time to complete, or if the user does not want to impose
                  a time limit on the route. In either case, setting the Route
                  Timeout to 0 allows the tow tractor to continue along the
                  route without being marked as having failed.
                </li>
                <li>
                  Enabling the Route Position Tolerance allows for some
                  flexibility in the tow tractor's movements along the route,
                  while disabling it provides more strict enforcement of the
                  route path. If this setting is enabled, the tow tractor will
                  be allowed to deviate from the route. For example, if the tow
                  tractor skips a position in the predefined route path, the
                  system will tolerate the error and continue with the route.
                  However, if the Route Position Tolerance is disabled, the
                  route will be considered to have failed as soon as the tow
                  tractor deviates from the route path, even if the deviation is
                  small.
                </li>
                <li>
                  The “Start Route On” setting determines when the route will be
                  considered to have started. With the "Start Route On: Enter"
                  option, the route is considered to have started as soon as the
                  tow tractor arrives at the first position in the route. The
                  time that the tow tractor spends at this beacon will be
                  logged, and will be included in the overall duration of the
                  route. With the "Start Route On: Left" option, the route is
                  considered to have started only after the tow tractor has left
                  the first position. In this case, the time that the tow
                  tractor spends at the first beacon will not be logged, and
                  will not be included in the overall duration of the route.
                </li>
                <li>
                  With the "Finish Route On: Enter" option, the time spent on
                  the last position is not logged because the lap is considered
                  finished as soon as the tow tractor arrives at the last
                  position. With the "Finish Route On: Left" option, the time
                  spent on the last position is logged because the lap is not
                  considered finished until the tow tractor has left the last
                  position.
                </li>
              </ul>
              <p>
                These settings can be useful for ensuring that the route is
                tracked and logged accurately, and for providing accurate data
                about the tow tractor's performance.
              </p>
              <h1 id="analytics">Analytics</h1>
              <p>
                The positions analytics page provides users with valuable
                insights into the performance of their tow tractor. The heatmaps
                on this page show the positions where the tow tractor spends the
                most time or passes through the most frequently. This
                information can be useful for identifying areas of the warehouse
                where the tow tractor may be experiencing difficulty, or for
                identifying areas that are heavily used and may need to be
                optimized for better performance.
              </p>
              <p>
                The route analytics page provides even more detailed information
                about the performance of the tow tractor. This page includes
                histograms that show the duration of the routes, the number of
                routes performed, and other metrics that can help users
                understand how their tow tractor is performing.
              </p>
              <p>
                The date interval for the analysis can be chosen using the form
                on the page at the top, allowing users to focus on specific time
                periods and compare the performance of the tow tractor over
                time.
              </p>
              <p>
                Overall, the analytics pages provide users with valuable
                insights that can help them optimize the performance of their
                tow tractor and improve their warehouse operations.
              </p>
              <a href="@/assets/settings-images/analytics.png" taget="_blank">
                <img src="@/assets/settings-images/analytics.png" />
              </a>
              <h3 id="search">
                Search
              </h3>
              <p>
                The search form allows users to search for specific routes and
                filter the results by various criteria.
              </p>
              <p>
                By using the date filter, users can specify the period of time
                they are interested in, and only view routes that finished
                within that time frame.
              </p>
              <p>
                The work shift interval can also be used to filter the results
                and only view routes that were completed within a specific
                shift. This can be useful for understanding how the tow tractor
                is performing at different times of the day or week.
              </p>
              <p>
                In addition, users can filter the results by route duration,
                which can be useful for identifying routes that were
                particularly fast or slow.
              </p>
              <p>
                The user can use the "Routes Shown" switch to choose whether
                they want to see data for valid routes or invalid routes.
              </p>
              <h3 id="results">
                Results
              </h3>
              <p>
                At the top of the results, some global metrics are displayed,
                such as the total number of valid routes, the total number of
                invalid routes, the average duration of the routes, the fastest
                route, and the slowest route.
              </p>
              <p>
                After the global metrics, there is a histogram that shows the
                number of routes by duration. This histogram provides users with
                a visual representation of the distribution of route durations
                and can help them to understand how long the routes typically
                take to complete.
              </p>
              <p>
                After the histogram, there are two plots: one that shows the
                average time on the checkpoint and another that shows the
                frequency of checkpoints. The plot that shows the average time
                that the tow tractor spent at a checkpoint includes both the
                time spent at the checkpoint and the time spent traveling to the
                next position. The plot that shows the frequency on checkpoint
                displays the number of times that the tow tractor has visited
                each checkpoint along the route.
              </p>
              <img src="@/assets/settings-images/analytics_average.png" />
              <p>
                The last two plots on the route analytics page in the Azitek tow
                tractor tracking system are hour-based analyses, which provide
                users with detailed insights into the performance of the tow
                tractor on an hourly basis.
              </p>
              <p>
                The first plot is an histogram that shows the number of routes
                completed per hour. This plot provides users with a visual
                representation of how many routes the tow tractor has completed
                during each hour of the day, and can help them to understand the
                overall workload of the tow tractor.
              </p>
              <p>
                The second plot is a line graph that shows the average duration
                of routes per hour. This plot provides users with a sense of how
                long the routes typically take to complete during each hour of
                the day, and can help them to identify any trends or patterns in
                the data.
              </p>
              <p>
                The last two plots are hour-based analyses, which provide users
                with detailed insights into the performance of the tow tractor
                on an hourly basis and can help them to identify any trends or
                patterns in the data. The first plot is an histogram that shows
                the number of routes completed per hour. The second plot shows
                the average duration of routes per hour.
              </p>
              <p>
                After the plots there is a list of the routes along with
                detailed information about each route. This list includes the
                the start date and end date of the route, the duration of the
                route, the status of the route (e.g., valid or invalid).
              </p>
              <h1 id="predictions">Predictions</h1>
              <p>
                The predictions page allows users to obtain the estimated time
                of arrival of the train at a given position. This page is useful
                for, for example, an operator at a workstation to know when the
                train is expected to arrive.
              </p>
              <p>
                We can show the next points based on the current position of the
                train, or fix a position. The selected position is the first in
                the table.
              </p>
              <h1 id="settings">Settings</h1>
              <p>
                The "Settings" page is opened by clicking on Settings from the
                Top Menu.
              </p>
              <img src="@/assets/settings-images/settings.png" />

              <h3 id="edit_beacons_location">
                Edit Beacons Location
              </h3>
              <p>
                To start organizing the beacon's location around the layout, the
                user can click on the "Edit Beacons Location" button on the
                Beacons Locations tab.
              </p>
              <img
                src="@/assets/settings-images/settings_edit_beacon_location.png"
              />
              <p>
                When the user clicks on the "Edit Beacons Location" button on
                the Beacons Location tab, the dashboard enters edit beacon
                location mode. In this mode, the user can drag existing beacons
                to new positions on the layout, or click on the layout to add a
                new beacon. When the user clicks on the layout to add a new
                beacon, the Move Beacon dialog opens.
              </p>
              <img
                src="@/assets/settings-images/settings_edit_beacon_location_move_tag.png"
              />
              <p>
                To move a beacon, select a beacon from the active list. An
                active beacon is a beacon that is already on the map. Once the
                user has selected a beacon, they can click on “Confirm”. This
                will move the selected beacon to the clicked location.
              </p>
              <p>
                To add a new beacon, the user can select a beacon from the
                inactive beacon list. This will add the beacon to the map at the
                clicked location and set it to active.
              </p>
              <p>
                On the right side of the screen, there is a table that displays
                all the changes that have been made to the beacon positions.
                This table allows the user to view and manage their changes, and
                delete any specific actions if they need to undo a change.
              </p>
              <img
                src="@/assets/settings-images/settings_edit_beacon_location_changes_made.png"
              />
              <p>
                To save the changes that the user has made to the beacon
                positions, they can click on the "Save" button on the Edit
                Beacons Location page. This will save the updated beacon
                positions and exit edit beacon location mode.
              </p>

              <h3 id="edit_gateway_settings">
                Edit Gateway Settings
              </h3>
              <p>
                To edit a gateway, the user can first select the Gateways tab on
                the Settings page. This will display a list of all the available
                gateways. From this list, the user can click on the "Edit"
                button in the Operations column for the gateway that they want
                to edit.
              </p>
              <img src="@/assets/settings-images/settings_edit_gateway.png" />
              <p>
                This will open the Edit Gateway dialog box, which allows the
                user to make changes to the gateway's settings. In this dialog,
                the user can change the label for the gateway, add specific
                network configuration settings, and enable or disable the
                buzzer.
              </p>
              <p>
                Once the user has made the desired changes, they can click on
                the "Save" button to save their changes.
              </p>
              <img
                src="@/assets/settings-images/settings_edit_gateway_dialog.png"
              />

              <h3 id="adding_wireless_networks">
                Adding Wireless Networks
              </h3>
              <p>
                Select Settings from the top menu to open the settings page.
                Then, select the Wireless Networks tab and click on the "NEW
                WIRELESS NETWORK" button.
              </p>
              <img
                src="@/assets/settings-images/settings_edit_wireless_networks.png"
              />
              <p>
                This will open the Create Wireless Network dialog box, where the
                user can enter the details for the new wireless network. This
                includes the network name, password, and other relevant
                information.
              </p>
              <img
                src="@/assets/settings-images/settings_edit_wireless_networks_create_dialog.png"
              />
              <p>
                Once the user has entered the details for the new wireless
                network, they can click on the "Save" button to add the network
                to the list of available wireless networks. The user can then
                assign this network to a gateway by editing the gateway settings
                and selecting the desired network from the list.
              </p>
              <p>
                Overall, this process allows the user to add multiple wireless
                networks to their gateways, providing flexibility and redundancy
                for their wireless network connectivity.
              </p>
              <p>
                When the gateway communicates with the server, it will receive
                information about any recently added wireless networks and will
                attempt to connect to them. It is important to ensure that the
                gateway has a working connection before removing or disabling
                the old connection, as the gateway needs to be connected in
                order to receive the information about the new wireless network.
              </p>

              <h3 id="add_a_new_user">
                Add a new User
              </h3>
              <p>
                Select Settings from the top menu to open the settings page.
                Then, select the tab Users.
              </p>
              <img src="@/assets/settings-images/settings_edit_users.png" />
              <p>
                Click New User (The Create New User dialog box appears). Fill in
                the name, email and password. Click Create to create the new
                user
              </p>
              <img
                src="@/assets/settings-images/settings_edit_users_dialog.png"
              />

              <h3 id="update_layout_image">
                Update Layout Image
              </h3>
              <p>
                Select Settings from the top menu to open the settings page.
                Then, select the tab Layout. Click Choose File and select the
                new file you want to use as layout. Click UPLOAD.
              </p>
              <img src="@/assets/settings-images/settings_edit_layout.png" />
              <h3 id="global_view">
                Global View
              </h3>
              <p>
                The "Global View" option allows users to view the status of all
                of the gateways that are currently active on the system. It is
                possible to see the gateways on the live map or a page with all
                relevant information about each gateway.
              </p>
              <p>
                On “Live map” all the selected gateways are shown on the map.
              </p>
              <img src="@/assets/settings-images/global_view.png" />
              <p>
                The "Real Time Analytics" page provides users with detailed
                information about the status and performance of the gateways
                that are currently active on the system.
              </p>
              <img src="@/assets/settings-images/global_view_realtime.png" />
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      showIndex: true,
      isButtonActive: false,
      windowWidth: window.innerWidth
    };
  },

  computed: {
    ...mapState("clients", {
      users: "users",
      clientData: "data"
    }),
    ...mapState("auth", {
      user: "user",
      client: "client"
    })
  },

  methods: {
    handleClick() {
      this.isButtonActive = !this.isButtonActive;
      if (this.isButtonActive) {
        this.showIndex = true;
      } else {
        this.showIndex = false;
      }
    }
  },

  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 860 && !this.isButtonActive) {
        this.showIndex = false;
      } else {
        this.showIndex = true;
      }
    };
  }
};
</script>

<style lang="scss">
.user-faq {
  padding: 24px 0px 0px 20px;
  text-align: justify;
  list-style: inside;
  line-height: 1.6;
  height: 75vh;

  @media (max-width: 1700px) {
    padding: 0;
  }

  .wrapper {
    display: grid;
    grid-template-columns: 20% 5% 75%;

    @media (max-width: 860px) {
      display: flex;
    }

    button {
      display: none;

      @media (max-width: 860px) {
        display: flex;
        background-color: white;
        border: none;
        padding-right: 10px;
        padding-top: 15px;

        .el-icon-d-arrow-right,
        .el-icon-d-arrow-left {
          color: $--color-primary;
        }
      }
    }
  }

  .manual {
    overflow-y: scroll;
    height: 75vh;
    padding-right: 20px;

    @media (max-width: 860px) {
      padding-right: 25px;
    }
  }

  .index {
    overflow-y: scroll;
    height: 75vh;

    @media (max-width: 860px) {
      position: fixed;
      background-color: white;
      z-index: 1;
      border-right: solid #dcdfe6;
      display: flex;
    }
  }

  .divider {
    height: 73vh;

    @media (max-width: 860px) {
      display: none;
    }
  }

  .horizontal-divider {
    width: 90%;
  }

  a {
    color: inherit;
  }

  img {
    margin-top: 25px;
    margin-bottom: 25px;
    max-width: 100%;
    max-height: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  h1 {
    padding-top: 0px;
    margin-top: 25px;
    font-size: 30px;
  }

  p {
    margin-top: 10px;
  }

  h3 {
    margin-top: 25px;
  }

  ul {
    margin-left: 15px;
  }
}
</style>
