import { ClientsState } from "../clients.types";

export const initialState = () => ({
  data: {
    email: "",
    name: "",
    id: -1,
    type: 0,
    mapImages: [],
    timezone: ""
  },
  error: null,
  loading: false,
  ssids: {
    data: [],
    error: null,
    loading: false
  },
  anchors: {
    data: [],
    error: null,
    loading: false,
    isEmpty: false
  },
  selectedAnchor: null,
  globalView: false,
  users: {
    data: [],
    error: null,
    loading: false
  }
});

export default initialState() as ClientsState;
