import { HttpRequest } from "@/services/api/http";

export default new (class AuthApi extends HttpRequest {
  constructor() {
    super();
  }

  public async getById(id: number) {
    return this.axiosClient.get(`/clients/${id}`);
  }

  public async getAnchorsById(id: number) {
    return this.axiosClient.get(`/users/anchors/`);
  }

  public async getUsersById(id: number) {
    return this.axiosClient.get(`/clients/${id}/users`);
  }

  public async getAzitekClients() {
    return this.axiosClient.get(`/clients/azitek/`);
  }

  public async getSsidsById(id: number) {
    const response = await this.axiosClient.get(`/clients/${id}`);
    return {
      data: response.data.ssids
    };
  }

  public async refreshAPI(id: number) {
    return await this.axiosClient.put(`/clients/${id}/resetapikey`);
  }

  public async createSsid(data: any) {
    return await this.axiosClient.post("/client_ssids/", {
      ...data
    });
  }

  public async updateSsidById(data: any) {
    return await this.axiosClient.put(`/client_ssids/${data.id}`, {
      ...data
    });
  }

  public async updateClient(data: any, id: number) {
    return await this.axiosClient.put(`clients/${id}`, {
      ...data
    });
  }

  public async uploadImage(data: any, id: number) {
    return await this.axiosClient.post(`/clients/${id}/upload_image`, data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  public async deleteSsidById(id: number) {
    return await this.axiosClient.delete(`/client_ssids/${id}`);
  }
})();
