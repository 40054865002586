import actions from "./anchors.store.actions";
import mutations from "./anchors.store.mutations";
import state from "./anchors.store.state";
import { AnchorState } from "../anchors.types";
import { RootState } from "@/store/store.types";
import { Module } from "vuex";

export const anchorsStore: Module<AnchorState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions
};
