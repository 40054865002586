import { AnchorState } from "../anchors.types";

export const initialState = () => ({
  anchor: {
    data: null,
    loading: false,
    error: null
  },
  routes: {
    data: [],
    loading: false,
    next: true,
    error: null
  }
});

export default initialState() as AnchorState;
