<template>
  <div class="routes-anchor-card">
    <el-card
      :class="anchorsLayout === 'lines' ? 'lines-layout' : 'grid-layout'"
    >
      <div slot="header">
        <div class="card-header">
          <span class="anchor-label"
            ><span v-if="showShiftConflict" class="conflict-marker">* </span
            >{{ anchor?.label }}</span
          >
        </div>
      </div>
      <div
        :class="[
          'anchor-routes',
          anchorsLayout === 'lines'
            ? 'routes-lines-layout'
            : 'routes-grid-layout'
        ]"
      >
        <el-select
          v-model="selectedRouteIds"
          placeholder="Select Route"
          @change="handleRouteOverlap"
          @remove-tag="onRemoveRoute"
          multiple
          filterable
        >
          <el-option
            v-for="route in routes"
            :key="route.id"
            :label="
              `${route.label} (${formatTime(
                route.constraintShiftStart
              )} - ${formatTime(route.constraintShiftEnd)})`
            "
            :value="route.id"
          >
            <span style="float: left"> {{ route.label }}</span>
            <span style="float: left; padding: 0 5px">
              <i
                :class="
                  route.constraintTag
                    ? 'el-icon-location-outline'
                    : route.constraintShiftStart != 0 ||
                      route.constraintShiftEnd != 86399
                    ? 'el-icon-time'
                    : ''
                "
              >
              </i>
            </span>
            <span
              style="float: right; font-size: 12px"
              v-if="
                route.constraintShiftStart != 0 ||
                  route.constraintShiftEnd != 86399
              "
            >
              {{ formatTime(route.constraintShiftStart) }} -
              {{ formatTime(route.constraintShiftEnd) }}
            </span>
            <span
              style="float: right; font-size: 12px"
              v-else-if="route.constraintTag"
            >
              {{ route.constraintTag }}
            </span>
          </el-option>
        </el-select>
      </div>
      <div class="submit-button">
        <el-tooltip :disabled="!showShiftConflict" placement="top">
          <template v-slot:content>
            <div>
              Shift conflict: <br /><br />
              <div
                v-for="route in routesInConflict"
                :key="route.routeALabel + route.routeBLabel"
              >
                {{ route.routeALabel }} with {{ route.routeBLabel }}
              </div>
            </div>
          </template>
          <el-button
            type="primary"
            size="mini"
            @click="onSubmitRoutes"
            :disabled="!anchor.id || showShiftConflict"
            >Submit</el-button
          >
        </el-tooltip>
      </div>

      <div v-if="showShiftConflict" class="shift-conflict">
        * Shift conflict
      </div>
    </el-card>
  </div>
</template>

<script>
import routesApi from "@/modules/routes/api/routes.api";
import * as actionTypes from "@/store/action-types";
import { cloneDeep } from "lodash";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  name: "RoutesAnchorCard",
  props: {
    anchor: {
      type: Object,
      default: () => ({})
    },
    routeList: {
      type: Array,
      default: () => []
    },
    anchorsLayout: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      selectedRouteIds: null,
      routes: null,
      associatedRoutes: [],
      showShiftConflict: false,
      routesInConflict: []
    };
  },
  methods: {
    ...mapActions("user", {
      setIsChanging: actionTypes.USER_SET_IS_CHANGING
    }),

    handleRouteOverlap() {
      this.showShiftConflict = false;
      const routesInfo = this.routes.filter(route =>
        this.selectedRouteIds.includes(route.id)
      );
      this.routesInConflict = [];

      for (let i = 0; i < routesInfo.length; i++) {
        for (let j = i + 1; j < routesInfo.length; j++) {
          const routeA = routesInfo[i];
          const routeB = routesInfo[j];

          if (
            (routeA.constraintShiftStart <= routeB.constraintShiftStart && // Start Shift: not conficted
            routeA.constraintShiftEnd >= routeB.constraintShiftStart && // End Shift: conflicted
              (routeA.constraintShiftEnd <= routeB.constraintShiftEnd ||
                routeA.constraintShiftEnd >= routeB.constraintShiftEnd)) ||
            (routeA.constraintShiftStart >= routeB.constraintShiftStart && // Start Shift: conficted
            routeA.constraintShiftStart <= routeB.constraintShiftEnd && // End Shift: conflicted
              routeA.constraintShiftEnd >= routeB.constraintShiftStart &&
              routeA.constraintShiftEnd <= routeB.constraintShiftEnd) ||
            (routeA.constraintShiftStart >= routeB.constraintShiftStart && // Start Shift: conficted
            routeA.constraintShiftStart <= routeB.constraintShiftEnd && // End Shift: can be conficted
              (routeA.constraintShiftEnd <= routeB.constraintShiftEnd ||
                routeA.constraintShiftEnd >= routeB.constraintShiftEnd))
          ) {
            if (
              !this.routesInConflict.find(
                route => route.routeBLabel === routeB.label
              )
            ) {
              this.routesInConflict.push({
                routeALabel: routeA.label,
                routeBLabel: routeB.label
              });
            }
          }
        }
      }
      this.showShiftConflict = this.routesInConflict.length > 0;
    },

    onRemoveRoute() {
      this.handleRouteOverlap();
    },

    async onSubmitRoutes() {
      try {
        const res = await routesApi.addRouteToAnchor(this.anchor.id, {
          routes: this.selectedRouteIds
        });

        if (res.data) {
          this.$notify({
            title: "Success",
            message: "Routes updated successfully!",
            type: "success"
          });
          this.$emit("route-updated");
          this.setIsChanging(false);
        }
      } catch (err) {
        this.$notify.error({
          title: "Error",
          message: "Something went wrong. Try again later."
        });
      } finally {
        this.routes = cloneDeep(this.routeList);
      }
    },
    formatTime(targetTime) {
      if (targetTime != null) {
        return moment.utc(targetTime * 1000).format("HH:mm");
      } else return "--";
    }
  },
  watch: {
    routeList: {
      handler(newValue) {
        this.routes = cloneDeep(newValue);
        this.associatedRoutes = this.routes.filter(route =>
          route.anchors.some(anchor => anchor.id === this.anchor.id)
        );
        this.selectedRouteIds = this.associatedRoutes?.length
          ? this.associatedRoutes.map(route => route.id)
          : [];
      }
    }
  },
  created() {
    this.routes = cloneDeep(this.routeList);
    this.associatedRoutes = this.routes.filter(route =>
      route.anchors.some(anchor => anchor.id === this.anchor.id)
    );
    this.selectedRouteIds = this.associatedRoutes?.length
      ? this.associatedRoutes.map(route => route.id)
      : [];
    this.setIsChanging(false);
  }
};
</script>
<style lang="scss">
.routes-anchor-card {
  .grid-layout {
    width: 262px;
    height: 220px;
  }

  .lines-layout {
    width: 30vw;
    height: 220px;

    @media (max-width: 1080px) {
      width: 50vw;
    }
    @media (max-width: 600px) {
      width: 70vw;
    }
  }

  .shift-conflict {
    color: red;
    font-size: 12px;
    font-weight: bold;
    text-align: right;
    padding-top: 5px;
  }

  .conflict-marker {
    color: red;
    font-size: 14px;
    font-weight: bold;
  }

  .el-card {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    text-align: start;

    &__header {
      border-bottom: none;
      padding: 24px 24px 0;

      .card-header {
        display: flex;
        border-bottom: none;
        justify-content: space-between;
        align-items: center;
        gap: 5px;

        .anchor-label {
          font-size: 14px;
          font-weight: bold;
          color: $--color-primary;
          word-break: break-word;
        }
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding: 0 24px 24px;

      .routes-lines-layout {
        max-height: 200px;
      }

      .routes-grid-layout {
        max-height: 150px;
      }

      .anchor-routes {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 110px;

        .el-select {
          .el-input__inner {
            font-size: 12px;
          }

          .el-tag {
            max-width: 96%;
          }
        }

        .el-select__tags {
          max-height: 70px;
          overflow-y: auto;
          overflow-x: hidden;

          .el-tag.el-tag--info {
            color: $--color-primary;

            .el-tag__close {
              background-color: $--color-primary;
              color: #fff;
            }

            .el-tag__close:hover {
              background-color: #79cfff;
              color: #fff;
            }
          }
        }

        .el-select__tags::-webkit-scrollbar {
          width: 7px;
        }

        .el-select__tags::-webkit-scrollbar-track {
          background: none;
        }

        .el-select__tags::-webkit-scrollbar-thumb {
          background-color: #a5b3bc;
          border-radius: 10px;
        }
      }

      .submit-button {
        align-self: center;
        padding: 5px 0;
      }
    }
  }
}
</style>
