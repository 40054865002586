<template>
  <div :class="['stats', isDetails ? 'stats--details' : '']">
    <div v-if="isLoading" v-loading="isLoading" class="stats__loading" />
    <h4 v-else-if="anchors.isEmpty">
      You must have an anchor to see data on this page.
    </h4>
    <router-view v-else />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import * as actionTypes from "@/store/action-types";
import * as routerTypes from "@/router/router.types";

export default {
  computed: {
    ...mapState("auth", {
      user: "user"
    }),

    ...mapState("clients", {
      selectedAnchor: "selectedAnchor",
      anchors: "anchors"
    }),

    ...mapState("anchors", {
      anchor: "anchor",
      routes: "routes"
    }),

    isLoading() {
      return (
        (this.anchor.data === null && this.$route.params.anchorId) ||
        this.anchor.loading
      );
    },

    isDetails() {
      return this.$route.name === routerTypes.ROUTE_STATS_ROUTE;
    }
  },

  watch: {
    "$route.params.anchorId"(newValue, oldValue) {
      if (!newValue) {
        this.$router
          .replace({
            name: routerTypes.ROUTE_STATS,
            params: {
              anchorId: this.selectedAnchor.anchorId,
              routeId: this.$route.params.routeId ?? null
            }
          })
          .catch(_err => {
            // Do nothing
          });

        this.handleAnchorDetails(true);
      }
    },

    selectedAnchor: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        const resetRouteId = oldValue && Number(newValue) !== Number(oldValue);
        this.handleAnchorDetails(resetRouteId);
      }
    }
  },

  methods: {
    ...mapActions("anchors", {
      getAnchorDetails: actionTypes.ANCHOR_GET_DETAILS
    }),

    async handleAnchorDetails(resetRouteId = false) {
      if (this.selectedAnchor?.anchorId) {
        await this.getAnchorDetails(this.selectedAnchor.anchorId);
        this.$router
          .push({
            name: routerTypes.ROUTE_STATS_ROUTE,
            params: {
              anchorId: this.selectedAnchor.anchorId,
              routeId:
                this.$route.params.routeId && !resetRouteId
                  ? this.$route.params.routeId
                  : null
            }
          })
          .catch(_err => {
            // Do nothing
          });
      }
    }
  },

  created() {
    if (!this.$route.params.anchorId) {
      this.$router
        .replace({
          name: routerTypes.ROUTE_STATS,
          params: {
            anchorId: this.selectedAnchor.anchorId,
            routeId: this.$route.params.routeId ?? null
          }
        })
        .catch(_err => {
          // Do nothing
        });
    }
  }
};
</script>

<style lang="scss">
.stats {
  padding: 24px 0;
  max-width: 1080px;
  margin: 0 auto;

  &--details {
    max-width: 100%;
    padding-top: 0;
  }

  .user-anchors .el-table__row {
    cursor: pointer;
  }

  &__loading {
    .el-loading-spinner {
      margin-top: 120px;
    }
  }
}
</style>
