import * as mutationTypes from "@/store/mutation-types";
import { UserState } from "../user.types";
import { initialState } from "./user.store.state";
import { AxiosError } from "axios";
import { Anchor } from "@/modules/clients/clients.types";

export default {
  [mutationTypes.USER_SET_RESOURCES_DATA](state: UserState, data: any) {
    state.resources.data = data;
  },

  [mutationTypes.USER_UPDATE_USAGE_INFO](state: UserState, data: any) {
    state.resources.usageInfo = data;
  },

  [mutationTypes.USER_SET_RESOURCES_UPDATED_DATA](
    state: UserState,
    updatedData: any
  ) {
    state.resources.updatedData = updatedData;
  },

  [mutationTypes.USER_SET_ESSENTIAL_INFORMATION](state: UserState, data: any) {
    state.resources.sidebarAnchor = data;
  },

  [mutationTypes.USER_SET_GLOBAL_INFORMATION](state: UserState, data: any) {
    state.resources.globalAnchors = data;
  },

  [mutationTypes.USER_SET_RESOURCES_LOADING](
    state: UserState,
    loading: boolean
  ) {
    state.resources.loading = loading;
  },

  [mutationTypes.USER_SET_RESOURCES_ERROR](
    state: UserState,
    error: AxiosError | null
  ) {
    state.resources.error = error;
  },

  [mutationTypes.USER_SET_IS_CHANGING](state: UserState, isChanging: boolean) {
    state.isChangingInformation = isChanging;
  },

  [mutationTypes.USER_SET_PATH](state: UserState, path: string) {
    state.pathToChangeTo = path;
  },

  [mutationTypes.USER_SET_DISCARD](state: UserState, discard: boolean) {
    state.discardChanges = discard;
  },

  [mutationTypes.USER_UPDATE_ANCHOR](state: UserState, payload: Anchor) {
    const anchorIndex = state.resources.data.anchors.findIndex(
      _anchor => _anchor.anchorId === payload.anchorId
    );

    if (anchorIndex >= 0) {
      state.resources.data.anchors[anchorIndex] = payload;
    }
  },

  [mutationTypes.USER_UPDATE_ACTIVE_ROUTE](state: UserState, route: object) {
    state.activeRoute = route;
  },

  reset(state: UserState) {
    const s = initialState();
    Object.keys(s).forEach(key => {
      (state as any)[key] = (s as any)[key];
    });
  }
};
