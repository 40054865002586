import * as mutationTypes from "@/store/mutation-types";
import { DashboardData, DashboardState } from "../dashboard.types";

export default {
  [mutationTypes.SET_CURRENT_TIME](
    state: DashboardState,
    payload: DashboardData
  ) {
    state.data = payload;
  }
};
