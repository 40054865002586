<template>
  <aside
    v-if="isLiveMap && this.clientData.mapImages.length"
    class="dashboard__sidebar"
  >
    <div
      :class="isTracker ? 'sidebar-wrapper__tracker' : 'sidebar-wrapper'"
      v-if="!isEditing && !stateAnchors.isEmpty"
    >
      <template v-if="!isTracker">
        <h3>
          Real-time Metrics
        </h3>
        <template v-if="sidebarAnchor.usageInfo">
          <div
            class="wrapper"
            v-if="sidebarAnchor.usageInfo.routesInfo.length > 0"
            v-loading="loadingMetricsCard"
            element-loading-background="#F0F5FB"
          >
            <div v-if="activeRouteUsageInfo">
              <MetricsCard
                :usageInfo="activeRouteUsageInfo"
                :isActiveRoute="true"
              />
            </div>
            <div
              v-for="routesInfo in allRoutesUsageInfo"
              :key="routesInfo.routeId"
            >
              <MetricsCard :usageInfo="routesInfo" :isActiveRoute="false" />
            </div>
          </div>
        </template>
        <div class="sidebar-component">
          <h3>Recent Laps</h3>
        </div>
        <div class="sidebar__table" v-if="sidebarAnchor">
          <el-table
            :data="
              sidebarAnchor.activeRouteOnTime
                ? [''].concat(sidebarAnchor.recentRoutes)
                : sidebarAnchor.recentRoutes
            "
            @row-click="redirect"
            class="sidebar-table"
            style="width: 100%"
          >
            <el-table-column label="" width="35px">
              <template slot-scope="scope">
                <el-tooltip
                  v-if="scope.row.error === 1"
                  class="item"
                  effect="dark"
                  content="Route error due to timeout."
                  placement="right"
                >
                  <i v-if="scope.row.error" class="el-icon-warning"></i>
                </el-tooltip>
                <el-tooltip
                  v-if="
                    scope.row.error === 2 ||
                      scope.row.error === 3 ||
                      scope.row.error === 5
                  "
                  class="item"
                  effect="dark"
                  content="Route error due to wrong path."
                  placement="right"
                >
                  <i v-if="scope.row.error" class="el-icon-warning"></i>
                </el-tooltip>
                <el-tooltip
                  v-if="scope.row.error === 4"
                  class="item"
                  effect="dark"
                  content="Route error due to the gateway being offline."
                  placement="right"
                >
                  <i v-if="scope.row.error" class="el-icon-warning"></i>
                </el-tooltip>
                <el-tooltip
                  v-if="scope.row.error === 6"
                  class="item"
                  effect="dark"
                  content="Route interrupted."
                  placement="right"
                >
                  <i v-if="scope.row.error" class="el-icon-warning"></i>
                </el-tooltip>
                <el-tooltip
                  v-if="scope.row.error === 7"
                  class="item"
                  effect="dark"
                  content="Route minimum duration was not reached."
                  placement="right"
                >
                  <i v-if="scope.row.error" class="el-icon-warning"></i>
                </el-tooltip>
                <el-tooltip
                  v-if="scope.row.error > 7"
                  class="item"
                  effect="dark"
                  content="Unknown Error"
                  placement="right"
                >
                  <i v-if="scope.row.error" class="el-icon-warning"></i>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="Route" class="route-column">
              <template slot-scope="scope">
                <el-tooltip
                  :content="
                    scope.$index === 0 &&
                    sidebarAnchor.currentRouteInstance &&
                    sidebarAnchor.activeRouteOnTime
                      ? currentRouteInstanceLabel
                      : scope.row.routeLabel
                  "
                  placement="left"
                  :disabled="!isTruncated(scope)"
                >
                  <template
                    v-if="
                      scope.$index === 0 &&
                        sidebarAnchor.currentRouteInstance &&
                        sidebarAnchor.activeRouteOnTime
                    "
                  >
                    <b :ref="`cell-${scope.$index}`">
                      {{ currentRouteInstanceLabel }}
                    </b>
                  </template>
                  <template v-else>
                    <span :ref="`cell-${scope.$index}`">
                      {{ scope.row.routeLabel }}
                    </span>
                  </template>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="Start" :resizable="false" width="95px">
              <template slot-scope="scope">
                <template
                  v-if="
                    scope.$index === 0 &&
                      sidebarAnchor.currentRouteInstance &&
                      sidebarAnchor.activeRouteOnTime
                  "
                >
                  <b>
                    {{
                      formatTimeUnit(
                        sidebarAnchor.currentRouteInstance.startDate,
                        true,
                        clientData
                      )
                    }}
                  </b>
                </template>
                <template v-else-if="scope.row != ''">
                  <template v-if="anotherDay(scope.row.startTime)">
                    <el-tooltip
                      :content="
                        formatTimeUnit(scope.row.startTime, true, clientData) +
                          ' (' +
                          moment
                            .tz(
                              moment.unix(scope.row.startTime),
                              clientData.timezone
                            )
                            .format('DD/MM') +
                          ')'
                      "
                      placement="top"
                    >
                      <span>
                        {{
                          formatTimeUnit(
                            scope.row.startTime,
                            true,
                            clientData
                          ) +
                            " (" +
                            moment
                              .tz(
                                moment.unix(scope.row.startTime),
                                clientData.timezone
                              )
                              .format("DD/MM") +
                            ")"
                        }}
                      </span>
                    </el-tooltip>
                  </template>
                  <template v-else>
                    {{ formatTimeUnit(scope.row.startTime, true, clientData) }}
                  </template>
                </template>
              </template>
            </el-table-column>

            <el-table-column label="Duration" :resizable="false" width="95px">
              <template slot-scope="scope">
                <template
                  v-if="
                    scope.$index === 0 &&
                      sidebarAnchor.currentRouteInstance &&
                      sidebarAnchor.activeRouteOnTime
                  "
                >
                  <b>{{ currentTime }} </b>
                </template>
                <template v-else>
                  {{ formatTimeUnit(scope.row.duration, false, clientData) }}
                </template>
              </template>
            </el-table-column>

            <el-table-column label="Delay" :resizable="false" width="95px">
              <template slot-scope="scope">
                <template
                  v-if="
                    scope.$index === 0 &&
                      sidebarAnchor.activeRouteOnTime &&
                      !scope.row.error
                  "
                >
                  <b>{{ getRouteTableDelay }}</b>
                </template>
                <template v-else-if="!scope.row.error">
                  {{
                    scope.row.delay > 0
                      ? formatTimeUnit(scope.row.delay, false, clientData)
                      : "-" + formatTimeUnit(scope.row.delay, false, clientData)
                  }}
                </template>
                <template v-else>
                  <hr />
                </template>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div v-if="lastFive" class="sidebar-component">
          <h3>
            Last Five Positions
          </h3>
          <!-- <div class="sidebar__table">
            <el-table
              :data="lastFive.lastFive"
              class="sidebar-table"
              style="width: 100%"
            >
              <el-table-column label="Start" width="140">
                <template slot-scope="scope">
                  {{ formatTimeUnit(scope.row.enterTs, true, clientData) }}
                </template>
              </el-table-column>
              <el-table-column label="Duration">
                <template slot-scope="scope">
                  {{
                    formatTimeUnit(
                      scope.row.lastSeenAt - scope.row.enterTs,
                      false,
                      clientData
                    )
                  }}
                </template>
              </el-table-column>
              <el-table-column label="Position" width="220">
                <template slot-scope="scope">
                  <template v-if="scope.row.label">
                    {{ scope.row.label }}
                  </template>
                  <template v-else>
                    {{
                      scope.row.tagId
                        .toString(16)
                        .toUpperCase()
                        .match(/.{1,2}/g)
                        .join("")
                    }}
                  </template>
                </template>
              </el-table-column>
            </el-table>
          </div> -->
          <div class="sidebar__table">
            <LastPositionsTable
              border="none"
              :lastFivePositions="lastFive.lastFive"
              @show-tag-label-on-map="showTagLabelOnMap"
            />
          </div>
        </div>
        <div class="button-div">
          <el-button @click="redirectToPositions()">Show More</el-button>
        </div>
      </template>
      <template v-else>
        <TagList
          :asset-list="tagBeaconList"
          :beacon-list="beaconList"
          :currentMovingTag="sidebarAnchor.currentTagId"
          :gatewayLabel="sidebarAnchor.label"
          :currentTagStartTs="sidebarAnchor.currentTagStartTs"
          @view-history="fetchAssetHistory"
          @highlight-beacon="updateHighlightedBeacon"
        />
        <BeaconList
          :beacon-list="beaconList"
          :currentMovingTag="sidebarAnchor.currentTagId"
          :gatewayLabel="sidebarAnchor.label"
          :currentTagStartTs="sidebarAnchor.currentTagStartTs"
        />
      </template>
    </div>
  </aside>
</template>

<script>
import moment from "moment-timezone";
import TagList from "@/components/TagList.vue";
import BeaconList from "@/components/BeaconList.vue";
import * as routerTypes from "@/router/router.types";
import { formatTimeUnit } from "@/utils/utils";
import MetricsCard from "@/components/MetricsCard.vue";
import { mapActions, mapState } from "vuex";
import * as actionTypes from "@/store/action-types";
import RoutesApi from "@/modules/routes/api/routes.api";
import LastPositionsTable from "@/views/global/components/LastPositionsTable.vue";

export default {
  components: {
    TagList,
    BeaconList,
    MetricsCard,
    LastPositionsTable
  },

  props: {
    isLiveMap: {
      type: Boolean,
      default: false
    },

    isTracker: {
      type: Boolean,
      default: false
    },

    isEditing: {
      type: Boolean,
      default: false
    },

    currentTime: {
      type: String,
      default: ""
    },

    clientData: {
      type: Object,
      default: () => ({})
    },

    stateAnchors: {
      type: Object,
      default: () => ({})
    },

    sidebarAnchor: {
      type: Object,
      default: () => ({})
    },

    lastFive: {
      type: Object,
      default: () => ({})
    },

    tagBeaconList: {
      type: Array,
      default: () => []
    },

    beaconList: {
      type: Array,
      default: () => []
    },

    highlightedBeacon: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      moment,
      formatTimeUnit,
      activeRouteUsageInfo: null,
      allRoutesUsageInfo: [],
      routeList: [],
      loadingMetricsCard: true
    };
  },
  watch: {
    sidebarAnchor: {
      deep: true,
      handler() {
        this.handleUsageInfoChanges();
      }
    }
  },

  computed: {
    ...mapState("user", {
      resources: "resources"
    }),
    getRouteTableDelay() {
      let hours;
      let minutes;

      if (this.sidebarAnchor.activeRouteOnTime[1] >= 0) {
        hours = Math.floor(this.sidebarAnchor.activeRouteOnTime[1] / 3600);
        minutes = Math.floor(
          (this.sidebarAnchor.activeRouteOnTime[1] % 3600) / 60
        );
      } else {
        hours = Math.ceil(this.sidebarAnchor.activeRouteOnTime[1] / 3600);
        minutes = Math.ceil(
          (this.sidebarAnchor.activeRouteOnTime[1] % 3600) / 60
        );
      }

      let minutesString = Math.abs(minutes).toString();

      if (minutes < 10 && minutes > -10)
        minutesString = "0".concat(minutesString);

      if (Math.abs(hours) >= 1)
        return (
          (this.sidebarAnchor.activeRouteOnTime[1] < 0 ? "-" : "") +
          (minutes > 0 ? hours.toString() : hours.toString()) +
          "h" +
          (minutes < 10 && minutes > -10
            ? "0" + minutesString
            : minutesString) +
          "m"
        );
      else
        return (
          (this.sidebarAnchor.activeRouteOnTime[1] < 0 ? "-" : "") +
          "00h" +
          minutesString +
          "m"
        );
    },
    currentRouteInstanceLabel() {
      if (
        this.sidebarAnchor.usageInfo &&
        this.sidebarAnchor.currentRouteInstance
      ) {
        return this.sidebarAnchor.usageInfo.routesInfo.find(
          route =>
            route.routeId === this.sidebarAnchor.currentRouteInstance.routeId
        )?.routeLabel;
      }
      return "";
    }
  },

  methods: {
    ...mapActions("user", {
      updateActiveRoute: actionTypes.USER_UPDATE_ACTIVE_ROUTE
    }),

    // Calculates the percentage
    getPercentage(divisor, dividend) {
      if (dividend)
        return Number.parseFloat((divisor / dividend) * 100).toFixed(1);
      else return 0;
    },

    // Checks if the route belongs to another day or to the same day
    anotherDay(startTime) {
      if (
        moment().dayOfYear() !==
        moment.tz(moment.unix(startTime), this.clientData.timezone).dayOfYear()
      )
        return true;
      return false;
    },

    // Redirects to route analytics positions page
    redirectToPositions() {
      this.$router.push({
        name: routerTypes.ROUTE_STATS_GLOBALS
      });
    },

    // Redirects to the route analytics positions page with route id as a parameter
    redirect(route) {
      this.$router.push({
        name: routerTypes.ROUTE_STATS_GLOBALS,
        params: {
          lapId: route.id
        }
      });
    },

    // Emits an event to fetch asset history
    fetchAssetHistory(obj) {
      this.$emit("fetch-asset-history", obj);
    },

    updateHighlightedBeacon(beacon) {
      this.$emit("update-highlighted-beacon", beacon);
    },

    async fetchRouteList() {
      try {
        const res = await RoutesApi.getRouteList();
        this.routeList = res.data;
        this.routeList.sort((a, b) => b.anchors.length - a.anchors.length);
        this.handleUsageInfoChanges();
      } catch (error) {
        console.log(error);
      }
    },

    addRouteConstraints(route) {
      const routeInfo = this.routeList.find(
        element => element.id == route.routeId
      );
      if (routeInfo) {
        return {
          ...route,
          constraintTag: routeInfo.constraintTag,
          constraintShiftStart: routeInfo.constraintShiftStart,
          constraintShiftEnd: routeInfo.constraintShiftEnd
        };
      }
      return route;
    },

    handleUsageInfoChanges() {
      if (!this.routeList.length) return;

      if (this.sidebarAnchor.activeRoute) {
        this.updateActiveRoute(this.sidebarAnchor.activeRoute);

        const activeRoute = this.sidebarAnchor.usageInfo.routesInfo.find(
          route => route.routeId === this.sidebarAnchor.activeRoute.id
        );

        this.activeRouteUsageInfo = this.addRouteConstraints(activeRoute);

        this.allRoutesUsageInfo = this.sidebarAnchor.usageInfo.routesInfo
          .filter(route => route.routeId !== this.sidebarAnchor.activeRoute.id)
          .map(this.addRouteConstraints);
      } else {
        this.allRoutesUsageInfo = this.sidebarAnchor.usageInfo.routesInfo.map(
          this.addRouteConstraints
        );
        this.activeRouteUsageInfo = null;
        this.updateActiveRoute(null);
      }
      this.loadingMetricsCard = false;
    },
    isTruncated(scope) {
      const element = this.$refs[`cell-${scope.$index}`];
      if (element) {
        return element.textContent.length > 17;
      }
      return false;
    },
    showTagLabelOnMap(tagData) {
      this.$emit("show-tag-label-on-map", tagData);
    }
  },
  created() {
    if (this.isLiveMap) this.fetchRouteList();
  }
};
</script>

<style lang="scss">
.dashboard {
  &__sidebar {
    .sidebar-wrapper {
      display: block !important;
      position: fixed;
      top: 64px;
      right: 0;
      bottom: 0;
      padding-top: 60px;
      padding-bottom: 20px;
      background: $--border-color-extra-light;
      width: 480px;
      max-height: 100vh;
      overflow-y: auto;
      overflow-x: hidden;
      border-left: 1px solid #dcdfe6;
      z-index: 100;

      @media (max-width: $md) {
        top: 98px;
        scrollbar-gutter: stable both-edges;
      }
      @media (max-width: $sm) {
        width: 100vw;
      }
      @media (max-width: 470px) {
        top: 138px;
      }

      &__tracker {
        display: block !important;
        position: fixed;
        top: 64px;
        right: 0;
        bottom: 0;
        padding-top: 80px;
        padding-bottom: 20px;
        background: $--border-color-extra-light;
        width: 760px;
        max-height: 100vh;
        overflow-y: auto;
        overflow-x: hidden;
        border-left: 1px solid #dcdfe6;
        z-index: 40;

        @media (max-width: $sm) {
          width: 100%;
          top: 80px;
          padding-top: 90px;
        }
        @media (max-width: 500px) {
          padding-top: 120px;
        }
      }

      .sidebar__current-route-info {
        margin: 12px 0;

        .current-route-info__circle {
          position: relative;
          top: 2px;
          left: 2px;
          display: inline-block;
          width: 14px;
          height: 14px;
          background: #32cd32;
          border-radius: 50%;

          &--delayed {
            background-color: #ffb833;
          }
        }
      }

      .button-div {
        margin-top: 20px;
      }
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin: 15px 10px 0;
      width: 100%;
      min-height: 100px;

      @media (max-width: $sm) {
        width: auto;
      }

      // &__card {
      //   display: flex;
      //   flex-direction: column;
      //   gap: 15px;
      //   background-color: white;
      //   border-radius: 5px;
      //   box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12),
      //     0 0 6px 0 rgba(0, 0, 0, 0.04);
      //   width: 200px;
      //   text-align: start;
      //   padding: 24px;

      //   @media (max-width: $sm) {
      //     width: 300px;
      //     padding: 18px 20px 24px;
      //   }

      //   &__data {
      //     display: flex;
      //     font-size: 14px;
      //     flex-direction: column;
      //     gap: 6px;

      //     @media (max-width: $sm) {
      //       flex-direction: column;
      //     }

      //     .laps {
      //       display: flex;
      //       gap: 10px;

      //       &__total {
      //         &__value {
      //           font-size: 30px;
      //           font-weight: 600;
      //         }
      //         &__text {
      //           color: #929292;
      //         }
      //       }
      //       &__data {
      //         display: flex;
      //         flex-direction: column;
      //         align-items: flex-start;
      //         gap: 4px;

      //         &__valid {
      //           color: green;
      //           font-weight: bold;
      //         }
      //         &__error {
      //           color: red;
      //         }
      //       }
      //     }

      //     .other-data {
      //       display: flex;
      //       flex-direction: column;
      //       align-items: flex-start;
      //       gap: 4px;

      //       @media (max-width: $sm) {
      //         flex-direction: row;
      //         gap: 30px;
      //       }
      //     }
      //   }
      // }
    }

    .sidebar-component {
      margin-top: 24px;
    }

    .sidebar__table {
      margin: 15px 10px 0;

      @media (max-width: $sm) {
        margin: 15px 24px 0;
      }

      .sidebar-table:hover {
        cursor: pointer;
      }

      .last-positions-table {
        .el-table .el-table__cell {
          padding: 12px 0;
        }
      }

      .el-table {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12),
          0 0 6px 0 rgba(0, 0, 0, 0.04);
        border-radius: 5px;
      }

      .el-table td.el-table__cell div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      th,
      tr,
      td {
        text-align: center;
      }
    }
  }
}
</style>
