var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('dashboard',{attrs:{"show-anchors":true,"interval-update":true,"anchorsToShow":_vm.anchorsToShow}},[_c('div',{staticClass:"sidebar"},[_c('p',{staticClass:"select-desc"},[_c('b',[_vm._v("Select the Gateways to show on the map:")])]),_c('el-checkbox-group',{staticClass:"check-group",model:{value:(_vm.anchorsToShowIds),callback:function ($$v) {_vm.anchorsToShowIds=$$v},expression:"anchorsToShowIds"}},[_vm._l((_vm.anchors.data),function(anchor){return [_c('el-checkbox',{key:anchor.id,staticClass:"checkbox-item",attrs:{"label":anchor.anchorId,"border":""}},[_c('div',{staticClass:"anchor-label-wrapper"},[_c('span',{staticClass:"anchor-label",style:({ color: _vm.offlineColor(anchor) })},[_vm._v(" "+_vm._s(anchor.label)+" ")]),(_vm.isAnchorOffline(anchor.powerSource, anchor.offlineTs))?_c('span',{staticClass:"no-wifi-span"},[_c('wifi-icon',{attrs:{"signal":0,"noConnection":true}})],1):_vm._e(),(
                  _vm.isAnchorPowerSourceDisconnected(
                    anchor.lastHeartbeat ? anchor.lastHeartbeat : null
                  )
                )?_c('span',{staticClass:"no-battery-span"},[_c('span',[_c('img',{attrs:{"src":require("@/assets/battery_dead.svg"),"alt":"no battery"}})])]):_vm._e(),(anchor.currentRouteInstance)?_c('span',[_vm._v(" "+_vm._s(" - ")+" "),_c('span',{style:({
                    color: _vm.computedColor(anchor),
                    fontWeight: _vm.computedWeight(anchor)
                  })},[_vm._v(" "+_vm._s(anchor.currentTime)+" ")])]):_vm._e(),(anchor.activeRouteOnTime)?_c('span',{style:({
                  color: _vm.computedDelay(anchor),
                  fontWeight: _vm.computedWeight(anchor)
                })},[_vm._v(" "+_vm._s(" (" + _vm.getRouteDelay(anchor) + ")")+" ")]):_vm._e()]),_c('div',{style:({
                background: _vm.computedBackground(anchor),
                position: 'absolute',
                left: '93%',
                top: '25%'
              }),attrs:{"id":"circle"}})])]})],2)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }