import * as actionTypes from "@/store/action-types";
import * as mutationTypes from "@/store/mutation-types";
import { UserStoreContext } from "../user.types";
import userApi from "../api/user.api";
import { cloneDeep } from "lodash";
import anchorsApi from "@/modules/anchors/api/anchors.api";

export default {
  async [actionTypes.USER_GET_RESOURCES](
    { commit, state }: UserStoreContext,
    payload?: any
  ) {
    try {
      commit(mutationTypes.USER_SET_RESOURCES_LOADING, true);
      commit(mutationTypes.USER_SET_RESOURCES_ERROR, null);

      const res = await userApi.getResources(
        payload ? payload.timestamp : undefined,
        payload ? payload.simplified : undefined
      );
      commit(mutationTypes.USER_SET_RESOURCES_LOADING, false);

      if (payload) {
        if (payload.timestamp) {
          const currentData = cloneDeep(state.resources.data);
          let dataUpdated = false;

          if (res.data.anchors.length != state.resources.data.anchors.length) {
            res.data.anchorsBasic?.forEach((anchor: any) => {
              dataUpdated = true;
              const index = currentData.anchors.findIndex(
                _anchor => _anchor.anchorId === anchor.anchorId
              );

              if (index >= 0) {
                currentData.anchors[index].lastHeartbeat = anchor.lastHeartbeat;
                currentData.anchors[index].lastPositionMetadata =
                  anchor.lastPositionMetadata;
                currentData.anchors[index].lastPosition = anchor.lastPosition;
                currentData.anchors[index].nextPosition = anchor.nextPosition;
              } else {
                currentData.anchors.push(anchor);
              }
            });
          }

          // Update anchors
          res.data.anchors?.forEach((anchor: any) => {
            dataUpdated = true;
            const index = currentData.anchors.findIndex(
              _anchor => _anchor.anchorId === anchor.anchorId
            );

            if (index >= 0) {
              currentData.anchors[index] = anchor;
            } else {
              currentData.anchors.push(anchor);
            }
          });

          // Update tags
          res.data.tags?.forEach((tag: any) => {
            dataUpdated = true;
            const index = currentData.tags.findIndex(
              _tag => _tag.tagId === tag.tagId
            );

            if (index >= 0) {
              currentData.tags[index] = tag;
            } else {
              currentData.tags.push(tag);
            }
          });

          if (dataUpdated) {
            commit(mutationTypes.USER_SET_RESOURCES_DATA, currentData);
          }
        }
      } else {
        commit(mutationTypes.USER_SET_RESOURCES_DATA, res.data);
      }

      return res;
    } catch (error) {
      commit(mutationTypes.USER_SET_RESOURCES_ERROR, error);
      throw error;
    } finally {
      commit(mutationTypes.USER_SET_RESOURCES_LOADING, false);
    }
  },
  async [actionTypes.USER_GET_ESSENTIAL_INFORMATION](
    { commit, state }: UserStoreContext,
    payload?: any
  ) {
    try {
      const res = await anchorsApi.getEssentialInformation(payload);
      if (res.data != null)
        commit(mutationTypes.USER_SET_ESSENTIAL_INFORMATION, res.data);
    } catch (error) {
      commit(mutationTypes.USER_SET_RESOURCES_ERROR, error);
      throw error;
    }
  },

  async [actionTypes.USER_GET_GLOBAL_INFORMATION](
    { commit, state }: UserStoreContext,
    payload?: any
  ) {
    try {
      const res = await anchorsApi.getGlobalInformation(payload);
      commit(mutationTypes.USER_SET_GLOBAL_INFORMATION, res.data);
    } catch (error) {
      commit(mutationTypes.USER_SET_RESOURCES_ERROR, error);
      throw error;
    }
  },

  [actionTypes.USER_SET_IS_CHANGING](
    { commit, state }: UserStoreContext,
    payload?: any
  ) {
    commit(mutationTypes.USER_SET_IS_CHANGING, payload);
  },

  [actionTypes.USER_SET_DISCARD](
    { commit, state }: UserStoreContext,
    payload?: any
  ) {
    commit(mutationTypes.USER_SET_DISCARD, payload);
  },

  [actionTypes.USER_SET_PATH](
    { commit, state }: UserStoreContext,
    payload?: any
  ) {
    commit(mutationTypes.USER_SET_PATH, payload);
  },

  [actionTypes.USER_UPDATE_ACTIVE_ROUTE](
    { commit, state }: UserStoreContext,
    payload?: any
  ) {
    commit(mutationTypes.USER_UPDATE_ACTIVE_ROUTE, payload);
  },

  async [actionTypes.USER_GET_USAGE_INFO](
    { commit }: UserStoreContext,
    payload?: any
  ) {
    try {
      const res = await userApi.getUsageInfo(payload);

      commit(mutationTypes.USER_UPDATE_USAGE_INFO, res.data);

      return res;
    } catch (error) {
      commit(mutationTypes.USER_SET_RESOURCES_ERROR, error);
      throw error;
    }
  }
};
