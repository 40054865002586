export const AUTH_SET_USESR = "authLogin";

export const ANCHOR_SET_DETAILS = "anchorSetDetails";
export const ANCHOR_SET_ROUTES = "anchorSetRoutes";

export const USER_SET_RESOURCES_DATA = "userSetResourcesData";
export const USER_SET_RESOURCES_UPDATED_DATA = "userSetResourcesUpdatedData";
export const USER_SET_RESOURCES_LOADING = "userSetResourcesLoading";
export const USER_SET_RESOURCES_ERROR = "userSetResourcesLoading";
export const USER_UPDATE_ANCHOR = "userUpdateAnchor";
export const USER_UPDATE_ACTIVE_ROUTE = "userUpdateActiveRoute";
export const USER_UPDATE_USAGE_INFO = "userUpdateUsageInfo";
export const USER_SET_ESSENTIAL_INFORMATION = "userSetEssentialInformation";
export const USER_SET_GLOBAL_INFORMATION = "userSetGlobalInformation";
export const USER_SET_IS_CHANGING = "userSetIsChanging";
export const USER_SET_DISCARD = "userSetDiscard";
export const USER_SET_PATH = "userSetPath";

export const CLIENTS_SET_CLIENT = "clientsSetClient";
export const CLIENTS_SET_ANCHORS = "clientsSetAnchors";
export const CLIENTS_SET_SELECTED_ANCHOR = "clientsSetSelectedAnchor";
export const CLIENTS_SET_GLOBAL_VIEW = "clientsSetGlobalView";
export const CLIENTS_SET_USERS = "clientsSetUsers";
export const CLIENTS_SET_SSIDS = "clientsSetSSIDS";
export const CLIENTS_SET_SSID = "clientsSetSSID";
export const CLIENTS_SET_USER = "clientsSetUser";
export const CLIENTS_SET_DELETE_USER = "clientsSetDeletedUser";
export const CLIENTS_SET_UPDATE_SSID = "clientsSetUpdateSSID";
export const CLIENTS_SET_DELETE_SSID = "clientsSetDeletedSSID";
export const CLIENTS_SET_UPDATED_ANCHOR = "clientsSetUpdatedAnchor";

export const SET_CURRENT_TIME = "setCurrentTime";
