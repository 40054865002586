import actions from "./dashboard.store.actions";
import mutations from "./dashboard.store.mutations";
import state from "./dashboard.store.state";
import { DashboardState } from "../dashboard.types";
import { RootState } from "@/store/store.types";
import { Module } from "vuex";

export const dashboardStore: Module<DashboardState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions
};
