import qs from "qs";

import { HttpRequest } from "@/services/api/http";

export default new (class AuthApi extends HttpRequest {
  constructor() {
    super();
  }

  public async authToken(email: string, password: string) {
    const res = await this.axiosClient.post(
      "/auth/token",
      qs.stringify({
        username: email,
        password
      }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    );

    return res;
  }

  public async forgotPassword(email: string) {
    return this.axiosClient.get(`/auth/forgot_password?email=${email}`);
  }

  public async resetPassword(
    email: string,
    password: string,
    clientSecret: string
  ) {
    return this.axiosClient.post(
      "/auth/reset_password",
      qs.stringify({
        username: email,
        password,
        'client_secret': clientSecret // eslint-disable-line
      }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    );
  }

  public async switchSuperUser(payload: any) {
    console.log(payload);
    return this.axiosClient.put(`/users/switch_super_user/`, {
      userId: payload.userId,
      newClientId: payload.newClientId
    });
  }

  public async sessionUser() {
    return this.axiosClient.get("/session/user");
  }

  public async logout() {
    return this.axiosClient.get("/auth/logout");
  }
})();
