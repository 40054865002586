import * as actionTypes from "@/store/action-types";
import * as mutationTypes from "@/store/mutation-types";
import { Anchor, ClientsStoreContext } from "../clients.types";
import clientsApi from "../api/clients.api";
import userApi from "@/modules/user/api/user.api";
import anchorsApi from "@/modules/anchors/api/anchors.api";
import router from "@/router";

export default {
  async [actionTypes.CLIENTS_GET_CLIENT](
    { commit, state }: ClientsStoreContext,
    id: number
  ) {
    commit(mutationTypes.CLIENTS_SET_CLIENT, {
      ...state.data,
      loading: true,
      error: null
    });

    try {
      const response = await clientsApi.getById(id);
      commit(mutationTypes.CLIENTS_SET_CLIENT, {
        data: response.data,
        loading: false,
        error: null
      });
    } catch (error) {
      commit(mutationTypes.CLIENTS_SET_CLIENT, {
        data: null,
        loading: false,
        error: error.response
      });
      throw error;
    }
  },

  [actionTypes.CLIENTS_UPDATE_SELECTED_ANCHOR](
    { commit, state }: ClientsStoreContext,
    anchorId: number
  ) {
    const selectedAnchor = state.anchors.data?.find(
      (anchor: Anchor) => anchor.anchorId === anchorId
    );
    commit(mutationTypes.CLIENTS_SET_SELECTED_ANCHOR, selectedAnchor);
  },

  [actionTypes.CLIENTS_UPDATE_SELECTED_ANCHOR_PROPERTIES](
    { commit, state }: ClientsStoreContext,
    selectedAnchor: any
  ) {
    commit(mutationTypes.CLIENTS_SET_SELECTED_ANCHOR, selectedAnchor);
  },

  [actionTypes.CLIENTS_UPDATE_NEXT_POSITION](
    { commit, state }: ClientsStoreContext,
    payload: any
  ) {
    const selectedAnchor = state.selectedAnchor;
    if (selectedAnchor != null) {
      if (selectedAnchor.nextPosition != null) {
        selectedAnchor.nextPosition.createdDate = payload.createdDate;
        selectedAnchor.nextPosition.id = payload.id;
        selectedAnchor.nextPosition.seenAt = payload.seenAt;
        selectedAnchor.nextPosition.tagId = payload.tagId;
        selectedAnchor.nextPosition.x = payload.x;
        selectedAnchor.nextPosition.y = payload.y;
      }
    }

    commit(mutationTypes.CLIENTS_SET_SELECTED_ANCHOR, selectedAnchor);
  },

  [actionTypes.CLIENTS_UPDATE_GLOBAL_VIEW](
    { commit, state }: ClientsStoreContext,
    globalView: boolean
  ) {
    commit(mutationTypes.CLIENTS_SET_GLOBAL_VIEW, globalView);
  },

  async [actionTypes.CLIENTS_GET_ANCHORS](
    { commit, state }: ClientsStoreContext,
    id: number
  ) {
    commit(mutationTypes.CLIENTS_SET_ANCHORS, {
      data: state.anchors.data,
      loading: true,
      error: null,
      isEmpty: false
    });

    try {
      const response = await clientsApi.getAnchorsById(id);
      commit(mutationTypes.CLIENTS_SET_ANCHORS, {
        data: response.data,
        loading: false,
        error: null,
        isEmpty: response.data.length === 0
      });

      if (
        state.selectedAnchor === null &&
        router.currentRoute.params.anchorId
      ) {
        const _anchor = state.anchors.data.find(
          anchor =>
            anchor.anchorId === Number(router.currentRoute.params.anchorId)
        );
        commit(
          mutationTypes.CLIENTS_SET_SELECTED_ANCHOR,
          _anchor || response.data[0]
        );
      } else {
        const _anchor = state.anchors.data.find(
          anchor => anchor.anchorId === state.selectedAnchor?.anchorId
        );
        commit(
          mutationTypes.CLIENTS_SET_SELECTED_ANCHOR,
          _anchor || response.data[0]
        );
      }

      return response;
    } catch (error) {
      commit(mutationTypes.CLIENTS_SET_ANCHORS, {
        data: null,
        loading: false,
        error: error.response,
        isEmpty: false
      });
      throw error;
    }
  },

  async [actionTypes.CLIENTS_GET_USERS](
    { commit, state }: ClientsStoreContext,
    id: number
  ) {
    commit(mutationTypes.CLIENTS_SET_USERS, {
      ...state.users.data,
      loading: true,
      error: null
    });

    try {
      const response = await clientsApi.getUsersById(id);
      commit(mutationTypes.CLIENTS_SET_USERS, {
        data: response.data,
        loading: false,
        error: null
      });
    } catch (error) {
      commit(mutationTypes.CLIENTS_SET_USERS, {
        data: null,
        loading: false,
        error: error.response
      });
      throw error;
    }
  },

  async [actionTypes.CLIENTS_GET_SSIDS](
    { commit, state }: ClientsStoreContext,
    id: number
  ) {
    commit(mutationTypes.CLIENTS_SET_SSIDS, {
      ...state.ssids.data,
      loading: true,
      error: null
    });

    try {
      const response = await clientsApi.getSsidsById(id);
      commit(mutationTypes.CLIENTS_SET_SSIDS, {
        data: response.data,
        loading: false,
        error: null
      });
    } catch (error) {
      commit(mutationTypes.CLIENTS_SET_SSIDS, {
        data: null,
        loading: false,
        error: error.response
      });
      throw error;
    }
  },

  async [actionTypes.CLIENTS_CREATE_USER](
    { commit }: ClientsStoreContext,
    payload: any
  ) {
    const res = await userApi.createUser(payload);
    payload.id = res.data.id;
    commit(mutationTypes.CLIENTS_SET_USER, payload);
  },

  async [actionTypes.CLIENTS_DELETE_USER](
    { commit }: ClientsStoreContext,
    id: number
  ) {
    await userApi.deleteUser(id);
    commit(mutationTypes.CLIENTS_SET_DELETE_USER, id);
  },

  async [actionTypes.CLIENTS_CREATE_SSID](
    { commit }: ClientsStoreContext,
    payload: any
  ) {
    const res = await clientsApi.createSsid(payload);
    payload.id = res.data.id;
    commit(mutationTypes.CLIENTS_SET_SSID, payload);
  },

  async [actionTypes.CLIENTS_UPDATE_SSID](
    { commit }: ClientsStoreContext,
    payload: any
  ) {
    await clientsApi.updateSsidById(payload);
    commit(mutationTypes.CLIENTS_SET_UPDATE_SSID, payload);
  },

  async [actionTypes.CLIENTS_DELETE_SSID](
    { commit }: ClientsStoreContext,
    id: number
  ) {
    await clientsApi.deleteSsidById(id);
    commit(mutationTypes.CLIENTS_SET_DELETE_SSID, id);
  },

  async [actionTypes.CLIENTS_UPDATE_ANCHOR](
    { commit, state }: ClientsStoreContext,
    payload: any
  ) {
    try {
      const response = await anchorsApi.updateAnchor(
        payload.label,
        payload.id,
        payload.staticIp,
        payload.gateway,
        payload.subnet,
        payload.dnsPrimary,
        payload.dnsSecondary,
        payload.buzzerDisable,
        payload.firmwareId,
        payload.setup,
        payload.updateSsids,
        payload.updateWifiOptions
      );

      if (state.selectedAnchor?.anchorId === response.data.anchorId) {
        commit(mutationTypes.CLIENTS_SET_SELECTED_ANCHOR, response.data);
        commit(`user/${mutationTypes.USER_UPDATE_ANCHOR}`, response.data, {
          root: true
        });
      }
      commit(mutationTypes.CLIENTS_SET_UPDATED_ANCHOR, payload);
    } catch (err) {
      // Do nothing
    }
  }
};
