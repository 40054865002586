<template>
  <div class="lines-container">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      :class="[
        'lines-icon',
        isButtonActive ? 'active-color' : 'inactive-color'
      ]"
    >
      <line x1="3" x2="21" y1="6" y2="6" />
      <line x1="3" x2="21" y1="12" y2="12" />
      <line x1="3" x2="21" y1="18" y2="18" />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    isButtonActive: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
.lines-icon {
  width: 16px;
  height: 16px;
}

.lines-container .active-color line {
  stroke: $--color-primary;
}
.lines-container .inactive-color line {
  stroke: #a5b3bc;
}

.lines-container:hover .lines-icon line {
  stroke: $--color-primary;
}
</style>
