import * as actionTypes from "@/store/action-types";
import * as mutationTypes from "@/store/mutation-types";
import { DashboardStoreContext, DashboardData } from "../dashboard.types";

export default {
  async [actionTypes.SET_CURRENT_TIME](
    { commit }: DashboardStoreContext,
    payload?: DashboardData
  ) {
    commit(mutationTypes.SET_CURRENT_TIME, payload);
  }
};
