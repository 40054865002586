<template>
  <div class="anchors-stats">
    <div
      v-for="anchor in anchors.data"
      v-bind:key="anchor.anchorId"
      class="flex-child"
    >
      <div class="div-wrapper">
        <div class="anchor-label">
          <h2>{{ anchor.label }}</h2>
        </div>
        <div class="online-status">
          <p>
            <span
              v-if="anchor.rssi"
              :class="
                isAnchorOffline(anchor.powerSource, anchor.offlineTs)
                  ? 'offline-display'
                  : ''
              "
            >
              <wifi-icon
                :signal="anchor.rssi"
                :noConnection="
                  isAnchorOffline(anchor.powerSource, anchor.offlineTs)
                "
              />
              <span
                v-if="
                  isAnchorPowerSourceDisconnected(
                    anchor.lastHeartbeat ? anchor.lastHeartbeat : null
                  )
                "
              >
                <span>
                  <img src="@/assets/battery_dead.svg" alt="no battery" />
                </span>
              </span>
              <span
                v-if="isAnchorOffline(anchor.powerSource, anchor.offlineTs)"
                class="rssi__offline"
              >
                Offline
              </span>
            </span>
            <template v-if="anchor.currentRouteInstance">
              | <b> Route Time: </b>
              <span
                v-bind:style="{
                  color: computedColor(anchor),
                  fontWeight: computedWeight(anchor)
                }"
              >
                <span>
                  {{ getRouteTotalHours(anchor)
                  }}{{ getRouteTotalMinutes(anchor) }}m:{{
                    getRouteTotalSeconds(anchor)
                  }}s
                </span>
                <span v-if="anchor.activeRouteOnTime">
                  ({{ getRouteDelay(anchor) }})
                </span>
              </span>
            </template>
          </p>
        </div>
        <template
          v-if="
            anchor.recentRoutes !== null &&
              JSON.stringify(anchor.recentRoutes) !== JSON.stringify([])
          "
        >
          <template
            v-if="
              anchor.recentRoutes.length > 0 &&
                JSON.stringify(anchor.recentRoutes) !== JSON.stringify([''])
            "
          >
            <!--:row-class-name="tableRowStyle"-->
            <div class="sidebar-table">
              <el-table
                ref="anchor.recentRoutes"
                :data="
                  anchor.activeRouteOnTime != null &&
                  anchor.activeRouteOnTime.length > 0
                    ? [anchor.activeRouteOnTime[1]].concat(anchor.recentRoutes)
                    : anchor.recentRoutes
                "
                style="width: 100%"
              >
                <el-table-column label="" width="35px">
                  <template slot-scope="scope">
                    <i v-if="scope.row.error" class="el-icon-warning"></i>
                  </template>
                </el-table-column>
                <el-table-column
                  label="Start (hh:mm)"
                  :resizable="false"
                  width="130px"
                >
                  <template slot-scope="scope">
                    <template
                      v-if="scope.$index === 0 && anchor.currentRouteInstance"
                    >
                      <b>
                        {{
                          formatDateCorrectly(
                            anchor.currentRouteInstance.startDate,
                            true
                          )
                        }}
                      </b>
                    </template>

                    <template v-else-if="scope.row.startTime">
                      <template v-if="anotherDay(scope.row.startTime)">
                        {{
                          formatDateCorrectly(scope.row.startTime, true) +
                            " (" +
                            moment
                              .tz(
                                moment.unix(scope.row.startTime),
                                clientData.timezone
                              )
                              .format("DD/MM") +
                            ")"
                        }}
                      </template>
                      <template v-else>
                        {{ formatDateCorrectly(scope.row.startTime, true) }}
                      </template>
                    </template>
                  </template>
                </el-table-column>

                <el-table-column label="Duration (hh:mm)" :resizable="false">
                  <template slot-scope="scope">
                    <template
                      v-if="scope.$index === 0 && anchor.currentRouteInstance"
                    >
                      <b
                        >{{
                          getRouteTotalHours(anchor) === ""
                            ? "00:"
                            : getRouteTotalHours(anchor)
                        }}{{ getRouteTotalMinutes(anchor) }}
                      </b>
                    </template>
                    <template v-else-if="scope.row.duration">
                      {{
                        moment.utc(scope.row.duration * 1000).format("HH") +
                          "h" +
                          moment.utc(scope.row.duration * 1000).format("mm") +
                          "m"
                      }}
                    </template>
                  </template>
                </el-table-column>

                <el-table-column label="Delay (hh:mm)" :resizable="false">
                  <template slot-scope="scope">
                    <template
                      v-if="
                        scope.$index === 0 &&
                          anchor.activeRouteOnTime != null &&
                          !scope.row.error
                      "
                    >
                      <b>{{ getRouteTableDelay(anchor) }}</b>
                    </template>
                    <template v-else-if="!scope.row.error">
                      {{
                        scope.row.delay > 0
                          ? formatDateCorrectly(scope.row.delay, false)
                          : "-" + formatDateCorrectly(scope.row.delay, false)
                      }}
                    </template>
                    <template v-else>
                      <hr />
                    </template>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </template>
          <template v-else>
            <div class="no-recent-routes">
              No Recent Routes Were Found
            </div>
          </template>
        </template>
        <template v-else>
          <div class="no-recent-routes">
            No Recent Routes Were Found
          </div>
        </template>
        <template>
          <div class="wrapper" v-if="anchor.usageInfo">
            <div id="today">
              <b>Today</b>
            </div>
            <div id="two-weeks">
              <b>Last Two Weeks</b>
            </div>
            <div id="laps">
              <b>Nr of Valid Laps</b>
            </div>
            <div id="laps-length">
              {{
                (anchor.usageInfo.dCount || 0) +
                  "/" +
                  (anchor.usageInfo.dCount + anchor.usageInfo.dCountError ||
                    0) +
                  " (" +
                  returnPercentage(
                    anchor.usageInfo.dCount,
                    anchor.usageInfo.dCount + anchor.usageInfo.dCountError
                  ) +
                  "%)"
              }}
            </div>
            <div id="routes-two-weeks">
              {{
                (anchor.usageInfo.twCount || 0) +
                  "/" +
                  (anchor.usageInfo.twCount + anchor.usageInfo.twErrors || 0) +
                  " (" +
                  returnPercentage(
                    anchor.usageInfo.twCount,
                    anchor.usageInfo.twCount + anchor.usageInfo.twErrors
                  ) +
                  "%)"
              }}
            </div>
            <div id="laps-errors">
              <b>Nr of Laps w/ Errors</b>
            </div>
            <div id="laps-errors-length">
              {{
                anchor.usageInfo.dCountError != null
                  ? anchor.usageInfo.dCountError
                  : "No info"
              }}
            </div>
            <div id="routes-two-weeks-errors">
              {{
                anchor.usageInfo.twCount != null
                  ? anchor.usageInfo.twErrors
                  : "No info"
              }}
            </div>
            <div id="average-title">
              <b>Avg Duration</b>
            </div>
            <div id="average-today">
              {{
                anchor.usageInfo.dAvg != null
                  ? moment.utc(anchor.usageInfo.dAvg * 1000).format("HH:mm")
                  : "No info"
              }}
            </div>
            <div id="average-weeks">
              {{
                anchor.usageInfo.twAvg != null
                  ? moment.utc(anchor.usageInfo.twAvg * 1000).format("HH:mm")
                  : "No info"
              }}
            </div>
            <div id="usage-title">
              <b>Travelling Percentage</b>
            </div>
            <div id="usage-today">
              {{
                anchor.usageInfo.dUsage != null
                  ? anchor.usageInfo.dUsage + "%"
                  : "No Info"
              }}
            </div>
            <div id="usage-weeks">
              {{
                anchor.usageInfo.twUsage != null
                  ? anchor.usageInfo.twUsage + "%"
                  : "No Info"
              }}
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import * as actionTypes from "@/store/action-types";
import moment from "moment-timezone";
import * as routerTypes from "@/router/router.types";
import wifiIcon from "@/components/wifi-icon.vue";
import cloneDeep from "lodash";

export default {
  components: {
    wifiIcon
  },
  computed: {
    ...mapState("clients", {
      anchors: "anchors",
      selectedAnchor: "selectedAnchor",
      clientData: "data"
    }),
    ...mapState("auth", {
      user: "user"
    }),
    ...mapState("user", {
      resources: "resources"
    }),
    tableHeaderColor() {
      return {
        backgroundColor: "#e9ecee",
        color: "#000",
        fontWeight: 500,
        textAlign: "center"
      };
    }
  },

  watch: {
    "resources.data": {
      deep: true,
      async handler(newValue) {
        if (newValue.anchors.length) {
          this.anchors.data.forEach(element => {
            newValue.anchors.forEach(newElement => {
              if (element.anchorId === newElement.anchorId) {
                element.activeRouteOnTime = newElement.activeRouteOnTime;
                element.activeRouteStart = newElement.activeRouteStart;
                element.lastHeartbeat = newElement.lastHeartbeat;
              }
            });
          });
        }
        const _self = this; // eslint-disable-line
        this.resources.usageInfo.forEach(value => {
          _self.anchors.data.forEach(anchor => {
            if (anchor.anchorId === value.anchorId) {
              anchor.usageInfo = {};
              if (anchor.activeRoute) {
                anchor.usageInfo = value.routesInfo.find(
                  route => route.routeId === anchor.activeRoute.id
                );
              }
              anchor.usageInfo.anchorId = value.anchorId;
              anchor.usageInfo.dUsage = value.dUsage;
              anchor.usageInfo.twUsage = value.twUsage;
            }
          });
        });
      }
    },

    "resources.usageInfo": {
      deep: true,
      async handler(newValue) {
        const _self = this; // eslint-disable-line
        newValue.forEach(value => {
          _self.anchors.data.forEach(anchor => {
            if (anchor.anchorId === value.anchorId) {
              anchor.usageInfo = {};
              if (anchor.activeRoute) {
                anchor.usageInfo = value.routesInfo.find(
                  route => route.routeId === anchor.activeRoute.id
                );
              }
              anchor.usageInfo.anchorId = value.anchorId;
              anchor.usageInfo.dUsage = value.dUsage;
              anchor.usageInfo.twUsage = value.twUsage;
            }
          });
        });
      }
    }
  },

  data() {
    return {
      moment,
      interval: null,
      intervalUsageInfo: null,
      intervalResources: null
    };
  },

  methods: {
    ...mapActions("clients", {
      getAnchors: actionTypes.CLIENTS_GET_ANCHORS,
      updateSelectedAnchor: actionTypes.CLIENTS_UPDATE_SELECTED_ANCHOR,
      updateGlobalView: actionTypes.CLIENTS_UPDATE_GLOBAL_VIEW
    }),
    ...mapActions("user", {
      getResources: actionTypes.USER_GET_RESOURCES,
      getUsageInfo: actionTypes.USER_GET_USAGE_INFO
    }),
    toggle() {
      this.$refs["fullscreen"].toggle();
    },
    /*tableRowStyle({ row, rowIndex }) {
      if (rowIndex == 0)
        return row == null
          ? "row-style"
          : row > 0
          ? "row-style__red"
          : row < 0
          ? "row-style__green"
          : "row-style";
      return "row-style";
    },*/
    computedColor(info) {
      if (info.activeRouteOnTime === null) return "#1F4258";
      else {
        if (info.activeRouteOnTime[0] === 0) return "#1F4258";
        else if (info.activeRouteOnTime[0] === -1) return "green";
        else return "red";
      }
    },
    returnPercentage(divisor, dividend) {
      if (dividend)
        return Number.parseFloat((divisor / dividend) * 100).toFixed(1);
      else return 0;
    },
    computedWeight(info) {
      if (info.activeRouteOnTime === null) return "normal";
      else {
        return info.activeRouteOnTime[0] === 0 ? "normal" : "bold";
      }
    },
    isAnchorPowerSourceDisconnected(heartbeat) {
      if (heartbeat) if (!heartbeat.powerSource) return true;
      return false;
    },
    isAnchorOffline(powerSource, offlineTs) {
      if (!offlineTs) return false;
      else {
        const sixMinutesAgo = moment()
          .utc()
          .subtract(6, "minutes");

        if (!powerSource)
          if (
            moment
              .tz(moment.unix(offlineTs), this.clientData.timezone)
              .isBefore(sixMinutesAgo)
          )
            return true;
          else {
            const oneMinuteAgo = moment()
              .utc()
              .subtract(60, "seconds");

            if (
              moment
                .tz(moment.unix(offlineTs), this.clientData.timezone)
                .isBefore(oneMinuteAgo)
            )
              return true;
          }
      }
    },

    formatDateCorrectly(targetTime, date) {
      if (!date) {
        if (targetTime >= 3600)
          return (
            moment.utc(Math.abs(targetTime) * 1000).format("HH") +
            "h" +
            moment.utc(Math.abs(targetTime) * 1000).format("HH") +
            "m"
          );
        else
          return (
            moment.utc(Math.abs(targetTime) * 1000).format("mm") +
            "m" +
            moment.utc(Math.abs(targetTime) * 1000).format("ss") +
            "s"
          );
      } else {
        if (targetTime >= 3600)
          return (
            moment
              .tz(moment.unix(targetTime), this.clientData.timezone)
              .format("HH") +
            "h" +
            moment
              .tz(moment.unix(targetTime), this.clientData.timezone)
              .format("mm") +
            "m"
          );
        else
          return (
            moment
              .tz(moment.unix(targetTime), this.clientData.timezone)
              .format("mm") +
            "m" +
            moment
              .tz(moment.unix(targetTime), this.clientData.timezone)
              .format("ss") +
            "s"
          );
      }
    },

    getRouteTotalHours(info) {
      const currentTime = moment.tz(this.clientData.timezone);

      const routeStart = moment.tz(
        moment.unix(info.currentRouteInstance.startDate),
        this.clientData.timezone
      );

      if (Math.floor(currentTime.diff(routeStart, "seconds") / 3600) >= 1)
        return (
          Math.floor(
            currentTime.diff(routeStart, "seconds") / 3600
          ).toString() + "h"
        );
      else return "";
    },

    getRouteTotalMinutes(info) {
      const minutes = Math.floor(
        (moment()
          .utc()
          .diff(
            moment.tz(
              moment.unix(info.currentRouteInstance.startDate),
              this.clientData.timezone
            ),
            "seconds"
          ) %
          3600) /
          60
      );

      return minutes < 10 ? "0" + minutes : minutes;
    },

    getRouteTotalSeconds(info) {
      const secondsDiff = Math.floor(
        (moment()
          .utc()
          .diff(
            moment.tz(
              moment.unix(info.currentRouteInstance.startDate),
              this.clientData.timezone
            ),
            "seconds"
          ) %
          3600) %
          60
      );
      if (secondsDiff < 10) return "0" + secondsDiff;
      else return secondsDiff;
    },
    anotherDay(startTime) {
      if (moment().dayOfYear() !== moment.unix(startTime).dayOfYear())
        return true;
      return false;
    },
    getRouteDelay(info) {
      let hours;
      let minutes;
      if (info.activeRouteOnTime[1] >= 0) {
        hours = Math.floor(info.activeRouteOnTime[1] / 3600);
        minutes = Math.floor((info.activeRouteOnTime[1] % 3600) / 60);
      } else {
        hours = Math.ceil(info.activeRouteOnTime[1] / 3600);
        minutes = Math.ceil((info.activeRouteOnTime[1] % 3600) / 60);
      }

      const minutesString = Math.abs(minutes).toString();

      if (Math.abs(hours) >= 1)
        return (
          (minutes > 0 ? "+" + hours.toString() : hours.toString()) +
          "h:" +
          (minutes < 10 && minutes > -10
            ? "0" + minutesString
            : minutesString) +
          "m"
        );
      else return (minutes > 0 ? "+" + minutesString : minutesString) + "m";
    },
    getRouteTableDelay(info) {
      return info.activeRouteOnTime[1] < -60
        ? "-" +
            moment
              .utc(Math.abs(info.activeRouteOnTime[1]) * 1000)
              .format("HH:mm")
        : moment
            .utc(Math.abs(info.activeRouteOnTime[1]) * 1000)
            .format("HH:mm");
    }
    /*redirect(anchorId) {
      this.updateSelectedAnchor(anchorId);
      if (this.interval) {
        clearInterval(this.interval);
      }

      if (this.intervalResources) {
        clearInterval(this.intervalResources);
      }

      if (this.intervalUsageInfo) {
        clearInterval(this.intervalUsageInfo);
      }
      this.$router.push({
        name: routerTypes.ROUTE_DASHBOARD,
        params: {
          anchorId: anchorId
        }
      });
    }*/
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    if (this.intervalResources) {
      clearInterval(this.intervalResources);
    }
    if (this.intervalUsageInfo) {
      clearInterval(this.intervalUsageInfo);
    }
  },
  created() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    if (this.intervalResources) {
      clearInterval(this.intervalResources);
    }
    if (this.intervalUsageInfo) {
      clearInterval(this.intervalUsageInfo);
    }

    this.interval = setInterval(
      function(_self) {
        if (!_self.isEditing) {
          _self.getResources({
            timestamp:
              _self.nextTimestamp ||
              Math.floor((new Date().getTime() - 3000) / 1000),
            simplified: true
          });
        }

        if (_self.nextTimestamp) {
          _self.nextTimestamp = null;
        }
      },
      2000,
      this
    );

    this.intervalUsageInfo = setInterval(
      function(_self) {
        _self.getUsageInfo(_self.user.data.clientId);
      },
      3600000, //1 hour
      this
    );

    this.intervalResources = setInterval(
      function(_self) {
        _self.getResources();
      },
      300000, // 5minutes
      this
    );

    this.anchors.data = this.anchors.data.filter(element => element != "");

    this.updateGlobalView(true);

    this.resources.usageInfo.forEach(value => {
      this.anchors.data.forEach(anchor => {
        if (anchor.anchorId === value.anchorId) {
          anchor.usageInfo = {};
          if (anchor.activeRoute) {
            anchor.usageInfo = value.routesInfo.find(
              route => route.routeId === anchor.activeRoute.id
            );
          }
          anchor.usageInfo.anchorId = value.anchorId;
          anchor.usageInfo.dUsage = value.dUsage;
          anchor.usageInfo.twUsage = value.twUsage;
        }
      });
    });
  }
};
</script>

<style lang="scss">
.anchors-stats {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 10%;
  color: black;

  .row-style {
    background-color: #fff;
    text-align: center;

    &__red {
      color: red;
    }

    &__green {
      color: green;
    }
  }

  .sidebar-table {
    th,
    tr,
    td {
      text-align: center;
    }
  }

  @media (max-width: 800px) {
    .flex-child {
      flex: 100%;
      max-width: 400px;
    }
  }

  .flex-child {
    flex: 33%;
    max-width: 500px;
  }

  .div-wrapper {
    height: 58vh;
    max-height: 550px;
    background-color: #fff;
    border: 3px solid #fff;
    box-shadow: 1px 1px 1px 1px #c9d1d6;
    border-radius: 5px;
    margin-top: 5%;
    margin-right: 5%;
    overflow-y: auto;
    color: $--color-primary;
    position: relative;
  }

  .anchor-label {
    margin-bottom: 3%;
    background-color: $--color-primary;
    color: white;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .no-recent-routes {
    height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .wrapper {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 10px;
    color: $--color-primary;
    position: relative;
    top: 60px;
    width: 100%;
  }

  #today {
    grid-column-start: 2;
    grid-row-start: 1;
  }
  #two-weeks {
    grid-column-start: 3;
    grid-row-start: 1;
  }
  #laps {
    text-align: left;
    padding-left: 5%;
    grid-column-start: 1;
    grid-row-start: 2;
  }
  #laps-length {
    grid-column-start: 2;
    grid-row-start: 2;
  }
  #laps-errors {
    text-align: left;
    padding-left: 5%;
    grid-column-start: 1;
    grid-row-start: 3;
  }
  #laps-errors-length {
    grid-column-start: 2;
    grid-row-start: 3;
  }
  #routes-two-weeks-errors {
    grid-column-start: 3;
    grid-row-start: 3;
  }
  #average-title {
    text-align: left;
    padding-left: 5%;
    grid-column-start: 1;
    grid-row-start: 4;
  }
  #average-today {
    grid-column-start: 2;
    grid-row-start: 4;
  }
  #average-weeks {
    grid-column-start: 3;
    grid-row-start: 4;
  }
  #routes-two-weeks {
    grid-column-start: 3;
    grid-row-start: 2;
  }
  #usage-title {
    text-align: left;
    padding-left: 5%;
    grid-column-start: 1;
    grid-row-start: 5;
  }
  #usage-today {
    grid-column-start: 2;
    grid-row-start: 5;
  }
  #usage-weeks {
    grid-column-start: 3;
    grid-row-start: 5;
  }

  .online-status {
    margin-top: 23px;
    margin-bottom: 23px;
  }

  .rssi__offline {
    color: $--color-danger;
    font-weight: bold;
    margin-top: 3px;
  }

  .offline-display {
    display: flex;
    justify-content: center;
  }

  .wifi {
    margin-right: 30px;
    margin-top: 5px;
    &__no-connection {
      top: 5px;
      left: 2px;
      width: 24px;
      height: 3px;
    }

    svg {
      position: absolute;
      top: -22px;
      left: -1px;
      width: 30px;
    }
  }
}
</style>
