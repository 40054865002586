import * as mutationTypes from "@/store/mutation-types";
import { AuthState, AuthUser } from "../auth.types";
import { initialState } from "./auth.store.state";

export default {
  [mutationTypes.AUTH_SET_USESR](state: AuthState, payload: AuthUser) {
    state.user = payload;
  },

  reset(state: AuthState) {
    const s = initialState();
    Object.keys(s).forEach(key => {
      (state as any)[key] = (s as any)[key];
    });
  }
};
