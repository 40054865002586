<template>
  <div class="asset-manager">
    <div class="asset-manager__head">
      <h2>Asset Manager</h2>
    </div>
    <div
      class="asset-manager__content"
      v-loading="loadingAssets"
      element-loading-background="#F0F5FB"
    >
      <div class="asset-metrics-container" v-if="!loadingAssets">
        <div class="text-container">
          <span class="title">Live Metrics</span>
          <span class="subtitle">Last Update: {{ lastUpdate }}</span>
        </div>
        <div class="asset-cards">
          <AssetCard
            tagsType="Inside"
            :tagList="insideTags"
            :toHex="toHex"
            :tagToHighlight="tagToHighlight"
            :percentage="getPercentage(insideTags)"
          />
          <AssetCard
            tagsType="Outside"
            :tagList="outsideTags"
            :toHex="toHex"
            :tagToHighlight="tagToHighlight"
            :percentage="getPercentage(outsideTags)"
          />
          <AssetCard
            tagsType="Warning"
            :tagList="warningTags"
            :toHex="toHex"
            :tagToHighlight="tagToHighlight"
            :percentage="getPercentage(warningTags)"
          />
          <AssetCard
            tagsType="Undefined"
            :tagList="undefinedTags"
            :toHex="toHex"
            :tagToHighlight="tagToHighlight"
            :percentage="getPercentage(undefinedTags)"
          />
        </div>
        <div class="progress-bar-container">
          <span class="total"><b>Total Tags: </b>{{ tagList.length }}</span>
          <div class="progress-bars">
            <div class="progress-bar">
              <span>Inside</span>
              <el-progress
                :stroke-width="11"
                :percentage="getPercentage(this.insideTags)"
                color="#3C8300"
              ></el-progress>
            </div>
            <div class="progress-bar">
              <span>Outside</span>
              <el-progress
                :stroke-width="11"
                :percentage="getPercentage(this.outsideTags)"
                color="#1F4258"
              ></el-progress>
            </div>
            <div class="progress-bar">
              <span>Warning</span>
              <el-progress
                :stroke-width="11"
                :percentage="getPercentage(this.warningTags)"
                color="#D4BA32"
              ></el-progress>
            </div>
            <div class="progress-bar">
              <span>Undefined</span>
              <el-progress
                :stroke-width="11"
                :percentage="getPercentage(this.undefinedTags)"
                color="#A5B3BC"
              ></el-progress>
            </div>
          </div>
        </div>
      </div>
      <div class="asset-analytics-container" v-if="!loadingAssets">
        <div class="text-container">
          <span class="title">Statistical Metrics</span>
          <span class="subtitle"
            >This section provides metrics of the store activity. Select other
            dates to explore data for other specific periods.
          </span>
          <div class="date-picker">
            <el-date-picker
              v-model="dateRanges"
              type="daterange"
              :picker-options="pickerOptions"
              range-separator="To"
              start-placeholder="Start date"
              end-placeholder="End date"
              @change="onSelectDateRanges"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="analytics-data">
          <div class="data-container">
            <div class="data-icon">
              <img src="@/assets/check-list.svg" alt="check-list" />
            </div>
            <div class="data">
              <span class="data-label">Total Used Tags:</span>
              <span class="data-value"
                >{{ totalUsageTags }} of {{ tagList?.length ?? 0 }}</span
              >
            </div>
          </div>
          <div class="data-container">
            <div class="data-icon">
              <img src="@/assets/warning-grey.svg" alt="warning" />
            </div>
            <div class="data">
              <span class="data-label">Total Warnings:</span>
              <span class="data-value">{{ totalBuzzerTags }}</span>
            </div>
          </div>
          <div class="data-container">
            <div class="data-icon">
              <img src="@/assets/users.svg" alt="users" />
            </div>
            <div class="data">
              <span class="data-label">Total Customer Records:</span>
              <span class="data-value">{{ totalCustomerRecords }}</span>
            </div>
          </div>
          <div class="data-container">
            <div class="data-icon">
              <img src="@/assets/user.svg" alt="user" />
            </div>
            <div class="data">
              <span class="data-label">Customers per Hour (avg):</span>
              <span class="data-value">{{ totalTagsAvg }}</span>
            </div>
          </div>
          <div class="data-container">
            <div class="data-icon">
              <img src="@/assets/clock.svg" alt="clock" />
            </div>
            <div class="data">
              <span class="data-label">Time Spent in Store (avg):</span>
              <span class="data-value">{{ totalAverageTime }} min</span>
            </div>
          </div>
          <div class="data-container">
            <div class="data-icon">
              <img src="@/assets/calendar-check.svg" alt="calendar-check" />
            </div>
            <div class="data">
              <span class="data-label">Total Days Analyzed:</span>
              <span class="data-value">{{ totalDaysAnalyzed }}</span>
            </div>
          </div>
        </div>
        <div class="chart-container">
          <AssetsBarChart :data="chartData" />
          <AssetsBarChart :data="chartData" :typeOfDataSet="'laps'" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment-timezone";
import tagsApi from "@/modules/tags/api/tags.api";
import AssetCard from "@/components/AssetCard.vue";
import AssetsBarChart from "./stats/components/assets-bar-chart.vue";

export default {
  name: "AssetManager",
  components: {
    AssetCard,
    AssetsBarChart
  },
  data() {
    return {
      moment,
      chartData: [],
      interval: null,
      lastUpdate: "",
      loadingAssets: true,
      tagList: [],
      tagToHighlight: "",
      insideTags: [],
      warningTags: [],
      outsideTags: [],
      undefinedTags: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "Default",
            onClick(picker) {
              const end = new Date();
              const start = new Date("2024-09-20T00:00:00");
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "Today",
            onClick(picker) {
              const start = new Date();
              picker.$emit("pick", [start, ""]);
            }
          },
          {
            text: "Yesterday",
            onClick(picker) {
              const start = new Date(
                new Date().setDate(new Date().getDate() - 1)
              );
              picker.$emit("pick", [start, ""]);
            }
          },
          {
            text: "Last week",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "Last month",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "Last 3 months",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      dateRanges: [],
      totalUsageTags: 0,
      totalBuzzerTags: 0
    };
  },
  computed: {
    ...mapState("clients", {
      clientData: "data",
      anchors: "anchors"
    }),
    totalAverageTime() {
      if (this.chartData.length) {
        let sumAvgTime = 0;
        this.chartData.forEach(element => (sumAvgTime += element.avgDuration));
        sumAvgTime = sumAvgTime / 60;
        return Math.round(sumAvgTime / this.chartData.length);
      }
      return 0;
    },
    totalTagsAvg() {
      if (this.chartData.length) {
        let sumAvgTags = 0;
        this.chartData.forEach(element => (sumAvgTags += element.totalTags));
        if (this.totalDaysAnalyzed) {
          const total =
            sumAvgTags / this.chartData.length / this.totalDaysAnalyzed;
          return total % 1 !== 0 ? total.toFixed(2) : total;
        }
      }
      return 0;
    },
    totalCustomerRecords() {
      if (this.chartData.length) {
        let sumTotal = 0;
        this.chartData.forEach(element => (sumTotal += element.totalTags));
        return sumTotal;
      }
      return 0;
    },
    totalDaysAnalyzed() {
      if (this.dateRanges) {
        if (this.dateRanges[0] && this.dateRanges[1]) {
          const momentStart = moment(this.dateRanges[0]).endOf("day");
          const momentEnd = moment(this.dateRanges[1]).endOf("day");
          const daysDifference = momentEnd.diff(momentStart, "days");
          return daysDifference + 1;
        } else if (this.dateRanges[0] && !this.dateRanges[1]) {
          return 1;
        }
      }
      return 0;
    }
  },

  watch: {
    warningTags(newValue, oldValue) {
      if (newValue && oldValue && newValue.length != oldValue.length) {
        this.onSelectDateRanges(this.dateRanges);
      }
    }
  },
  methods: {
    async getAnchorTagsList() {
      try {
        const res = await tagsApi.getAnchorTagsList(600000);
        this.tagList = res.data;
        this.tagList.sort((a, b) => b.lastSeenTs - a.lastSeenTs);
        this.analyseTags();
      } catch (error) {
        console.log("Error fetching tags list:", error);
      } finally {
        if (this.loadingAssets) {
          this.loadingAssets = false;
        }
        this.lastUpdate = moment
          .tz(moment(), this.clientData.timezone)
          .format("HH:mm (MM/DD/YYYY)");
      }
    },
    async getAverageTime(startDate = null, endDate = null) {
      try {
        const res = await tagsApi.getAverageTime(startDate, endDate);
        this.chartData = res.data;
      } catch (error) {
        console.log("Error while trying to get average time:", error);
        this.chartData = [];
      }
    },
    async getInOutTotalUsage(startDate = null, endDate = null) {
      try {
        const res = await tagsApi.getInOutTotalUsage(startDate, endDate);
        if (res.data) {
          this.totalUsageTags = res.data.totalUsageTags;
        }
      } catch (error) {
        console.log("Error while trying to get total tags", error);
        this.totalUsageTags = 0;
      }
    },
    async getTotalBuzzerTags(startDate = null, endDate = null) {
      try {
        const res = await tagsApi.getTotalBuzzerTags(startDate, endDate);
        if (res.data) {
          this.totalBuzzerTags = res.data.totalBuzzerTags;
        }
        return;
      } catch (error) {
        console.log("Error while trying to get total warning tags", error);
        this.totalBuzzerTags = 0;
      }
    },
    toHex(str) {
      return str
        .toString(16)
        .toUpperCase()
        .match(/.{1,2}/g)
        .join("");
    },
    analyseTags() {
      this.outsideTags = [];
      this.insideTags = [];
      this.undefinedTags = [];
      this.warningTags = [];
      this.tagList.forEach(tag => {
        if (tag.anchorId == null) this.undefinedTags.push(tag);

        this.anchors.data.forEach(anchor => {
          if (tag.anchorId == anchor.id && anchor.anchorInfo == "entry")
            this.insideTags.push(tag);
          else if (tag.anchorId == anchor.id && anchor.anchorInfo == "out")
            this.outsideTags.push(tag);
          else if (tag.anchorId == anchor.id && anchor.anchorInfo == "buzzer")
            this.warningTags.push(tag);
        }, this);
      }, this);
    },
    getPercentage(tagsArray) {
      const totalTags = this.tagList.length;
      if (totalTags > 0) {
        const tagQuantity = tagsArray.length;

        if (tagQuantity === 0) {
          return 0;
        }

        const tagPerc = (tagQuantity * 100) / totalTags;
        const roundedTagPerc = parseFloat(tagPerc.toFixed(2));
        return roundedTagPerc;
      } else return 0;
    },
    onSelectDateRanges(dateRanges) {
      let startDate;
      let endDate;

      if (dateRanges) {
        startDate = dateRanges[0]
          ? moment(dateRanges[0])
              .endOf("day")
              .unix()
          : null;
        endDate = dateRanges[1]
          ? moment(dateRanges[1])
              .endOf("day")
              .unix()
          : null;

        this.getAverageTime(startDate, endDate);
        this.getInOutTotalUsage(startDate, endDate);
        this.getTotalBuzzerTags(startDate, endDate);
      } else {
        this.chartData = [];
        this.totalBuzzerTags = 0;
        this.totalUsageTags = 0;
      }
    }
  },
  created() {
    this.interval = setInterval(this.getAnchorTagsList, 2000);

    const startDate = new Date("2024-09-20T00:00:00");
    const endDate = new Date();

    this.dateRanges = [startDate, endDate];
    this.onSelectDateRanges(this.dateRanges);
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
};
</script>
<style lang="scss">
.asset-manager {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  margin-bottom: 40px;
  min-height: calc(100vh - 61px);

  &__head {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 35px 24px 15px;
    flex-wrap: wrap;
  }

  &__content {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    min-height: 500px;
    padding: 0 30px;

    @media (max-width: 850px) {
      padding: 0;
    }

    .asset-metrics-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      background-color: #fff;
      padding: 28px 30px 30px;
      gap: 22px;
      border: 1px solid #e5e5e5;
      border-radius: 8px;
      height: min-content;

      @media (max-width: 850px) {
        width: 75vw;
      }

      .text-container {
        display: flex;
        flex-direction: column;
        gap: 2px;
        align-self: self-start;

        .title {
          font-size: 18px;
          font-weight: bold;
          text-align: left;
          align-self: flex-start;
          color: #000000;
        }

        .subtitle {
          font-size: 14px;
          font-weight: bold;
          text-align: left;
          align-self: flex-start;
          color: #545454;
        }
      }

      .asset-cards {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        max-width: 755px;
        gap: 8px;

        @media (max-width: 850px) {
          max-width: 480px;
        }
      }

      .progress-bar-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 20px;
        border: 1px solid #e5e5e5;
        border-radius: 8px;
        padding: 20px 22px;
        width: 420px;
        transition: width ease-in-out 0.2s;

        @media (max-width: 850px) {
          width: 62vw;
        }

        .total {
          font-size: 16px;
          color: #000000;
        }

        .progress-bars {
          display: flex;
          flex-direction: column;
          gap: 5px;
          width: 100%;

          .el-progress__text {
            font-size: 14px !important;
          }

          .progress-bar {
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
              font-size: 14px;
              font-weight: bold;
            }
          }

          .el-progress-bar {
            width: 180px;
            margin: 0;
            padding: 0;
            width: 100%;
          }

          .el-progress {
            display: grid;
            grid-template-columns: 85% 15%;
            width: 304px;

            @media (max-width: 540px) {
              grid-template-columns: 80% 20%;
              width: 70%;
            }
          }
        }
      }
    }
    .asset-analytics-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      background-color: #ffffff;
      padding: 28px 30px 30px;
      gap: 22px;
      border: 1px solid #e5e5e5;
      border-radius: 8px;
      width: 43%;
      height: min-content;

      @media (max-width: 1700px) {
        width: 755px;
      }

      @media (max-width: 850px) {
        width: 75vw;
        max-height: auto;
      }

      .text-container {
        display: flex;
        flex-direction: column;
        gap: 2px;
        width: 100%;

        .title {
          font-size: 18px;
          font-weight: bold;
          text-align: left;
          align-self: flex-start;
          color: #000000;
        }

        .subtitle {
          font-size: 14px;
          font-weight: bold;
          text-align: left;
          align-self: flex-start;
          color: #545454;
        }
      }

      .date-picker {
        padding-top: 15px;
        align-self: center;

        .el-date-editor--daterange.el-input__inner {
          border-radius: 8px;
          @media (max-width: $sm) {
            width: 100%;
          }
        }
      }

      .chart-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 95%;

        canvas {
          width: 700px !important;
          height: 200px !important;

          @media (max-width: 850px) {
            width: 75vw !important;
          }
        }
      }

      .analytics-data {
        display: flex;
        justify-content: center;
        align-self: flex-start;
        flex-wrap: wrap;
        gap: 8px;
        font-size: 14px;
        max-width: 1000px;

        .data-container {
          display: flex;
          align-items: center;
          gap: 15px;
          width: 230px;
          padding: 10px;
          border: 1px solid #e5e5e5;
          border-radius: 8px;

          .data-icon {
            display: flex;
            padding: 10px;
            background-color: #ebeef5;
            border-radius: 8px;
          }

          .data {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;

            .data-value {
              font-weight: bold;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $sm) {
  .el-picker-panel__body {
    min-width: 200px !important;
    display: flex !important;
    flex-direction: column !important;
  }

  .el-picker-panel {
    width: 450px !important;
  }

  .el-date-range-picker__content .el-date-range-picker__header div {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .el-date-range-picker__content.is-left,
  .el-date-range-picker__content.is-right {
    width: 100% !important;
  }
}
@media (max-width: 530px) {
  .el-picker-panel {
    left: 10px !important;
    width: 95% !important;
  }
}
</style>
