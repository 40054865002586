<template>
  <div class="grid-container">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      :class="['grid-icon', isButtonActive ? 'active-color' : 'inactive-color']"
    >
      <rect width="7" height="7" x="3" y="3" rx="1" />
      <rect width="7" height="7" x="14" y="3" rx="1" />
      <rect width="7" height="7" x="14" y="14" rx="1" />
      <rect width="7" height="7" x="3" y="14" rx="1" />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    isButtonActive: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="scss">
.grid-icon {
  width: 16px;
  height: 16px;
}

.grid-container .active-color rect {
  stroke: $--color-primary;
}

.grid-container .inactive-color rect {
  stroke: #a5b3bc;
}

.grid-container:hover .grid-icon rect {
  stroke: $--color-primary;
}
</style>
