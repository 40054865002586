import actions from "./user.store.actions";
import mutations from "./user.store.mutations";
import state from "./user.store.state";
import getters from "./user.store.getters";
import { UserState } from "../user.types";
import { RootState } from "@/store/store.types";
import { Module } from "vuex";

export const userStore: Module<UserState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
