<template>
  <div
    v-if="anchor"
    :class="['stats-details', showSidebar ? 'stats-details--sidebar-open' : '']"
  >
    <template>
      <div class="stats-details__content">
        <dashboard
          :show-anchors="false"
          :interval-update="false"
          :enumerate-tags="enumerateTags"
          :route-selected="routeSelected"
          :stayed-trip="stayedTrip"
          @toggle-sidebar="value => (showSidebar = value)"
        />
        <stats-charts
          v-if="dateSelected"
          :routeSelected="routeSelected"
          :redirectedInfo="redirectedInfo"
          :routeId="routeId"
          :anchorId="anchor.data.anchorId"
          @done="submit = false"
          @stayed-trip="value => (stayedTrip = value)"
        />
      </div>

      <div class="stats-sidebar" v-loading.lock="routesQuery.loading">
        <div>
          ROUTES
        </div>
        <el-table
          :data="routes.data"
          border
          style="width: 100%"
          @row-click="onRouteClicked"
          :row-class-name="tableRowClassName"
        >
          <el-table-column prop="id" label="Lap ID" :resizable="false">
          </el-table-column>
          <el-table-column label="Tags" :resizable="false">
            <template slot-scope="scope">
              {{ scope.row.tags.length }}
            </template>
          </el-table-column>
          <el-table-column label="Start date" :resizable="false">
            <template slot-scope="scope">
              {{ moment.unix(scope.row.startDate).format("DD/MM/YYYY") }}
            </template>
          </el-table-column>
          <el-table-column label="End date" :resizable="false">
            <template slot-scope="scope">
              <template v-if="scope.row.endDate">
                {{ moment.unix(scope.row.endDate).format("DD/MM/YYYY") }}
              </template>
              <template v-else>
                Ongoing
              </template>
            </template>
          </el-table-column>
        </el-table>
        <el-button
          v-if="routes.next"
          type="primary"
          tabindex="-1"
          slot="append"
          icon="el-icon-plus"
          :loading="routes.loading"
          @click="loadRoutes(false, true)"
          >Load More</el-button
        >
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import * as actionTypes from "@/store/action-types";
import * as routerTypes from "@/router/router.types";
import Dashboard from "@/views/Dashboard";
import StatsCharts from "@/views/stats/components/stats-charts";
import moment from "moment";

export default {
  components: {
    Dashboard,
    StatsCharts
  },

  props: {
    duration: {
      default: null
    },
    startDate: {
      default: null
    },
    routeId: {
      default: null
    }
  },

  computed: {
    ...mapState("anchors", {
      routes: "routes",
      anchor: "anchor"
    }),

    ...mapState("user", {
      resources: "resources"
    }),

    ...mapState("clients", {
      selectedAnchor: "selectedAnchor"
    })
  },

  data() {
    return {
      moment,
      showSidebar: false,
      stayedTrip: [],
      dateSelected: [],
      routeSelected: null,
      pickerOptions: {
        disabledDate: null,
        shortcuts: null
      },
      routesQuery: {
        page: 0,
        pageSize: 10,
        loading: false
      },
      enumerateTags: [],
      startHour: null,
      endHour: null,
      submit: false,
      closedMenu: null,
      redirectedInfo: null
    };
  },

  watch: {
    "$route.params.routeId"(newValue, oldValue) {
      if (newValue === null && !oldValue) {
        this.loadRoutes(true);
      }
    },
    showSidebar: {
      handler(newValue) {
        this.closedMenu = !newValue;
      }
    }
  },

  methods: {
    ...mapActions("anchors", {
      getAnchorRoutes: actionTypes.ANCHOR_GET_ROUTES
    }),

    async loadRoutes(reset = false, loadMore = false) {
      if (reset) this.routesQuery.page = 0;

      const anchorId = this.selectedAnchor.anchorId;
      const query = {
        page: this.routesQuery.page++
      };

      query["page_size"] = this.routesQuery.pageSize;

      if (reset) this.routesQuery.loading = true;
      await this.getAnchorRoutes({ anchorId, query });

      if (this.routes.data.length && !loadMore) {
        let routeToStart = null;
        if (this.$route.params.routeId) {
          routeToStart = this.routes.data.find(
            _route => _route.id === Number(this.$route.params.routeId)
          );

          if (!routeToStart && this.routes.next) {
            return this.loadRoutes();
          }
        }

        if (!routeToStart || !this.$route.params.routeId) {
          routeToStart = this.routes.data.reduce(
            (previous, current) => {
              return current.createdDate > previous.createdDate
                ? current
                : previous;
            },
            { createdDate: 0 }
          );
        }

        this.onRouteClicked(routeToStart);
      }

      this.routesQuery.loading = false;
    },

    onRouteClicked(route) {
      if (
        !this.$route.params.routeId ||
        (this.$route.params.routeId &&
          route.id !== Number(this.$route.params.routeId))
      ) {
        this.$router
          .replace({
            name: routerTypes.ROUTE_ANALYTICS_POSITIONS_DETAILS,
            params: {
              anchorId: this.$route.params.anchorId,
              routeId: route.id
            }
          })
          .catch(_ => {
            // Ignore
          });
      }
      this.routeSelected = route;
      this.enumerateTags = this.routeSelected.tags;
      this.getTagIdsFromUserTags();
    },

    tableRowClassName({ row, rowIndex }) {
      if (row.id === Number(this.$route.params.routeId)) {
        return "row-active";
      }
    },

    getTagIdsFromUserTags() {
      this.enumerateTags?.forEach(tag => {
        const userTag = this.resources.data.tags.find(
          _tag => tag.tagPosition.tagId === _tag.id
        );

        if (userTag) {
          tag.tagId = userTag.tagId;
          tag.lastPosition = tag.tagPosition;
          //tag = userTag;
        }
      });
    }
  },

  created() {
    if (this.duration !== null) {
      this.redirectedInfo = {
        duration: this.duration,
        startDate: this.startDate
      };
    }
    this.loadRoutes(true);
  }
};
</script>

<style lang="scss">
.stats-details {
  &__empty-state {
    max-width: 600px;
    margin: 48px auto;
    padding: 24px;
    background: #ffffff;
    border: 1px solid #dcdfe6;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
    display: flex;
  }

  &--sidebar-open {
    > .stats-sidebar {
      display: block !important;
      position: fixed;
      top: 104px;
      right: 0;
      bottom: 0;
      padding-top: 60px;
      padding-bottom: 20px;
      background: $--border-color-extra-light;
      width: 45vh;
      max-height: 100vh;
      overflow-y: auto;
      overflow-x: hidden;
      border-left: 1px solid #dcdfe6;

      &::after {
        content: " ";
        position: absolute;
        top: 0;
        width: 480px;
        height: 60px;
        background: $--border-color-extra-light;
      }

      .el-date-editor {
        margin-top: 12px;
      }

      .el-table {
        margin-bottom: 26px;
      }

      .el-button {
        margin-bottom: 20px;
      }
    }

    .stats-details {
      &__content {
        grid-template-columns: 35% 40% !important;
      }
    }

    .stats-charts {
      width: 100% !important;
    }

    .dashboard__map-wrapper {
      width: 65vh !important;
    }
  }

  .dashboard__map-wrapper,
  .dashboard__slot {
    height: 100%;
    padding-top: 0;
    border-bottom: 1px solid #dcdfe6;
  }

  .dashboard__map.leaflet-container {
    height: 100%;
    border-bottom: none;
  }

  &__content {
    display: grid !important;
    grid-template-columns: 45% 55%;

    .dashboard__map-wrapper {
      position: fixed !important;
      width: 45%;
      height: 100%;
      border-top: none;
      border-left: 1px solid #dcdfe6;
    }

    .dashboard__slot {
      height: 0;
      border-bottom: none;
    }

    .stats-charts {
      margin-top: 5%;
      margin-left: 2%;
      width: 90%;
    }
  }

  .stats-sidebar {
    display: none;
  }

  .toggle-dashboard-slot {
    position: fixed;
    top: 120px;
    right: 24px;
  }

  .el-table__row {
    cursor: pointer;

    &.row-active > td {
      background-color: #f5f7fa;
    }
  }
}
</style>
