<template>
  <div class="routes-editor">
    <div class="route-selector-create">
      <div class="edit-route-selector">
        <h3>Select Route To Edit</h3>
        <el-select
          v-model="selectedRoute"
          placeholder=""
          clearable
          @change="handleSelectedRoute"
          @clear="handleClearRoute"
        >
          <el-option
            v-for="route in routeList"
            :key="route.id"
            :label="route.label ?? route.id"
            :value="route.id"
          >
            <span style="float: left"> {{ route.label }}</span>
            <span style="float: left; padding-left: 5px">
              <i
                :class="
                  route.constraintTag
                    ? 'el-icon-location-outline'
                    : route.constraintShiftStart != 0 ||
                      route.constraintShiftEnd != 86399
                    ? 'el-icon-time'
                    : ''
                "
              >
              </i>
            </span>
            <span
              style="float: right; font-size: 12px"
              v-if="
                route.constraintShiftStart != 0 ||
                  route.constraintShiftEnd != 86399
              "
            >
              {{ formatTime(route.constraintShiftStart) }} -
              {{ formatTime(route.constraintShiftEnd) }}
            </span>
            <span
              style="float: right; font-size: 12px"
              v-else-if="route.constraintTag"
            >
              {{ route.constraintTag }}
            </span>
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="routes-editor__table" v-loading.lock="loading">
      <div class="routes-editor__table__form">
        <h3>Route Settings</h3>
        <el-form
          label-position="left"
          class="route-form"
          :rules="rules"
          ref="routeForm"
          :model="routeForm"
        >
          <el-form-item
            label="Route Label"
            class="route-label form-flex"
            prop="routeLabel"
          >
            <el-input
              v-model="routeForm.routeLabel"
              class="route-label-input"
              :disabled="!isAdmin || selectedRoute === ''"
              clearable
              @input="setIsChanging(true)"
            ></el-input>
          </el-form-item>
          <el-form-item label="Target Route Duration (HH:mm)" class="form-flex">
            <el-time-picker
              v-model="goalTime"
              :disabled="!isAdmin || selectedRoute === ''"
              style="width: 130px"
              placeholder="Goal Time"
              :picker-options="{
                format: 'HH:mm'
              }"
              size="small"
              format="HH:mm"
              value-format="HH:mm"
              @change="setIsChanging(true)"
            >
            </el-time-picker>
          </el-form-item>
          <el-form-item label="Route Timeout (HH:mm)" class="form-flex">
            <el-time-picker
              v-model="routeTimeout"
              :disabled="!isAdmin || selectedRoute === ''"
              style="width: 130px"
              placeholder="Route Timeout"
              :picker-options="{
                format: 'HH:mm'
              }"
              size="small"
              format="HH:mm"
              value-format="HH:mm"
              @change="setIsChanging(true)"
            >
            </el-time-picker>
          </el-form-item>
          <el-form-item label="Min Route Duration (HH:mm)" class="form-flex">
            <el-time-picker
              v-model="routeMinDuration"
              :disabled="!isAdmin || selectedRoute === ''"
              style="width: 130px"
              placeholder="Route Duration"
              :picker-options="{
                format: 'HH:mm'
              }"
              size="small"
              format="HH:mm"
              value-format="HH:mm"
              @change="setIsChanging(true)"
            >
            </el-time-picker>
          </el-form-item>
          <el-form-item label="Route Position Tolerance" class="form-flex">
            <el-checkbox
              border
              :disabled="!isAdmin || selectedRoute === ''"
              v-model="skipsEnabledCheckbox"
              size="medium"
              type="primary"
              style="width: 130px"
              @change="setIsChanging(true)"
            >
              Enabled
            </el-checkbox>
          </el-form-item>
          <el-form-item label="Start Route On Beacon" class="form-flex">
            <el-switch
              class="switch"
              :disabled="!isAdmin || selectedRoute === ''"
              v-model="startOnEnter"
              active-color="#1F4258"
              inactive-color="#1F4258"
              active-text="Arrival"
              inactive-text="Departure"
              @change="startOnEnterChange()"
            >
            </el-switch>
          </el-form-item>
          <el-form-item label="Finish Route On Beacon" class="form-flex">
            <el-switch
              class="switch"
              v-model="finishOnEnter"
              active-color="#1F4258"
              inactive-color="#1F4258"
              active-text="Arrival"
              :disabled="disableSwitch || !isAdmin || selectedRoute === ''"
              inactive-text="Departure"
              @change="setIsChanging(true)"
            >
            </el-switch>
          </el-form-item>
        </el-form>
      </div>
      <div class="route-constraints">
        <div class="route-constraints__header">
          <h3>Constraints</h3>
          <el-tooltip
            placement="top"
            content="Route constraints let you set specific conditions for your route, such as decision beacon or time-based work shift limits."
          >
            <i class="el-icon-info"></i>
          </el-tooltip>
        </div>
        <el-form
          label-position="left"
          class="route-form"
          :rules="rules"
          ref="routeForm"
          :model="routeForm"
        >
          <el-form-item class="form-flex flex-wrap">
            <template slot="label">
              <!-- <el-tooltip placement="left" content="Set a decision beacon as a reference point for the current route in progress.">                 -->
              <el-tooltip placement="left" content="Coming soon">
                <span
                  >Decision Beacon <i class="el-icon-location-outline"></i
                ></span>
              </el-tooltip>
              <!-- </el-tooltip> -->
            </template>
            <el-select
              v-model="decisionBeacon"
              placeholder="Select beacon"
              :disabled="true"
              filterable
              size="small"
              clearable
            >
              <el-option
                v-for="tag in resources.data.tags.filter(
                  tag => tag.active && tag.lastPosition != null
                )"
                :key="`tag.label${tag.tagId}`"
                :label="
                  `(${tag.tagId
                    .toString(16)
                    .toUpperCase()
                    .match(/.{1,2}/g)
                    .join('')}) ${tag.label}`
                "
                :value="tag.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="form-flex flex-wrap">
            <template slot="label">
              <el-tooltip
                placement="left"
                content="Set a work shift interval for starting this route."
              >
                <span>Work Shift <i class="el-icon-time"></i></span>
              </el-tooltip>
            </template>
            <el-time-picker
              is-range
              size="small"
              :disabled="decisionBeacon != ''"
              v-model="routeShift"
              range-separator="To"
              start-placeholder="Start time"
              end-placeholder="End time"
              format="HH:mm"
              value-format="HH:mm"
              @change="setIsChanging(true)"
            >
            </el-time-picker>
          </el-form-item>
        </el-form>
      </div>
      <div class="routes-editor__table__edit-route">
        <h3 class="beacon-list-title">Route Beacons List</h3>
        <el-table :data="tableData" :row-class-name="getTableRowClassName">
          <el-table-column :resizable="false" label="Order" width="100">
            <template slot-scope="scope">
              {{ scope.row.ord }}
            </template>
          </el-table-column>
          <el-table-column :resizable="false" label="ID">
            <template slot-scope="scope">
              <template v-if="scope.row.tagId">
                {{
                  scope.row.tagId
                    .toString(16)
                    .toUpperCase()
                    .match(/.{1,2}/g)
                    .join("")
                }}
              </template>
            </template>
          </el-table-column>
          <el-table-column :resizable="false" label="Label" prop="label">
            <template slot-scope="scope">
              <template v-if="scope.row.label">
                {{ scope.row.label }}
              </template>
            </template>
          </el-table-column>
        </el-table>
        <div class="routes__table__settings-buttons-bottom">
          <div class="download-container">
            <div class="route-information">
              <div>
                <el-button
                  type="success"
                  size="mini"
                  @click="downloadCSV(false)"
                  >Download Beacons List</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-buttons">
      <el-button v-if="isAdmin" size="small" @click="setDialogDiscard(true)">
        Cancel
      </el-button>
      <el-button
        v-if="isAdmin"
        type="primary"
        size="small"
        @click="makeSureSave()"
      >
        Save
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import * as actionTypes from "@/store/action-types";
import { cloneDeep } from "lodash";
import RoutesApi from "@/modules/routes/api/routes.api";
import moment from "moment";

export default {
  name: "RoutesEditor",

  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    routeList: {
      type: Array,
      default: () => []
    },
    routeToEdit: {
      type: Number
    },
    activeTabName: {
      type: String,
      default: ""
    },
    editingRoute: {
      type: Boolean,
      default: false
    },
    discardEditRoute: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      anchor: null,
      auxTableData: [],
      decisionBeacon: "",
      finishOnEnter: true,
      foundRoute: [],
      loading: false,
      goalTime: "",
      routeForm: {
        routeLabel: ""
      },
      routeMinDuration: "",
      routeSchedule: "",
      routeTimeout: "",
      routeShift: [
        moment.utc(0).format("HH:mm"),
        moment.utc(86399 * 1000).format("HH:mm")
      ],
      rules: {
        routeLabel: [
          {
            required: true,
            message: "Please input route label",
            trigger: "blur"
          },
          {
            min: 3,
            max: 50,
            message: "Length should be 3 to 50",
            trigger: "blur"
          }
        ]
      },

      showNumberedOnly: false,
      skipsEnabledCheckbox: true,
      startOnEnter: true
    };
  },

  computed: {
    ...mapState("auth", {
      user: "user"
    }),
    ...mapState("user", {
      isChangingInformation: "isChangingInformation",
      discardChanges: "discardChanges",
      activeRoute: "activeRoute",
      resources: "resources"
    }),
    disableSwitch() {
      if (this.tableData.length > 0)
        if (
          this.tableData[0].tagId ===
          this.tableData[this.tableData.length - 1].tagId
        )
          if (this.startOnEnter) return true;
      return false;
    },

    ...mapGetters("auth", ["isAdmin"])
  },

  watch: {
    editingRoute: {
      immediate: true,
      handler(newValue) {
        if (newValue === true) {
          this.edit();
        }
      }
    },

    discardChanges(newValue) {
      if (newValue === true) {
        this.setDialogDiscard(true);
      }
    },

    tableData: {
      deep: true,
      handler(newValue) {
        if (newValue.length > 0)
          if (
            newValue[0].id === newValue[newValue.length - 1].id &&
            this.startOnEnter
          )
            this.finishOnEnter = true;
      }
    },

    discardEditRoute: {
      handler(newValue) {
        if (newValue === true) {
          this.handleSelectedRoute();
          this.$emit("update-discard-edit-route");
        }
      }
    },

    routeToEdit: {
      handler(newValue) {
        if (newValue != "") {
          this.selectedRoute = newValue;
        }
      }
    },

    activeRoute: {
      handler(newValue) {
        if (newValue) {
          this.selectedRoute = newValue.id;
        } else if (!newValue) {
          this.handleClearRoute();
        }
      }
    },

    selectedRoute: {
      handler(newValue) {
        if (newValue != (this.activeRoute?.id ?? null)) {
          const route = this.routeList.find(route => route.id == newValue);
          this.updateActiveRoute(route ?? null);
        }
      }
    },

    activeTabName: {
      handler(newValue) {
        if (newValue == "editor" && this.selectedRoute) {
          this.handleSelectedRoute();
        }
      }
    }
  },

  methods: {
    ...mapActions("user", {
      setIsChanging: actionTypes.USER_SET_IS_CHANGING,
      setDiscard: actionTypes.USER_SET_DISCARD,
      setPath: actionTypes.USER_SET_PATH,
      updateActiveRoute: actionTypes.USER_UPDATE_ACTIVE_ROUTE
    }),

    startOnEnterChange() {
      if (this.tableData.length > 0)
        if (
          this.tableData[0].tagId ===
          this.tableData[this.tableData.length - 1].tagId
        )
          if (this.startOnEnter) this.finishOnEnter = true;
      this.setIsChanging(true);
    },

    getTableRowClassName({ row, rowIndex }) {
      if (row.color) return "table-data__yellow";
      else return "table-data";
    },

    downloadCSV() {
      const newArray = [];
      this.tableData.forEach(element => {
        newArray.push([
          element.ord,
          "0x" +
            element.tagId
              .toString(16)
              .toUpperCase()
              .match(/.{1,2}/g)
              .join(""),
          element.label
        ]);
      });
      let csvContent = "Ord, Tag ID, Label \n";
      newArray.forEach(function(infoArray, index) {
        const dataString = infoArray.join(",");
        csvContent += dataString + "\n";
      });
      const anchor = document.createElement("a");
      anchor.href =
        "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent);
      anchor.target = "_blank";
      anchor.download = "beacons-list.csv";
      anchor.click();
    },

    makeSureSave() {
      this.$refs.routeForm.validate(valid => {
        if (!valid) {
          this.$notify.error({
            title: "Error",
            message: "Please fill in all required fields before saving."
          });
          this.setDialogSave(false);
          return false;
        } else {
          this.setDialogSave(true);
          return true;
        }
      });
    },

    async edit() {
      // Save changes
      let payload = {};
      const newRouteTimeout = moment.duration(this.routeTimeout).asSeconds();
      const newRouteMinDuration = moment
        .duration(this.newRouteMinDuration)
        .asSeconds();
      const newGoalTime = moment.duration(this.goalTime).asSeconds();
      let startTag = 1;
      let endTag = 1;
      let tags = [];

      if (this.tableData.length !== 0) {
        startTag = this.tableData[0].id;
        endTag = this.tableData[this.tableData.length - 1].id;
        tags = this.tableData.map((entry, index) => ({
          ord: index + 1,
          tagPositionId: entry.tagPositionId
        }));
      }

      let startSeconds = 0;
      let endSeconds = 86399;

      if (
        this.routeShift != null &&
        (this.routeShift[0] != "00:00" || this.routeShift[1] != "23:59")
      ) {
        startSeconds =
          moment(this.routeShift[0], "HH:mm").diff(
            moment().startOf("day"),
            "seconds"
          ) + 1;
        endSeconds =
          moment(this.routeShift[1], "HH:mm").diff(
            moment().startOf("day"),
            "seconds"
          ) + 59;
      }

      payload = {
        label: this.routeForm.routeLabel,
        startTag: startTag,
        endTag: endTag,
        tags: tags,
        skipsMismatches: this.skipsEnabledCheckbox,
        startOnEnter: this.startOnEnter,
        finishOnEnter: this.finishOnEnter,
        routeTimeout: newRouteTimeout,
        routeMinDuration: newRouteMinDuration,
        goalTime: newGoalTime,
        constraintTag: this.decisionBeacon != "" ? this.decisionBeacon : null,
        constraintShiftStart: startSeconds,
        constraintShiftEnd: endSeconds
      };

      this.auxTableData = cloneDeep(this.tableData);
      this.loading = true;

      try {
        const res = await RoutesApi.editSettings(this.selectedRoute, payload);
        if (res.data) {
          this.$notify({
            title: "Success",
            message: "Route Settings successfully updated",
            type: "success"
          });
          this.$emit("fetch-route-list");
          this.setIsChanging(false);
        }
      } catch (error) {
        this.$notify.error({
          title: "Error",
          message: "Something went wrong. Try again later."
        });
      } finally {
        this.loading = false;
        this.$emit("update-edit-route");
      }
    },

    handleSelectedRoute() {
      this.foundRoute = this.routeList.find(
        route => route.id === this.selectedRoute
      );

      if (this.foundRoute) {
        this.routeForm.routeLabel = this.foundRoute.label ?? "";
        this.goalTime = moment
          .utc(this.foundRoute.goalTime * 1000)
          .format("HH:mm");
        this.routeTimeout = moment
          .utc(this.foundRoute.routeTimeout * 1000)
          .format("HH:mm");
        this.routeMinDuration = moment
          .utc(this.foundRoute.routeMinDuration * 1000)
          .format("HH:mm");
        (this.routeShift = [
          moment
            .utc(this.foundRoute.constraintShiftStart * 1000)
            .format("HH:mm"),
          moment.utc(this.foundRoute.constraintShiftEnd * 1000).format("HH:mm")
        ]),
          (this.skipsEnabledCheckbox =
            this.foundRoute.routeTolerance != 0 ? true : false);

        const newTableData = [];

        this.foundRoute.tags.forEach(tag => {
          const tagInfo = this.resources.data.tags.find(
            t => t.lastPosition.id == tag.lastPosition.id
          );
          if (tagInfo) {
            newTableData.push({
              id: tagInfo.id,
              tagId: tagInfo.tagId,
              label: tagInfo.label,
              tagPositionId: tagInfo.lastPosition.id,
              ord: tag.ord
            });
          }
        });

        this.$emit("change-table-data", newTableData);

        this.startOnEnter = this.foundRoute.startOnEnter;
        this.finishOnEnter = this.foundRoute.finishOnEnter;
      }
    },

    handleClearRoute() {
      this.selectedRoute = "";
      this.routeForm.routeLabel = "";
      this.goalTime = "";
      this.routeTimeout = "";
      this.routeMinDuration = "";
      this.foundRoute = [];
      this.$emit("change-table-data", []);
      this.$forceUpdate;
    },

    setDialogSave(booleanValue) {
      this.$emit("set-dialog-save", booleanValue);
    },

    setDialogDiscard(booleanValue) {
      if (this.isChangingInformation) {
        this.$emit("set-dialog-discard", booleanValue);
      }
    },

    formatTime(targetTime) {
      if (targetTime != null) {
        return moment.utc(targetTime * 1000).format("HH:mm");
      } else return "--";
    }
  },
  created() {
    this.selectedRoute = this.activeRoute?.id ?? null;

    if (this.selectedRoute) {
      this.handleSelectedRoute();
    }
  }
};
</script>

<style lang="scss">
.routes-editor {
  .el-form-item__error {
    position: absolute;
  }

  .route-selector-create {
    display: flex;
    justify-content: center;
    margin: 15px 40px 0;
    align-items: flex-end;
    color: grey;
    font-weight: bold;
    border-bottom: 1px solid grey;
    padding-bottom: 20px;

    @media (max-width: 636px) {
      align-items: center;
      flex-direction: column;
      gap: 10px;
      margin: 15px 20px 0px;
    }

    .edit-route-selector {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .option-indicator {
      padding-bottom: 9px;
    }

    h3 {
      color: $--color-primary;
      font-size: 18px;
    }

    .el-select {
      width: 300px;
      border-radius: 5px;

      @media (max-width: 600px) {
        width: 200px;
      }

      .el-input .el-input--suffix .el-input__suffix {
        top: 5px;
      }
    }
  }

  .option-indicator {
    padding-bottom: 9px;
  }

  h3 {
    color: $--color-primary;
    font-size: 18px;
  }

  .el-select {
    width: 300px;
    border-radius: 5px;

    @media (max-width: $xs) {
      width: 70vw;
    }

    .el-input .el-input--suffix .el-input__suffix {
      top: 5px;
    }
  }

  .create-route-button {
    background-color: $--color-primary;
    color: #fff;
  }

  .route-suggestion {
    margin-top: 15px;
    border-top: 1px solid grey;
    padding-top: 15px;
  }

  .el-form-item {
    margin-bottom: 0px;

    &::before,
    &::after {
      display: none !important;
    }

    .el-input__inner {
      padding-right: 0 !important;
    }
  }

  .table-data {
    width: 100%;
    max-height: 40vh;
    overflow-y: scroll;

    &__yellow {
      background-color: rgba(255, 184, 51, 0.7);
    }
    &__red {
      border: 1px solid red;
    }
  }
  .red-table {
    border: 1px solid red;
  }

  .download-container {
    display: flex;
    justify-content: center;
  }

  .route-information {
    display: flex;
    justify-content: flex-end;
    width: 94%;
    margin-top: 15px;
  }

  @media (max-width: 1700px) {
    .route-information {
      display: flex;
      justify-content: flex-end;
      margin-left: 0px;
    }
  }

  .form-flex {
    display: flex;
    justify-content: space-between;

    .el-icon-location-outline {
      font-size: 15px;
    }

    @media (max-width: $xs) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .route-form {
    margin-top: 15px;
    margin-bottom: 15px;

    .route-label-input .el-input__inner {
      height: 32px !important;
      font-size: 12px;
    }

    .route-label-input {
      width: 300px;

      @media (max-width: $sm) {
        width: 200px;
      }
      @media (max-width: $xs) {
        width: 70vw;
      }
    }

    .route-label .el-form-item__label {
      width: max-content !important;
    }

    .el-form-item label.el-form-item__label {
      line-height: 40px;
      width: 210px;
    }
  }

  .target-route-duration {
    display: flex;
    justify-content: space-between;
  }

  .el-form-item__content {
    margin-left: 0px;
  }

  .skips-mismatches {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3%;

    &__input {
      width: 50%;
      margin-top: 2%;
    }
  }

  .switch {
    margin-top: 10px;
  }

  h4 {
    margin: 24px auto;
  }

  .route-constraints {
    padding-top: 15px;

    &__header {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;

      .el-icon-info {
        color: $--color-primary;
      }
    }
  }

  &__table {
    padding: 0 40px 15px;
    overflow-y: auto;
    max-height: calc(100vh - 430px);

    @media (max-width: 636px) {
      padding: 0 20px 5px;
    }

    @media (max-width: $md) {
      max-height: 100%;
    }

    &__form {
      padding-top: 15px;
      border-bottom: 1px solid gray;
    }

    &__edit-route {
      margin-top: 15px;
      border-top: 1px solid grey;
      padding-top: 15px;
    }

    &__settings {
      margin-top: 1vh;
      display: flex;
      justify-content: space-evenly;
    }

    .settings-label {
      display: flex;
      justify-content: left;
      align-items: center;
    }

    &__settings-buttons {
      display: flex;
      justify-content: right;
      margin-top: 15px;
      margin-right: 15px;
    }

    &__settings-buttons-bottom {
      display: grid;
      margin-top: 15px;
    }
  }

  .form-buttons {
    border-top: 1px solid grey;
    padding: 20px 0;
    margin: 0 40px;

    @media (max-width: 636px) {
      margin: 0 20px;
    }
  }

  .el-table {
    margin-top: 24px;

    .el-table__row td:first-child .cell {
      line-height: 28px;
    }
  }

  .el-table__body td {
    cursor: pointer;
  }
}
</style>
