<template>
  <div class="asset-history-table">
    <div class="pagination-wrapper">
      <div class="pagination-wrapper__current">
        {{ pageSize * (currentTablePage - 1) + 1 }} -
        {{
          pagedAssetList.length == 20
            ? pagedAssetList.length * currentTablePage
            : (currentTablePage - 1) * pageSize + pagedAssetList.length
        }}
        of {{ typeOfPagination != "" ? assets : assetList.length }}
      </div>

      <el-pagination
        class="pagination"
        background
        layout="prev, pager, next"
        :page-size="pageSize"
        @current-change="value => (currentTablePage = value)"
        :total="typeOfPagination != '' ? assets : assetList.length"
      >
      </el-pagination>

      <div class="download-assets" @click.stop="downloadAssets()">
        <span>
          Export
        </span>
        <span class="download-assets__icon">
          <i class="el-icon-download"></i>
        </span>
      </div>
    </div>

    <el-table
      :data="pagedAssetList"
      class="asset-list-table"
      style="width: 100%"
    >
      <el-table-column label="ASSET" min-width="90" sortable prop="tagId">
        <template slot-scope="scope">
          <template v-if="scope.row.label">
            {{ scope.row.label }}
          </template>
          <template v-if="scope.row.upperHex">
            ({{ scope.row.upperHex }})
          </template>
        </template>
      </el-table-column>

      <el-table-column prop="beaconId" label="LOCATION" min-width="90" sortable>
        <template slot-scope="scope">
          <template v-if="scope.row.beaconLabel">
            {{ scope.row.beaconLabel }}
          </template>
          <template v-if="scope.row.beaconId">
            ({{ scope.row.beaconId }})
          </template>
          <template v-else>
            <hr />
          </template>
        </template>
      </el-table-column>

      <el-table-column
        prop="entryTs"
        label="ENTRY TIME"
        min-width="100"
        sortable
      >
        <template slot-scope="scope">
          <template v-if="scope.row.entryTs">
            {{ getTimeStampTransformed(scope.row.entryTs) }}
          </template>
          <template v-else>
            <hr />
          </template>
        </template>
      </el-table-column>

      <el-table-column
        prop="lastUpdate"
        label="LAST UPDATE"
        min-width="110"
        sortable
      >
        <template slot-scope="scope">
          <template v-if="scope.row.lastUpdate">
            {{ getTimeStampTransformed(scope.row.lastUpdate) }}
          </template>
          <template v-else>
            <hr />
          </template>
        </template>
      </el-table-column>

      <el-table-column prop="battery" label="BATT" width="80" sortable>
        <template slot-scope="scope">
          <template
            v-if="
              scope.row.battery == 1 ||
                scope.row.battery == 2 ||
                scope.row.battery == 3
            "
          >
            <battery-icon :battery="scope.row.battery" />
          </template>
          <template v-else-if="scope.row.battery == 0">
            <img src="@/assets/battery_dead.svg" alt="no battery" />
          </template>
          <template v-else>
            <hr />
          </template>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapState } from "vuex";
import batteryIcon from "@/components/battery-icon.vue";

export default {
  name: "assetList",
  components: {
    batteryIcon
  },
  props: {
    assetList: {
      type: Array,
      default: () => []
    },
    typeOfPagination: {
      type: String,
      default: ""
    },
    group: {
      default: () => null
    },
    assets: {
      default: () => null
    },
    hasGpsAnchor: {
      default: () => true
    }
  },

  watch: {
    currentTablePage: {
      handler(newValue) {
        switch (this.typeOfPagination) {
          case "group":
            this.$emit("current-table-page", {
              group: this.group,
              page: newValue - 1
            });
            break;
          case "overOneDay":
            this.$emit("current-table-page", {
              recent: false,
              page: newValue - 1
            });
            break;
          case "underOneDay":
            this.$emit("current-table-page", {
              recent: true,
              page: newValue - 1
            });
            break;
          case "travelling":
            this.$emit("current-table-page", newValue - 1);
            break;
          default:
            break;
        }
      }
    },
    assetList: {
      handler(newValue) {
        newValue.forEach(asset => {
          this.resources.data.tags.forEach(tag => {
            if (tag.id == asset.beaconId) {
              asset.beaconId = this.toHex(tag.tagId);
              asset.beaconLabel = tag.label;
            }
          });
        });
      }
    }
  },

  computed: {
    ...mapState("clients", {
      clientData: "data"
    }),
    ...mapState("user", {
      resources: "resources"
    }),
    pagedAssetList() {
      return this.typeOfPagination != ""
        ? this.assetList
        : this.assetList.slice(
            this.pageSize * this.currentTablePage - this.pageSize,
            this.pageSize * this.currentTablePage
          );
    }
  },

  data() {
    return {
      searchTagsOrder: {
        label: true,
        id: true,
        anchorLabel: true,
        lastSeenTs: true,
        battery: true,
        group: true,
        tagType: true
      },
      currentTablePage: 1,
      pageSize: 20,
      pagesRequested: [0]
    };
  },

  methods: {
    toHex(str, type) {
      if (type === "timestamp") return this.getTimeStampTransformed(str);
      else {
        return str
          .toString(16)
          .toUpperCase()
          .match(/.{1,2}/g)
          .join(type === "anchor" ? ":" : "");
      }
    },

    async downloadAssets() {
      const newArray = [];
      this.assetList.forEach(element => {
        newArray.push([
          element.label,
          element.upperHex,
          this.toHex(element.beaconId),
          element.beaconLabel,
          element.battery,
          element.entryTs,
          element.lastUpdate
        ]);
      }, this);
      let csvContent =
        "Label, Asset ID, Beacon ID, Beacon Label, Battery, Entered At, Last Seen \n";
      newArray.forEach(function(infoArray, index) {
        const dataString = infoArray.join(",");
        csvContent += dataString + "\n";
      });
      const anchor = document.createElement("a");
      anchor.href =
        "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent);
      anchor.target = "_blank";
      anchor.download = "asset-list.csv";
      anchor.click();
    },

    getTimeStampTransformed(timestamp) {
      if (timestamp)
        return moment
          .tz(moment.unix(timestamp), this.clientData.timezone)
          .format("MMM DD YYYY, HH:mm:ss");
      return "N/A";
    }
  }
};
</script>

<style lang="scss">
.pagination-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 500px) {
    flex-direction: column;
    gap: 10px;
  }

  &__current {
    height: 32px;
    display: flex;
    align-items: center;
  }

  .download-assets {
    position: absolute;
    right: 1%;
    display: flex;
    align-items: center;

    @media (max-width: 500px) {
      position: relative;
    }

    &:hover {
      cursor: pointer;
    }

    &__icon {
      margin-left: 5px;
    }
  }
}

.asset-history-table {
  .el-table {
    max-height: 700px;
    overflow-y: scroll;

    @media (max-width: 500px) {
      max-height: 60vh;
    }
  }
}

.cell {
  .el-button--mini {
    padding: 5px;
  }
}

.asset-list-table {
  margin-top: 5px;
}
</style>
