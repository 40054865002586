import actions from "./auth.store.actions";
import mutations from "./auth.store.mutations";
import state from "./auth.store.state";
import getters from "./auth.store.getters";
import { AuthState } from "../auth.types";
import { RootState } from "@/store/store.types";
import { Module } from "vuex";

export const authStore: Module<AuthState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
