var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"route-card",on:{"click":_vm.onRouteClick}},[_c('el-card',{class:[
      _vm.routesLayout === 'lines' ? 'lines-layout' : 'grid-layout',
      _vm.isActiveRoute ? 'selected-background' : 'not-selected-background'
    ]},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"head"},[_c('span',{staticClass:"route-label"},[(_vm.showShiftConflict)?_c('span',{staticClass:"conflict-marker"},[_vm._v("* ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.route?.label ?? _vm.route?.id)+" ")]),_c('div',{staticClass:"buttons"},[_c('el-tooltip',{attrs:{"content":"Lap Analytics","placement":"top","hide-after":2000}},[_c('el-button',{on:{"click":_vm.showConfirmationDialog}},[_c('img',{attrs:{"src":require("@/assets/bar-chart.svg"),"alt":"bar-chart"}})])],1),_c('el-tooltip',{attrs:{"content":"Edit Route","placement":"top","hide-after":2000}},[_c('el-button',{on:{"click":function($event){return _vm.updateTabName('editor')}}},[_c('i',{staticClass:"el-icon-edit"})])],1),_c('el-tooltip',{attrs:{"content":"Delete Route","placement":"top","hide-after":2000}},[_c('el-button',{on:{"click":function($event){return _vm.deleteRoute()}}},[_c('img',{attrs:{"src":require("@/assets/trash.svg"),"alt":"trash"}})])],1)],1)]),(
            _vm.route.constraintShiftStart != 0 ||
              _vm.route.constraintShiftEnd != 86399
          )?_c('div',{staticClass:"route-time"},[_c('span',[_c('b',[_vm._v("Starts at: ")]),_vm._v(_vm._s(_vm.routeStartShift))]),_c('span',[_c('b',[_vm._v("Ends at: ")]),_vm._v(_vm._s(_vm.routeEndShift))])]):(_vm.route.constraintTag)?_c('div',{staticClass:"decision-beacon"},[_c('span',[_c('b',[_vm._v("Decision beacon: ")]),_vm._v(_vm._s(_vm.route.constraintTag))])]):_vm._e()])]),_c('div',{staticClass:"anchor-selector"},[_c('el-select',{attrs:{"placeholder":"Select Gateways","multiple":"","filterable":"","default-first-option":"","disabled":_vm.isArchivedCards,"collapse-tags":_vm.routesLayout === 'grid'},on:{"change":_vm.handleRouteOverlap,"remove-tag":_vm.handleRouteOverlap},model:{value:(_vm.selectedAnchorIds),callback:function ($$v) {_vm.selectedAnchorIds=$$v},expression:"selectedAnchorIds"}},[(_vm.anchorList.length > 0)?_c('el-option',{key:'selectAll',style:({ fontWeight: 'bold' }),attrs:{"disabled":_vm.allOptionsSelected,"label":'SELECT ALL',"value":'selectAll'},nativeOn:{"click":function($event){return _vm.selectAllAnchors.apply(null, arguments)}}}):_vm._e(),_vm._l((_vm.anchorList),function(anchor){return _c('el-option',{key:anchor.id,attrs:{"label":anchor.label ?? anchor.id,"value":anchor.id}})})],2)],1),_c('el-tooltip',{attrs:{"disabled":!_vm.showShiftConflict,"placement":"top"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',[_vm._v(" Shift conflict: "),_c('br'),_c('br'),_vm._l((_vm.routesInConflict),function(route){return _c('div',{key:route.routeId + route.anchorId},[_vm._v(" "+_vm._s(route.anchorLabel)+" : "+_vm._s(route.routeLabel)+" ("+_vm._s(_vm.formatTime(route.startShift))+" - "+_vm._s(_vm.formatTime(route.endShift))+") ")])})],2)]},proxy:true}])},[_c('div',{staticClass:"submit-button"},[_c('el-button',{attrs:{"type":"primary","size":"mini","disabled":_vm.showShiftConflict},on:{"click":_vm.submitAnchors}},[_vm._v("Submit")])],1)]),_c('div',{class:[
        'card-footer',
        _vm.showShiftConflict ? 'justify-between' : 'justify-end'
      ]},[(_vm.showShiftConflict)?_c('div',{staticClass:"shift-conflict"},[_vm._v(" * Shift conflict ")]):_vm._e(),_c('div',{staticClass:"route-date"},[_c('el-tooltip',{attrs:{"content":"Creation Date","placement":"left","hide-after":2000}},[_c('span',[_vm._v(" "+_vm._s(_vm.moment.unix(_vm.route.startDate).format("MMM DD YYYY"))+" ")])])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }