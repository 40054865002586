import * as mutationTypes from "@/store/mutation-types";
import { ClientsState, Anchors, Users, Ssids, Anchor } from "../clients.types";
import { initialState } from "./clients.store.state";

export default {
  [mutationTypes.CLIENTS_SET_CLIENT](
    state: ClientsState,
    payload: ClientsState
  ) {
    state.data = payload.data;
    state.loading = payload.loading;
    state.error = payload.error;
  },

  [mutationTypes.CLIENTS_SET_ANCHORS](state: ClientsState, payload: Anchors) {
    state.anchors = payload;
  },

  [mutationTypes.CLIENTS_SET_SELECTED_ANCHOR](
    state: ClientsState,
    payload: Anchor
  ) {
    state.selectedAnchor = payload;
  },

  [mutationTypes.CLIENTS_SET_GLOBAL_VIEW](
    state: ClientsState,
    payload: boolean
  ) {
    state.globalView = payload;
  },

  [mutationTypes.CLIENTS_SET_USERS](state: ClientsState, payload: Users) {
    state.users = payload;
  },

  [mutationTypes.CLIENTS_SET_USER](state: ClientsState, payload: any) {
    state.users.data = [payload, ...state.users.data];
  },

  [mutationTypes.CLIENTS_SET_SSIDS](state: ClientsState, payload: Ssids) {
    state.ssids = payload;
    if (!state.ssids.loading)
      state.ssids.data = state.ssids.data.sort(function(a, b) {
        if (a.internal) return -1;
        else if (b.internal) return 1;
        else return 0;
      });
  },

  [mutationTypes.CLIENTS_SET_SSID](state: ClientsState, payload: any) {
    state.ssids.data = [...state.ssids.data, payload];
  },

  [mutationTypes.CLIENTS_SET_DELETE_SSID](state: ClientsState, id: number) {
    state.ssids = {
      ...state.ssids,
      data: state.ssids.data.filter(ssid => ssid.id !== id)
    };
  },

  [mutationTypes.CLIENTS_SET_DELETE_USER](state: ClientsState, id: number) {
    state.users = {
      ...state.users,
      data: state.users.data.filter(user => user.id !== id)
    };
  },

  [mutationTypes.CLIENTS_SET_UPDATED_ANCHOR](
    state: ClientsState,
    payload: Anchor
  ) {
    state.anchors = {
      ...state.anchors,
      data: state.anchors.data.map(anchor => {
        if (anchor.anchorId === payload.id) {
          anchor.label = payload.label;
          anchor.staticIp = payload.staticIp;
          anchor.gateway = payload.gateway;
          anchor.subnet = payload.subnet;
          anchor.dnsPrimary = payload.dnsPrimary;
          anchor.dnsSecondary = payload.dnsSecondary;
        }

        return anchor;
      })
    };
  },

  [mutationTypes.CLIENTS_SET_UPDATE_SSID](state: ClientsState, payload: any) {
    state.ssids = {
      ...state.ssids,
      data: state.ssids.data.map(ssid => {
        if (ssid.id === payload.id) {
          ssid.ssid = payload.ssid;
          ssid.hidden = payload.hidden;
        }

        return ssid;
      })
    };
  },

  reset(state: ClientsState) {
    const s = initialState();
    Object.keys(s).forEach(key => {
      (state as any)[key] = (s as any)[key];
    });
  }
};
