<template>
  <div class="unlinked-tags-card">
    <el-card>
      <div class="line"></div>
      <div class="content">
        <div class="content-head">
          <div class="title">
            <span class="label">Tags Unlinked to Anchors</span>
          </div>
          <span class="quantity"
            >Quantity: {{ unlinkedTags?.length ?? 0 }}</span
          >
        </div>
        <div v-if="!unlinkedTags.length" class="content-no-data">
          <span>No Data</span>
        </div>
        <div v-else class="content-table">
          <el-table
            :data="unlinkedTags"
            class="custom-table"
            :row-class-name="rowClassName"
          >
            <el-table-column prop="label" label="Tag" align="center">
              <template slot-scope="scope">
                <template v-if="scope.row.label">
                  {{ scope.row.label }}
                </template>
                <template v-else>
                  <span>{{ toHex(scope.row.tagId) }}</span>
                </template>
              </template>
            </el-table-column>
            <el-table-column prop="lastSeenTs" label="Last Seen" align="center">
              <template slot-scope="scope">
                <template>
                  {{
                    scope.row.lastSeenTs
                      ? moment
                          .tz(
                            moment.unix(scope.row.lastSeenTs),
                            clientData.timezone
                          )
                          .format("HH:mm")
                      : "-"
                  }}
                  <span
                    v-if="
                      scope.row.lastSeenTs &&
                        isDateBeforeToday(scope.row.lastSeenTs, clientData)
                    "
                  >
                    <el-tooltip placement="top">
                      <div slot="content">
                        {{
                          moment
                            .tz(
                              moment.unix(scope.row.lastSeenTs),
                              clientData.timezone
                            )
                            .format("MMMM Do YYYY, HH:mm:ss")
                        }}
                      </div>
                      <i class="el-icon-info"></i>
                    </el-tooltip>
                  </span>
                </template>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapState } from "vuex";
import { isDateBeforeToday } from "@/utils/utils";

export default {
  name: "unlinkedTagsCard",

  props: {
    unlinkedTags: {
      type: Array,
      default: () => []
    },
    tagToHighlight: {
      type: String,
      default: ""
    },
    toHex: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      moment,
      isDateBeforeToday
    };
  },

  computed: {
    ...mapState("clients", {
      clientData: "data"
    })
  },

  methods: {
    computeColor(status) {
      return `background-color: ${status == "ON" ? "green" : "red"};`;
    },

    // Checks if the tag matches the searched tag
    matchTagToHighlight(tag, searchedTag) {
      const hexTagId = this.toHex(tag.tagId);
      return (
        searchedTag === hexTagId ||
        searchedTag.slice(-3).includes(hexTagId.slice(-3)) ||
        tag.label.toUpperCase() === searchedTag
      );
    },

    // Highlights the row if tag if found on the table
    rowClassName({ row }) {
      if (this.tagToHighlight?.length >= 3)
        return this.matchTagToHighlight(row, this.tagToHighlight.toUpperCase())
          ? "highlight"
          : "";
      else return "";
    }
  }
};
</script>

<style lang="scss">
.unlinked-tags-card {
  height: max-content;
  position: sticky;
  top: 75px;

  @media (max-width: $sm) {
    position: relative;
    top: 0;
  }

  .el-card {
    width: 351px;
    height: 100%;
    border-radius: 10px;
    background-color: #fff;

    @media (max-width: 480px) {
      width: 330px;
    }

    @media (max-width: 1500px) {
      width: 270px;
    }

    .el-card__body {
      padding: 0;
    }

    .line {
      width: 100%;
      height: 16px;
      background-color: #a5b3bc;
    }

    .content-table::-webkit-scrollbar {
      width: 7px;
    }

    .content-table::-webkit-scrollbar-track {
      background: none;
    }

    .content-table::-webkit-scrollbar-thumb {
      background-color: #a5b3bc;
      border-radius: 10px;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      padding: 30px 25px 50px;
      min-height: 286px;

      &-head {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
        width: 100%;
        padding: 0 10px;

        & .title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          text-align: start;

          & .label {
            font-size: 20px;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;

            // button {
            //   background-color: white;
            //   border: none;
            //   padding: 5px;
            //   cursor: pointer;
            // }
          }
        }

        & .quantity {
          font-size: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          text-align: start;
        }
      }

      &-no-data {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        color: #bfbfbf;
        font-weight: bold;
        padding: 90px 0;
      }

      &-table {
        overflow-y: scroll;
        width: 100%;
        max-height: calc(100vh - 400px);
        min-height: 214px;

        .el-icon-info {
          color: #e6a23c;
        }

        .el-table {
          border-collapse: separate;
          border-spacing: 0;
          width: 100%;

          &::before {
            content: none;
            border: none !important;
          }

          .el-table__header {
            border-bottom: 1px solid black;

            th {
              color: black;
            }
          }

          .el-table__body {
            td {
              border: none;
            }

            th {
              border: none;
            }
          }
        }

        & .custom-table {
          font-size: 14px;
          color: black;
          text-align: center;

          .highlight {
            background-color: #fff583 !important;
          }

          & .el-table__body {
            td,
            th {
              padding: 8px 0 !important;
            }
          }
          & .el-table__header {
            td,
            th {
              padding: 0 0 10px !important;
            }
          }
        }
      }
    }
  }
}
</style>
