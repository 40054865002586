<template>
  <div class="beacon-list">
    <div class="header-content">
      <h3>Beacon Data</h3>
      <div class="header-content__details">
        <span>Quantity: {{ beaconList?.length }}</span>
        <div class="header-content-display-flex__inputs"></div>
        <div class="autocomplete-search">
          <el-autocomplete
            class="autocomplete-search__input-bar"
            v-model="beaconSearched"
            :fetch-suggestions="fetchSuggestions"
            placeholder="Search by Beacon Id or Label"
            @select="handleSelectedBeacon"
            @clear="clearSearchInfo"
            @input="clearSearchInfo"
            clearable
          >
            <template v-slot:empty>
              <div class="no-match-message">No match found</div>
            </template>
          </el-autocomplete>
        </div>
      </div>
    </div>
    <div class="table-container">
      <el-table
        :data="searchedInfo.length != 0 ? searchedInfo : beaconList"
        class="beacon-list-table"
        :max-height="tableHeight"
        style="width: 100%"
      >
        <el-table-column width="25px" align="start">
          <template slot-scope="scope">
            <el-tooltip
              v-if="currentMovingTag === scope.row.tagId"
              content="Thre's a tag is currently moving"
              placement="top"
              :key="scope.row.id + '-tooltip'"
            >
              <div
                class="status-circle"
                v-if="currentMovingTag === scope.row.tagId"
              ></div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="label" label="Beacon" sortable>
          <template slot-scope="scope">
            <template v-if="currentMovingTag === scope.row.tagId">
              <b>{{ gatewayLabel }}</b>
            </template>
            <template v-if="scope.row.tagId">
              <template v-if="scope.row.label">
                <b>{{ scope.row.label }}</b>
              </template>
              <br /><span class="grey-subtitle"
                >({{ toHex(scope.row.tagId, "tag") }})</span
              >
            </template>
            <template v-else>
              <hr />
            </template>
          </template>
        </el-table-column>

        <el-table-column
          prop="lastPosition"
          label="Entry Time"
          sortable
          min-width="110px"
        >
          <template slot-scope="scope">
            <template v-if="currentMovingTag === scope.row.tagId">
              <span v-html="getTimeStampTransformed(currentTagStartTs)"></span>
            </template>
            <template v-else-if="scope.row.lastPosition.createdDate">
              <span
                v-html="
                  getTimeStampTransformed(scope.row.lastPosition.createdDate)
                "
              ></span>
            </template>
            <template v-else>
              <hr />
            </template>
          </template>
        </el-table-column>

        <el-table-column
          prop="lastUpdate"
          label="Last Update"
          sortable
          min-width="110px"
        >
          <template slot-scope="scope">
            <template v-if="currentMovingTag === scope.row.tagId">
              <span v-html="getTimeStampTransformed(dashboardData)"></span>
            </template>
            <template v-else-if="scope.row.lastUpdate">
              <span
                v-html="getTimeStampTransformed(scope.row.lastUpdate)"
              ></span>
            </template>
            <template v-else>
              <hr />
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapState } from "vuex";
import tagsApi from "@/modules/tags/api/tags.api";
import { normalizeString } from "@/utils/utils";

export default {
  name: "BeaconList",
  props: {
    beaconList: {
      type: Array,
      default: () => []
    },
    currentMovingTag: {
      type: Number,
      default: 0
    },
    gatewayLabel: {
      type: String,
      default: ""
    },
    currentTagStartTs: {
      type: Number,
      default: 1
    }
  },

  data() {
    return {
      beaconSearched: "",
      currentTime: "",
      lastSeenLocations: {},
      loadingLastSeen: true,
      searchedInfo: [],
      tableHeight: 320
    };
  },

  computed: {
    ...mapState("clients", {
      clientData: "data"
    }),
    ...mapState("dashboard", {
      dashboardData: "data"
    }),
    ...mapState("user", {
      resources: "resources"
    })
  },

  methods: {
    toHex(str, type) {
      if (type === "timestamp") return this.getTimeStampTransformed(str);
      else {
        return str
          .toString(16)
          .toUpperCase()
          .match(/.{1,2}/g)
          .join(type === "anchor" ? ":" : "");
      }
    },

    getTimeStampTransformed(timestamp) {
      if (timestamp) {
        const formattedDate = moment
          .tz(moment.unix(timestamp), this.clientData.timezone)
          .format("MMM DD YYYY, HH:mm:ss");

        return formattedDate;
      }
      return "N/A";
    },

    // Checks if any beacon matches the searched item, if a match is found, adds the match to the results array
    doesBeaconMatchSearch(beacon, search, results = null) {
      const matchField = [
        this.toHex(beacon.tagId).toUpperCase(),
        beacon.label.toUpperCase()
      ].find(field => field.includes(search));

      if (matchField) {
        if (results !== null) {
          results.push({
            value: matchField,
            data: beacon
          });
        }
        return true;
      }
      return false;
    },

    // Fetches suggestions for the autocomplete based on the query string
    fetchSuggestions(queryString, cb) {
      const search = normalizeString(queryString);
      const results = [];

      const match = this.beaconList.filter(beacon =>
        this.doesBeaconMatchSearch(beacon, search, results)
      );

      if (!match) {
        cb([{ value: "No match found", disabled: true }]);
      } else {
        this.searchedInfo = match;
        cb(results);
      }
    },

    // Handles the selection of a beacon from the autocomplete suggestions
    handleSelectedBeacon(searchedBeacon) {
      if (searchedBeacon.value === "No match found") {
        return;
      } else if (searchedBeacon === "") {
        this.searchedInfo = [];
        return;
      }
      this.searchedInfo = [];
      const search = normalizeString(searchedBeacon.value);

      this.searchedInfo = this.beaconList.filter(beacon =>
        this.doesBeaconMatchSearch(beacon, search)
      );
    },

    // Clears the searchedInfo array
    clearSearchInfo() {
      this.searchedInfo = [];
    }
  }
};
</script>

<style lang="scss">
.beacon-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 30px 8px 50px;

  input {
    padding: 4px 12px;
    color: rgba(0, 0, 0, 0.7);
    border: 1px solid rgba(0, 0, 0, 0.12);
    transition: 0.15s all ease-in-out;
    background: white;
    &:focus {
      outline: none;
      transform: scale(1.05);
      & + label {
        font-size: 10px;
        transform: translateY(-24px) translateX(-12px);
      }
    }
    &::-webkit-input-placeholder {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.5);
      font-weight: 100;
    }
  }

  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px 0 17px;

    @media (max-width: 500px) {
      align-items: flex-start;
      flex-direction: column;
      padding: 0 10px 0 10px;
    }

    &__details {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 21px;

      @media (max-width: 500px) {
        justify-content: space-between;
        width: 100%;
      }
    }

    .header-content-display-flex {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      position: relative;
      width: 100%;

      &__inputs {
        display: flex;
      }

      &__search {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 1rem;
      }
    }
  }

  .cell {
    .el-button--mini {
      padding: 2px;
      border: none;
      background-color: inherit;
      color: #656f7d;
      cursor: pointer;

      .el-icon-time,
      .el-icon-aim {
        font-size: 14px;
        font-weight: bold;
      }
    }
    .el-button {
      margin-left: 0;
    }
    .el-button--mini:hover {
      color: $--color-primary;
    }
  }

  .table-container {
    width: 100%;
    overflow: hidden;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-top: 5px;
    border-radius: 8px;
  }

  .el-table {
    border-radius: 8px;
    color: $--color-primary;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    @media (max-width: $sm) {
      width: 100%;
    }
    .el-table__body {
      td,
      th {
        padding: 8px 2px !important;
      }
    }

    .status-circle {
      background-color: rgba(50, 205, 50, 0.7);
      width: 10px;
      height: 10px;
      border-radius: 100%;
    }

    .el-table__header-wrapper th {
      background-color: #f8f9fa;
      color: $--color-primary;
      font-weight: bold;
      font-size: 14px;
    }

    .grey-subtitle {
      color: #656f7d;
      font-weight: bold;
      font-size: 12px;
    }
  }
}
</style>
