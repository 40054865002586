<template>
  <div class="cargo-check">
    <div class="cargo-check__head">
      <div class="anchor-sorter">
        <AnchorSorter
          :anchors="anchors"
          @update-anchors="updateSortedAnchors"
        />
      </div>
      <div class="tag-searcher">
        <TagSearcher
          :anchors="anchors"
          :unlinkedTags="unlinkedTags"
          :tagsList="tagsList"
          :toHex="toHex"
          :updateSearchResults="updateSearchResults"
          @update:tagToHighlight="tagToHighlight = $event"
        />
      </div>
      <div class="total-quantities">
        <span><b>Total Anchors:</b> {{ anchors.length }}</span>
        <span><b>Total Tags:</b> {{ tagsList.length }}</span>
      </div>
    </div>

    <div
      class="cargo-check__content"
      :class="[this.anchors.length > 1 ? 'small-gap' : 'large-gap']"
      v-loading="loading"
      element-loading-background="#F0F5FB"
    >
      <div
        class="anchors"
        v-if="!foundAnchor.length && !foundUnlinkedTag.length"
      >
        <div v-for="anchor in anchors" :key="anchor.id" class="anchor-item">
          <AnchorCard
            :anchor="anchor"
            :tagToHighlight="tagToHighlight"
            :toHex="toHex"
          />
        </div>
      </div>

      <div
        class="searched-anchors"
        v-if="foundAnchor.length && !foundUnlinkedTag.length"
      >
        <div v-for="anchor in foundAnchor" :key="anchor.id" class="anchor-item">
          <AnchorCard
            :anchor="anchor"
            :tagToHighlight="tagToHighlight"
            :toHex="toHex"
          />
        </div>
      </div>

      <div class="inactive-tags" v-if="!foundAnchor.length && anchors.length">
        <!-- <button v-if="!showunlinkedTags" @click="handleShowTagsClick()">Inactive</button> -->
        <!-- <div v-if="showunlinkedTags"> -->
        <UnlinkedTagsCard
          :unlinkedTags="unlinkedTags"
          :tagToHighlight="tagToHighlight"
          :toHex="toHex"
        />
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import AnchorCard from "@/components/AnchorCard.vue";
import UnlinkedTagsCard from "@/components/UnlinkedTagsCard.vue";
import AnchorSorter from "@/components/AnchorSorter.vue";
import TagSearcher from "@/components/TagSearcher.vue";
import { mapState } from "vuex";
import tagsApi from "@/modules/tags/api/tags.api";

export default {
  name: "CargoCheck",
  components: {
    AnchorCard,
    UnlinkedTagsCard,
    AnchorSorter,
    TagSearcher
  },

  data() {
    return {
      // showunlinkedTags: false,
      tagsList: [],
      interval: null,
      anchors: [],
      foundAnchor: [],
      foundUnlinkedTag: [],
      sortedAnchors: [],
      loading: true,
      tagToHighlight: null,
      currentCriteria: null,
      anchorOrder: {
        labelAsc: null,
        tagsAsc: null
      }
    };
  },

  computed: {
    ...mapState("clients", {
      clientData: "data"
    }),
    ...mapState("user", {
      resources: "resources"
    }),
    unlinkedTags() {
      return this.tagsList?.filter(tag => !tag.isRecent || !tag.anchorId);
    }
  },

  methods: {
    // handleShowTagsClick() {
    //   this.showunlinkedTags = !this.showunlinkedTags;
    // }

    async getAnchorTagsList() {
      try {
        const response = await tagsApi.getAnchorTagsList();
        this.tagsList = response.data;
        this.getAnchorsTags();
      } catch (error) {
        console.log("Error fetching tags list:", error);
      }
    },

    // Gets anchors and attaches recent linked tags from tagsList.
    getAnchorsTags() {
      this.resources.data.anchors = this.resources.data.anchors.filter(
        anchor => anchor.id != 87
      );
      this.anchors = this.resources.data.anchors.map(anchor => {
        this.$set(
          anchor,
          "tagsList",
          this.tagsList.filter(
            tag => tag.anchorId === anchor.id && tag.isRecent
          )
        );
        return {
          id: anchor.id,
          label: anchor.label,
          tagsList: anchor.tagsList,
          offlineTs: anchor.offlineTs
        };
      });

      this.sortAndUpdateAnchors();

      this.loading = false;
    },

    sortAndUpdateAnchors() {
      if (!this.anchors.length) return;

      switch (this.currentCriteria) {
        case "label":
          this.anchors.sort((a, b) =>
            this.anchorOrder.labelAsc
              ? a.label.localeCompare(b.label)
              : b.label.localeCompare(a.label)
          );
          break;

        case "tags":
          this.anchors.sort((a, b) =>
            this.anchorOrder.tagsAsc
              ? a.tagsList.length - b.tagsList.length
              : b.tagsList.length - a.tagsList.length
          );
          break;
      }
    },

    updateSortedAnchors(sortingCriteria, anchorOrder) {
      this.currentCriteria = sortingCriteria;
      this.anchorOrder = anchorOrder;
      this.sortAndUpdateAnchors();
    },

    toHex(str) {
      return str
        .toString(16)
        .toUpperCase()
        .match(/.{1,2}/g)
        .join("");
    },

    // Updates the search results based on the type of the finding
    updateSearchResults(searchResults, type) {
      if (type === "anchor") {
        this.foundAnchor = searchResults;
      } else if (type === "unlinkedTag") {
        this.foundUnlinkedTag = searchResults;
      }
    }
  },

  created() {
    this.interval = setInterval(this.getAnchorTagsList, 2000);
  },

  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
};
</script>

<style lang="scss">
.cargo-check {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 40px 10px 50px;

  @media (max-width: $sm) {
    padding: 40px 10px 50px;
  }

  &__head {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 70px;

    @media (max-width: $sm) {
      gap: 10px;
      flex-direction: column;
    }

    .total-quantities {
      display: flex;
      gap: 20px;
    }
  }

  .small-gap {
    gap: 10px;

    @media (max-width: 1500px) {
      gap: 25px;
    }
  }

  .large-gap {
    gap: 5%;

    @media (max-width: $sm) {
      gap: 10px;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    height: 100%;
    color: black;

    @media (max-width: 1500px) {
      flex-direction: column;
      align-items: center;
    }

    .anchors,
    .searched-anchors {
      display: flex;
      justify-content: center;
      gap: 25px;
      flex-wrap: wrap;
      max-width: 1550px;
    }

    .inactive-tags {
      position: relative;
      min-width: 351px;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
