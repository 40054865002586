import * as actionTypes from "@/store/action-types";
import * as mutationTypes from "@/store/mutation-types";
import anchorsApi from "../api/anchors.api";
import routesApi from "@/modules/routes/api/routes.api";
import { AnchorStoreContext } from "../anchors.types";

export default {
  async [actionTypes.ANCHOR_GET_ROUTES](
    { commit, state }: AnchorStoreContext,
    { anchorId, query }: any
  ) {
    commit(mutationTypes.ANCHOR_SET_ROUTES, {
      ...state.routes,
      loading: true,
      error: null
    });

    try {
      const response = await routesApi.getAnchorRoutes(anchorId, query);
      const next = query["page_size"] === response.data.length;
      commit(mutationTypes.ANCHOR_SET_ROUTES, {
        data:
          query.page === 0
            ? response.data
            : state.routes.data.concat(...response.data),
        next,
        loading: false,
        error: null
      });
      return response;
    } catch (error) {
      commit(mutationTypes.ANCHOR_SET_ROUTES, {
        data: null,
        loading: false,
        error
      });
      throw error;
    }
  },

  async [actionTypes.ANCHOR_GET_DETAILS](
    { commit, state }: AnchorStoreContext,
    anchorId: number
  ) {
    commit(mutationTypes.ANCHOR_SET_DETAILS, {
      ...state.anchor,
      loading: true,
      error: null
    });

    try {
      const { data } = await anchorsApi.getAnchor(anchorId);

      commit(mutationTypes.ANCHOR_SET_DETAILS, {
        data,
        loading: false,
        error: null
      });
      return data;
    } catch (error) {
      commit(mutationTypes.ANCHOR_SET_DETAILS, {
        data: null,
        loading: false,
        error
      });
      throw error;
    }
  }
};
