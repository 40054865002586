<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :height="height"
    viewBox="0 0 24 24"
    :width="width"
  >
    <path d="M17 4h-3V2h-4v2H7v18h10V4z" fill="#1F4258" />
    <defs>
      <clipPath id="battery">
        <path d="M17 4h-3V2h-4v2H7v18h10V4z" />
      </clipPath>
    </defs>
    <rect
      x="0"
      y="0"
      fill="#1F4258"
      width="60%"
      height="100%"
      clip-path="url(#battery)"
    />
    <rect
      x="0"
      :y="percentage"
      fill="green"
      width="100%"
      height="100%"
      clip-path="url(#battery)"
    />
  </svg>
</template>

<script>
export default {
  props: {
    battery: {
      type: Number,
      required: true
    },
    width: {
      type: Number,
      default: 24
    },
    height: {
      type: Number,
      default: 24
    }
  },

  computed: {
    percentage() {
      let percentage = "0%";
      switch (this.battery) {
        case 0:
          percentage = "100%";
          break;
        case 1:
          percentage = "65%";
          break;
        case 2:
          percentage = "40%";
          break;
        case 3:
          percentage = "0%";
          break;
        default:
          break;
      }
      return percentage;
    }
  }
};
</script>

<style lang="scss"></style>
