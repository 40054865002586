import { AuthState } from "@/modules/auth/auth.types";

export default {
  isAuthenticated(state: AuthState) {
    return state.user.data?.email?.length! > 0 || false;
  },
  isAdmin(state: AuthState) {
    return state.user.data?.authScopes?.includes("client") || false;
  },
  isAzitek(state: AuthState) {
    return state.user.data?.authScopes?.includes("azitek") || false;
  },
  isSuperUser(state: AuthState) {
    return state.user.data?.authScopes?.includes("super") || false;
  },
  isChangePasswordUser(state: AuthState) {
    return state.user.data?.authScopes?.includes("change-password") || false;
  },
  isTracker(state: AuthState) {
    return state.user.data?.clientType == 2;
  },
  getClientType(state: AuthState) {
    return state.user.data?.clientType;
  }
};
