<script>
import { Bar } from "vue-chartjs";
import moment from "moment";
import { rectangle } from "leaflet";

export default {
  extends: Bar,

  props: {
    list: {
      type: Array,
      required: true
    },
    buildBin: {
      type: Boolean,
      default: false
    },
    analyticsData: {
      type: Array
    },
    selectMultiple: {
      type: Boolean,
      default: false
    },
    finishedSelection: {
      type: Boolean,
      default: false
    },
    resetGraph: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      intervals: [],
      barsClicked: [],
      lastHoverIndex: null,
      filterByInterval: {
        min: 0,
        max: 0
      },
      colorPrimary: "#1F4258",
      colorClicked: "#788D9A",
      colorHovered: "#D2D9DD",
      firstClick: true,
      secondClick: false,
      firstClickIndex: null,
      filterStack: [],
      chartdata: {
        filtered: false,
        labels: null,
        datasets: [
          {
            backgroundColor: [],
            data: null
          }
        ]
      },
      options: {
        tooltips: {
          callbacks: {
            label: tooltipItem => this.formatTooltipValue(tooltipItem.value)
          }
        },
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false,
        onClick: this.onClick,
        onHover: this.onHover,
        scales: {
          yAxes: [
            {
              display: true,
              ticks: {
                suggestedMin: 0,
                stepSize: 1
              },
              scaleLabel: {
                display: true,
                labelString: "Number of routes"
              }
            }
          ],
          xAxes: [
            {
              barPercentage: 0.85,
              categoryPercentage: 1.0,
              gridLines: {
                display: false
              },
              scaleLabel: {
                display: true,
                labelString: "Time (hh:mm)"
              }
            }
          ]
        }
      }
    };
  },

  watch: {
    finishedSelection(newValue) {
      if (newValue) {
        if (this.barsClicked.length === 0) this.$emit("done");
        else this.filterSelection();
      }
    },
    selectMultiple(newValue) {
      if (!newValue) {
        this.resetColors();
      }
    },
    resetGraph(newValue) {
      if (newValue) {
        this.resetColors();
        this.$emit("reseted-graph");
      }
    },
    list: {
      deep: true,
      handler(newValue) {
        if (this.buildBin) {
          this.filterByInterval = {
            min: Math.min(...newValue),
            max: Math.max(...newValue)
          };
          this.calculateBin();
        } else {
          this.chartdata.datasets[0].data = newValue;
          this.chartdata.labels = this.labels;
        }
      }
    }
  },

  methods: {
    popFilterStack() {
      const [min, max] = this.filterStack.pop();
      this.filterByInterval.min = min;
      this.filterByInterval.max = max;

      if (!this.filterStack.length) {
        this.$emit("has-filter-stack", false);
      }

      this.$emit("filter-interval", [
        this.filterByInterval.min,
        this.filterByInterval.max
      ]);
      this.calculateBin();
    },
    formatTooltipValue(value) {
      return "Number of Routes: " + value;
    },
    onHover(data, data1) {
      if (this.firstClick) return;
      else if (data1.length !== 0 && !this.secondClick) {
        //if the user has already clicked and is hovering over a bar with the primary color
        //all bars from the first bar clicked all the way to the hovered bar will change color
        if (
          this.chartdata.datasets[0].backgroundColor[data1[0]._index] ===
          this.colorPrimary
        ) {
          if (this.firstClickIndex < data1[0]._index)
            for (let i = this.firstClickIndex + 1; i <= data1[0]._index; i++)
              this.chartdata.datasets[0].backgroundColor[i] = this.colorHovered;
          else
            for (let i = this.firstClickIndex - 1; i >= data1[0]._index; i--)
              this.chartdata.datasets[0].backgroundColor[i] = this.colorHovered;
        }
        //if the user has already clicked but is hovering over a bar which doesn't have the primary color
        //all bars in front or back of the hovered bar will be reset (except for the clicked one)
        else if (
          this.chartdata.datasets[0].backgroundColor[data1[0]._index] !==
            this.colorPrimary &&
          data1[0]._index !== this.lastHoverIndex
        ) {
          if (data1[0]._index > this.firstClickIndex) {
            //When hover bar is in front of the clicked bar
            //reset every bar until the clicked bar
            //paint every bar ahead of the hover index
            for (let i = 0; i < this.firstClickIndex; i++)
              this.chartdata.datasets[0].backgroundColor[i] = this.colorPrimary;

            for (
              let i = data1[0]._index + 1;
              i < this.chartdata.datasets[0].backgroundColor.length;
              i++
            ) {
              this.chartdata.datasets[0].backgroundColor[i] = this.colorPrimary;
              this.lastHoverIndex = data1[0]._index;
            }
          } else if (data1[0]._index < this.firstClickIndex) {
            for (
              let i = this.firstClickIndex + 1;
              i < this.chartdata.datasets[0].backgroundColor.length;
              i++
            )
              this.chartdata.datasets[0].backgroundColor[i] = this.colorPrimary;
            for (let i = data1[0]._index - 1; i >= 0; i--) {
              this.chartdata.datasets[0].backgroundColor[i] = this.colorPrimary;
              this.lastHoverIndex = data1[0]._index;
            }
          } else return;
        } else {
          return;
        }
        const newOptions = { ...this.options, animation: { duration: 0 } };
        this.renderChart(this.chartdata, newOptions);
        return;
      }
      return;
    },
    onClick(data, data1) {
      if (this.selectMultiple && !this.finishedSelection) {
        this.barsClicked.push(data1[0]._index);
        if (this.firstClick) {
          this.chartdata.datasets[0].backgroundColor[
            data1[0]._index
          ] = this.colorClicked;
          this.firstClick = false;
          this.firstClickIndex = data1[0]._index;
        } else if (!this.secondClick) {
          if (data1[0]._index > this.firstClickIndex)
            for (let i = this.firstClickIndex; i <= data1[0]._index; i++)
              this.chartdata.datasets[0].backgroundColor[i] = this.colorClicked;
          else if (data1[0]._index < this.firstClickIndex) {
            for (let i = this.firstClickIndex; i >= data1[0]._index; i--)
              this.chartdata.datasets[0].backgroundColor[i] = this.colorClicked;
          } else {
            this.resetColors();
            return;
          }
          this.secondClick = true;
        } else {
          return;
        }

        const newOptions = { ...this.options, animation: { duration: 0 } };
        this.renderChart(this.chartdata, newOptions);
        return;
      }

      if (this.buildBin) {
        if (this.barsClicked.length !== 0) {
          return;
        }
        if (this.numberBars === 1) return;

        this.filterStack.push([
          this.filterByInterval.min,
          this.filterByInterval.max
        ]);
        this.$emit("has-filter-stack", true);

        if (data1[0]) {
          this.chartdata.filtered = true;
          const indexClicked = data1[0]._index;
          this.filterByInterval = {
            min:
              indexClicked === 0
                ? this.filterByInterval.min
                : this.intervals[data1[0]._index - 1] + 1,
            max: this.intervals[data1[0]._index]
          };

          this.$emit("filter-interval", [
            this.filterByInterval.min,
            this.filterByInterval.max
          ]);
          this.calculateBin();
        }
      }
    },
    filterSelection() {
      if (this.buildBin) {
        if (this.numberBars === 1) return;

        this.filterStack.push([
          this.filterByInterval.min,
          this.filterByInterval.max
        ]);
        this.$emit("has-filter-stack", true);
        if (this.barsClicked.length !== 0) {
          this.chartdata.filtered = true;
          this.filterByInterval = {
            min:
              Math.min(...this.barsClicked) === 0
                ? Math.min(...this.list)
                : this.intervals[Math.min(...this.barsClicked) - 1],
            max: this.intervals[Math.max(...this.barsClicked)]
          };
          this.$emit("filter-interval", [
            this.filterByInterval.min,
            this.filterByInterval.max
          ]);
          this.calculateBin();
          this.$emit("done");
          this.firstClick = true;
        }
      }
    },
    resetColors() {
      this.chartdata.datasets[0].backgroundColor = [];
      for (const i in this.chartdata.datasets[0].data)
        this.chartdata.datasets[0].backgroundColor.push(this.colorPrimary);
      this.lastHoverIndex = null;
      this.firstClick = true;
      this.secondClick = false;
      this.firstClickIndex = null;
      this.barsClicked = [];
      this.renderChart(this.chartdata, this.options);
    },
    calculateBin() {
      //console.log(this.analyticsData)
      this.intervals = [];
      //console.log("min: " + this.filterByInterval.min)
      // console.log("max: " + this.filterByInterval.max)
      //this.list.forEach(element => {
      //if (element >= this.filterByInterval.min && element <= this.filterByInterval.max)
      //console.log(element)
      //});
      this.localList = this.list.filter(
        value =>
          value >= this.filterByInterval.min &&
          value <= this.filterByInterval.max
      );

      let dataList = [];
      const newData = [];

      if (this.analyticsData.length === 1)
        dataList = [this.analyticsData[0].duration];
      else dataList = this.localList.sort((a, b) => a - b);

      const numberElements = dataList.length;

      let value = null;

      if (numberElements === 1) {
        value = this.analyticsData.find(
          element => element.duration === dataList[0]
        );
        //console.log(dataList)
        this.$emit("change-data", {
          dataList: dataList,
          startDate: value.startDate
        });
      } else this.$emit("change-data", { dataList: dataList, startDate: null });

      let iqr = 1;

      /*if (numberElements <= 5) {
        const q1 = this.median(dataList.slice(0, numberElements / 2));
        const q3 = this.median(dataList.slice(numberElements / 2));
        iqr = q3 - q1 !== 0 ? q3 - q1 : 1;
      }*/

      if (numberElements > 1) {
        const q1 = this.median(dataList.slice(0, numberElements / 2));
        const q3 = this.median(dataList.slice(numberElements / 2));
        iqr = q3 - q1 !== 0 ? q3 - q1 : 1;
      }

      const barWidth = 2 * (iqr / Math.cbrt(numberElements));
      this.numberBars = Math.max(
        1,
        Math.ceil((Math.max(...dataList) - Math.min(...dataList)) / barWidth)
      );
      if (numberElements <= 5) this.numberBars = numberElements;

      let currentBarValue = Math.min(...dataList);

      const dataLabels = [];
      if (numberElements > 5)
        for (let i = 0; i < this.numberBars; i++) {
          const newBarValue = Math.min(
            Number.parseInt(currentBarValue + barWidth, 10),
            this.filterByInterval.max
          );

          if (i === 0) {
            dataLabels.push(
              `${this.convertToDate(
                Number.parseFloat(currentBarValue).toFixed(0)
              )} - ${this.convertToDate(
                Number.parseFloat(newBarValue).toFixed(0)
              )}`
            );
            this.intervals.push(Number.parseInt(newBarValue, 10));
          } else {
            dataLabels.push(
              `${this.convertToDate(
                Number.parseFloat(currentBarValue + 1).toFixed(0)
              )} - ${this.convertToDate(
                Number.parseFloat(newBarValue).toFixed(0)
              )}`
            );
            this.intervals.push(Number.parseInt(newBarValue, 10));
          }
          currentBarValue += barWidth;
        }
      else {
        for (let i = 0; i < numberElements; i++) {
          currentBarValue = dataList[i];
          dataLabels.push(
            `${this.convertToDate(
              Number.parseFloat(currentBarValue).toFixed(0)
            )} - ${this.convertToDate(
              Number.parseFloat(currentBarValue + 1).toFixed(0)
            )}`
          );
          this.intervals.push(Number.parseInt(currentBarValue, 10));
          newData.push(1);
        }
      }

      if (numberElements > 5) {
        for (const i in this.intervals) {
          if (i == 0)
            newData.push(
              this.countIfLessOrEqualTo(dataList, this.intervals[i])
            );
          else
            newData.push(
              this.countIfInBetween(
                dataList,
                this.intervals[i - 1],
                this.intervals[i]
              )
            );
        }
      }
      for (const i in newData)
        this.chartdata.datasets[0].backgroundColor.push(this.colorPrimary);
      this.chartdata.datasets[0].data = newData;
      this.chartdata.labels = dataLabels;
      this.barsClicked = [];

      this.renderChart(this.chartdata, this.options);
    },

    convertToDate(numberOfSeconds) {
      return moment.utc(numberOfSeconds * 1000).format("HH:mm");
    },

    median(arr) {
      const mid = Math.floor(arr.length / 2),
        nums = [...arr].sort((a, b) => a - b);
      return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
    },

    countIfLessOrEqualTo(array, value) {
      return array.filter(x => x <= value).length;
    },
    countIfInBetween(array, min, max) {
      return array.filter(x => x > min && x <= max).length;
    }
  },

  mounted() {
    if (this.buildBin) {
      this.filterByInterval = {
        min: Math.min(...this.list),
        max: Math.max(...this.list)
      };
      this.calculateBin();
    } else {
      this.chartdata.datasets[0].data = this.list;
      this.chartdata.labels = this.labels;
    }
  }
};
</script>
