import actions from "./clients.store.actions";
import mutations from "./clients.store.mutations";
import state from "./clients.store.state";
import getters from "./clients.store.getters";
import { ClientsState } from "../clients.types";
import { RootState } from "@/store/store.types";
import { Module } from "vuex";

export const clientsStore: Module<ClientsState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
