var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"metrics"},[_c('el-card',{attrs:{"shadow":_vm.shadow}},[_c('div',{style:(_vm.computeColor),attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"left-content"},[(
              _vm.usageInfo.constraintTag != null ||
                _vm.usageInfo.constraintShiftStart != 0 ||
                _vm.usageInfo.constraintShiftEnd != 86399
            )?_c('span',{staticClass:"constraint-icon"},[_c('i',{class:_vm.usageInfo.constraintTag != null
                  ? 'el-icon-location-outline'
                  : _vm.usageInfo.constraintShiftStart != 0 ||
                    _vm.usageInfo.constraintShiftEnd != 86399
                  ? 'el-icon-time'
                  : ''})]):_vm._e(),_c('h3',{staticClass:"route-label"},[_vm._v(_vm._s(_vm.usageInfo.routeLabel))])]),_c('span',{staticStyle:{"float":"right","font-size":"14px"}},[(
              _vm.usageInfo.constraintShiftStart != 0 ||
                _vm.usageInfo.constraintShiftEnd != 86399
            )?_c('span',[_vm._v(" "+_vm._s(_vm.formatTime(_vm.usageInfo.constraintShiftStart))+" - "+_vm._s(_vm.formatTime(_vm.usageInfo.constraintShiftEnd))+" ")]):(_vm.usageInfo.constraintTag)?_c('span',[_vm._v(" "+_vm._s(_vm.usageInfo.constraintTag)+" ")]):_vm._e(),_c('el-tooltip',{attrs:{"placement":"top","content":"Lap analytics","hide-after":2000}},[_c('el-button',{on:{"click":function($event){return _vm.redirectToLapAnalytics()}}},[_c('img',{attrs:{"src":require("@/assets/bar-chart.svg"),"alt":"bar-chart"}})])],1)],1)])]),_c('div',{staticClass:"time-based-metrics"},[_c('span',{staticClass:"date"},[_vm._v("Today")]),_c('div',{staticClass:"card-data"},[_c('div',{class:{
            'card-data__valid':
              _vm.getPercentage(
                _vm.usageInfo.dCount,
                _vm.usageInfo.dCount + _vm.usageInfo.dCountError
              ) >= 90
          }},[(
              _vm.getPercentage(
                _vm.usageInfo.dCount,
                _vm.usageInfo.dCount + _vm.usageInfo.dCountError
              ) >= 90
            )?_c('span',[_c('i',{staticClass:"el-icon-top"})]):_vm._e(),_c('b',[_vm._v(_vm._s(_vm.usageInfo.dCount || 0))]),_vm._v(" valid ("+_vm._s(_vm.getPercentage( _vm.usageInfo.dCount, _vm.usageInfo.dCount + _vm.usageInfo.dCountError ))+"%) ")]),_c('div',{class:{
            'card-data__error':
              _vm.getPercentage(
                _vm.usageInfo.dCountError,
                _vm.usageInfo.dCount + _vm.usageInfo.dCountError
              ) >= 10
          }},[(
              _vm.getPercentage(
                _vm.usageInfo.dCountError,
                _vm.usageInfo.dCount + _vm.usageInfo.dCountError
              ) >= 10
            )?_c('span',[_c('i',{staticClass:"el-icon-bottom"})]):_vm._e(),_c('b',[_vm._v(_vm._s(_vm.usageInfo.dCountError || 0))]),_vm._v(" errors ("+_vm._s(_vm.getPercentage( _vm.usageInfo.dCountError, _vm.usageInfo.dCount + _vm.usageInfo.dCountError ))+"%) ")]),_c('div',{staticClass:"card-data__average-duration"},[_c('b',[_vm._v(" "+_vm._s(_vm.usageInfo.dAvg ? _vm.moment.utc(_vm.usageInfo.dAvg * 1000).format("HH") + "h" + _vm.moment.utc(_vm.usageInfo.dAvg * 1000).format("mm") + "m" : "No info of")+" ")]),_vm._v(" Avg Duration ")])])]),(_vm.showLastTwoWeeks)?_c('div',{staticClass:"time-based-metrics"},[_c('span',{staticClass:"date"},[_vm._v("Last Two Weeks")]),_c('div',{staticClass:"card-data"},[_c('div',{class:{
            'card-data__valid':
              _vm.getPercentage(
                _vm.usageInfo.twCount,
                _vm.usageInfo.twCount + _vm.usageInfo.twErrors
              ) >= 90
          }},[(
              _vm.getPercentage(
                _vm.usageInfo.twCount,
                _vm.usageInfo.twCount + _vm.usageInfo.twErrors
              ) >= 90
            )?_c('span',[_c('i',{staticClass:"el-icon-top"})]):_vm._e(),_c('b',[_vm._v(_vm._s(_vm.usageInfo.twCount || 0))]),_vm._v(" valid ("+_vm._s(_vm.getPercentage( _vm.usageInfo.twCount, _vm.usageInfo.twCount + _vm.usageInfo.twErrors ))+"%) ")]),_c('div',{class:{
            'card-data__error':
              _vm.getPercentage(
                _vm.usageInfo.twErrors,
                _vm.usageInfo.twCount + _vm.usageInfo.twErrors
              ) >= 10
          }},[(
              _vm.getPercentage(
                _vm.usageInfo.twErrors,
                _vm.usageInfo.twCount + _vm.usageInfo.twErrors
              ) >= 10
            )?_c('span',[_c('i',{staticClass:"el-icon-bottom"})]):_vm._e(),_c('b',[_vm._v(_vm._s(_vm.usageInfo.twErrors || 0))]),_vm._v(" errors ("+_vm._s(_vm.getPercentage( _vm.usageInfo.twErrors, _vm.usageInfo.twCount + _vm.usageInfo.twErrors ))+"%) ")]),_c('div',{staticClass:"card-data__average-duration"},[_c('b',[_vm._v(" "+_vm._s(_vm.usageInfo.twAvg ? _vm.moment.utc(_vm.usageInfo.twAvg * 1000).format("HH") + "h" + _vm.moment.utc(_vm.usageInfo.twAvg * 1000).format("mm") + "m" : "No info of")+" ")]),_vm._v(" Avg Duration ")])])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }