import { UserState } from "../user.types";

export const initialState = () => ({
  resources: {
    usageInfo: [],
    data: {
      anchors: [],
      tags: []
    },
    updatedData: {
      anchors: [],
      tags: []
    },
    loading: false,
    sidebarAnchor: null,
    globalAnchors: [],
    error: null
  },
  isChangingInformation: false,
  discardChanges: false,
  pathToChangeTo: "",
  activeRoute: {}
});

export default initialState() as UserState;
