<template>
  <div class="autocomplete-search">
    <el-autocomplete
      class="autocomplete-search__input-bar"
      v-model="tagSearched"
      :fetch-suggestions="querySearch"
      placeholder="Search by Tag ID or Label"
      @select="showSelectedTag"
      @input="showSelectedTag"
      @clear="handleClear"
      clearable
    >
      <template v-slot:empty>
        <div class="no-match-message">No match found</div>
      </template>
    </el-autocomplete>
  </div>
</template>

<script>
export default {
  name: "TagSearcher",

  props: {
    anchors: {
      type: Array,
      default: () => []
    },
    unlinkedTags: {
      type: Array,
      default: () => []
    },
    tagsList: {
      type: Array,
      default: () => []
    },
    toHex: {
      type: Function,
      required: true
    },
    updateSearchResults: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      tagSearched: ""
    };
  },

  methods: {
    // Checks if the tag matches the search query
    matchTag(tag, query) {
      const hexTagId = this.toHex(tag.tagId);
      return (
        query.includes(hexTagId) ||
        query.slice(-3).includes(hexTagId.slice(-3)) ||
        tag.label.toUpperCase().includes(query)
      );
    },

    // Processes and updates foundAnchor and foundInactiveTag based on the filtered results
    processFilteredTags(foundAnchor, foundInactiveTag, tag) {
      if (foundAnchor.length) {
        this.updateSearchResults(
          foundAnchor.map(tag => tag.anchor),
          "anchor"
        );
        this.$emit("update:tagToHighlight", this.tagSearched);
      } else if (foundInactiveTag.length) {
        this.updateSearchResults(foundInactiveTag, "unlinkedTag");
        this.$emit("update:tagToHighlight", this.tagSearched);
      }
    },

    // Searches for the anchor the tag is associated with or checks if it is an unlinked tag
    showSelectedTag(tag) {
      if (tag.value === "No match found") {
        return;
      } else if (this.tagSearched === "") {
        this.$emit("update:tagToHighlight", "");
        this.updateSearchResults([], "anchor");
        this.updateSearchResults([], "unlinkedTag");
        return;
      }

      this.$emit("update:tagToHighlight", "");
      this.updateSearchResults([], "anchor");
      this.updateSearchResults([], "unlinkedTag");
      const query = this.tagSearched.toUpperCase();

      const anchorsWithTags = this.anchors.flatMap(anchor =>
        anchor.tagsList.map(tag => ({ ...tag, anchor }))
      );

      const foundAnchor = anchorsWithTags.filter(tag =>
        this.matchTag(tag, query)
      );
      const foundInactiveTag = this.unlinkedTags.filter(tag =>
        this.matchTag(tag, query)
      );

      this.processFilteredTags(foundAnchor, foundInactiveTag, tag);
    },

    // Handles the clear event of the autocomplete input
    handleClear() {
      if (this.tagSearched === "") {
        this.$emit("update:tagToHighlight", "");
        this.updateSearchResults([], "anchor");
        this.updateSearchResults([], "unlinkedTag");
      }
    },

    // Fetches suggestions for autocomplete
    querySearch(queryString, cb) {
      const tags = this.tagsList.flatMap(tag => {
        if (this.toHex(tag.tagId) !== tag.label) {
          return [
            { value: this.toHex(tag.tagId), label: tag.label },
            { value: tag.label, label: tag.label }
          ];
        } else {
          return { value: this.toHex(tag.tagId), label: tag.label };
        }
      });

      const results = queryString
        ? tags.filter(tag => {
            const query = queryString.toUpperCase();
            return (
              tag.value.includes(query) ||
              tag.value.slice(-3).includes(query.slice(-3)) ||
              tag.label.toUpperCase().includes(query)
            );
          })
        : tags;

      if (results.length === 0) {
        cb([{ value: "No match found", disabled: true }]);
      } else {
        cb(results);
      }
    }
  }
};
</script>

<style lang="scss">
.autocomplete-search {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: start;

  .el-input__inner {
    width: 200px !important;
    height: 40px !important;
    border-radius: 10px !important;
    font-size: 12px;
  }
}
</style>
