import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import locale from "element-ui//lib/locale/lang/en";
import Fullscreen from "vue-fullscreen";

require("leaflet");
require("leaflet-path-drag");

import "./assets/styles/index.scss";
import "leaflet/dist/leaflet.css";

Vue.use(ElementUI, { locale });

Vue.use(Fullscreen);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
