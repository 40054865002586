import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { RootState } from "./store.types";
import { authStore } from "../modules/auth/store";
import { clientsStore } from "../modules/clients/store";
import { userStore } from "@/modules/user/store";
import { anchorsStore } from "@/modules/anchors/store";
import { dashboardStore } from "@/modules/dashboard/store";

Vue.use(Vuex);

export const storeOptions: StoreOptions<RootState> = {
  modules: {
    auth: authStore,
    clients: clientsStore,
    user: userStore,
    anchors: anchorsStore,
    dashboard: dashboardStore
  },
  actions: {
    resetStore({ commit }) {
      commit("auth/reset");
      commit("clients/reset");
      commit("user/reset");
      commit("anchors/reset");
      commit("dashboard/reset");
    }
  }
};

export default new Vuex.Store<RootState>(storeOptions);
