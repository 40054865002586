<template>
  <div>
    <el-tooltip
      class="item"
      effect="dark"
      placement="top"
      :content="getTooltipContent()"
      :hide-after="3000"
    >
      <i v-if="error" class="el-icon-warning" :style="`color: ${color}`"></i>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  name: "RouteErrorTooltip",

  props: {
    error: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: "#000000"
    }
  },

  methods: {
    getTooltipContent() {
      if (this.error === 1) {
        return "Route error due to timeout.";
      } else if (this.error === 2 || this.error === 3 || this.error === 5) {
        return "Route error due to wrong path.";
      } else if (this.error === 4) {
        return "Route error due to the gateway being offline.";
      } else if (this.error === 6) {
        return "Route interrupted.";
      } else if (this.error === 7) {
        return "Route minimum duration was not reached.";
      } else if (this.error > 7) {
        return "Unknown Error.";
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
