import * as mutationTypes from "@/store/mutation-types";
import { AnchorState } from "../anchors.types";
import { initialState } from "./anchors.store.state";

export default {
  [mutationTypes.ANCHOR_SET_DETAILS](state: AnchorState, anchor: any) {
    state.anchor = anchor;
  },

  [mutationTypes.ANCHOR_SET_ROUTES](state: AnchorState, routes: any) {
    state.routes = routes;
  },

  reset(state: AnchorState) {
    const s = initialState();
    Object.keys(s).forEach(key => {
      (state as any)[key] = (s as any)[key];
    });
  }
};
