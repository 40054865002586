<template>
  <div class="user-account-wrapper">
    <div class="user-account">
      <div class="user-account__container" v-if="isAdmin">
        <el-form
          label-position="top"
          label-width="120px"
          class="user-account__container__form border-bottom"
        >
          <el-form-item label="Client Name">
            <span @click="openEditClientNameDialog()">Edit</span>
            <el-input type="text" :value="clientData.name" disabled></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="user-account__container">
        <el-form
          label-position="top"
          label-width="120px"
          :class="
            isAdmin
              ? 'user-account__container__form border-bottom'
              : 'user-account__container__form'
          "
        >
          <el-form-item label="User Name">
            <span @click="openEditNameDialog()">Edit</span>
            <el-input type="text" :value="user.data.name" disabled></el-input>
          </el-form-item>
          <el-form-item label="Email">
            <el-input type="text" :value="user.data.email" disabled></el-input>
          </el-form-item>

          <el-form-item label="Password">
            <span @click="openChangePasswordDialog()">Edit</span>
            <el-input type="text" value="●●●●●●●●●●●●●" disabled> </el-input>
          </el-form-item>
          <el-form-item
            label="Timezone"
            prop="timezone"
            v-if="!isChangePasswordUser"
          >
            <span v-if="isAdmin" @click="openTimezoneDialog()">Edit</span>
            <el-input type="text" :value="clientData.timezone" disabled>
            </el-input>
          </el-form-item>

          <div class="delete-account-button">
            <el-button
              type="danger"
              class="edit-button"
              size="medium"
              @click.stop="showAccountDeletionDialog = true"
              >Delete Account
            </el-button>
          </div>
        </el-form>
      </div>

      <el-dialog
        v-if="editTimezoneForm.visible"
        title="Edit Timezone"
        :visible.sync="editTimezoneForm.visible"
        width="420px"
        @close="resetTimezone"
      >
        <el-form
          label-position="top"
          :model="editForm"
          ref="editForm"
          label-width="120px"
          class="user-account__account-form"
          hide-required-asterisk
        >
          <el-select
            v-model="editTimezoneForm.value"
            placeholder="Select Timezone"
            filterable
            size="small"
            class="search-input"
          >
            <el-option
              v-for="tz in allTimezones"
              :key="tz"
              :label="tz"
              :value="tz"
            ></el-option>
          </el-select>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editTimezoneForm.visible = false"
            >Cancel</el-button
          >
          <el-button @click="sendNewTimezone" type="primary"> Save</el-button>
        </span>
      </el-dialog>
      <el-dialog
        v-if="editNameForm.visible"
        title="Edit User Name"
        :visible.sync="editNameForm.visible"
        width="420px"
      >
        <el-form
          label-position="top"
          label-width="120px"
          class="user-account__account-form"
        >
          <el-form-item label="Name">
            <el-input
              type="text"
              v-model="editNameForm.value"
              :value="user.data.name"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editNameForm.visible = false">Cancel</el-button>
          <el-button @click="sendNewName" type="primary"> Save</el-button>
        </span>
      </el-dialog>

      <el-dialog
        v-if="editClientNameForm.visible"
        title="Edit Client Name"
        :visible.sync="editClientNameForm.visible"
        width="420px"
      >
        <el-form
          label-position="top"
          label-width="120px"
          class="user-account__account-form"
        >
          <el-form-item label="Name">
            <el-input
              type="text"
              v-model="editClientNameForm.value"
              :value="clientData.name"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editClientNameForm.visible = false"
            >Cancel</el-button
          >
          <el-button @click="sendNewClientName" type="primary"> Save</el-button>
        </span>
      </el-dialog>

      <el-dialog
        title="Confirm Account Deletion"
        :visible.sync="showAccountDeletionDialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <div>
          <span
            >Type 'Delete' into the input box to confirm account deletion.
          </span>
          <el-input
            class="delete-input"
            placeholder="Delete"
            v-model="typeDelete"
          ></el-input>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showAccountDeletionDialog = false" size="small"
            >Cancel</el-button
          >
          <el-button
            :disabled="typeDelete != 'Delete'"
            type="danger"
            @click="deleteAccount"
            size="small"
            >Delete Account</el-button
          >
        </span>
      </el-dialog>

      <router-view />
    </div>

    <user-users-list v-if="isAdmin && !isChangePasswordUser" />
  </div>
</template>

<script lang="js">

import { mapState, mapGetters, mapActions } from 'vuex'
import moment from 'moment-timezone'
import * as routerTypes from '@/router/router.types.ts';
import * as actionTypes from "@/store/action-types.ts";
import clientsApi from "@/modules/clients/api/clients.api";
import usersApi from "@/modules/user/api/user.api";
import userUsersList from "@/views/user/components/user-users-list.vue";


export default {
  components: {
    userUsersList
  },

  computed: {
    ...mapState("auth", {
      user: "user"
    }),
    ...mapGetters("auth", ["isChangePasswordUser", "isAdmin"]),
    ...mapState("clients", {
      clientData: "data"
    }),
  },

  data() {
    return {
      allTimezones: moment.tz.names(),
      editTimezoneForm: {
        visible: false,
        value: ""
      },
      editNameForm: {
        visible: false,
        value: ""
      },
      editClientNameForm: {
        visible: false,
        value: ""
      },
      typeDelete: '',
      showAccountDeletionDialog: false
    }
  },

  methods: {
    ...mapActions("clients", {
      deleteUser: actionTypes.CLIENTS_DELETE_USER
    }),
    ...mapActions({
      resetStore: "resetStore"
    }),
    openChangePasswordDialog () {
      this.$router.push({
        name: routerTypes.ROUTE_USER_CHANGE_PASSWORD,
        params: {
          tab: 'account'
        }
      })
    },
    async deleteAccount() {
      try {
        await this.deleteUser(this.user.data.id);
        this.$notify({
          title: "Success",
          message: "Account deleted",
          type: "success"
        });
      } catch (error) {
        this.$notify.error({
          title: "Error",
          message: "Something went wrong"
        });
      } finally {
        const loading = this.$loading({
          lock: true,
          text: "Logging out"
        });
        setTimeout(() => {
          loading.close();
          window.localStorage.removeItem("csrf");
          window.localStorage.removeItem("map-zoom");
          window.localStorage.removeItem("map-center");
          this.resetStore();
          this.$router.push({
            name: routerTypes.ROUTE_LOGIN
          });
        }, 1250); // This setTimeout was intentionally placed due to UX purposes and logic constraints
      }
    },
    openEditClientNameDialog() {
      this.editClientNameForm.visible = true
      this.editClientNameForm.value = this.clientData.name
    },
    openEditNameDialog() {
      this.editNameForm.visible = true
      this.editNameForm.value = this.user.data.name
    },
    async sendNewClientName() {
      try {
        const res = await clientsApi.updateClient({
          email: this.clientData.email,
          name: this.editClientNameForm.value
        }, this.clientData.id)
        this.clientData.name = res.data.name
        this.editClientNameForm.visible = false
        this.$forceUpdate();
        this.$notify({
          title: "Success",
          message: "Account updated",
          type: "success"
        });
      } catch (error) {
        this.$notify.error({
          title: "Error",
          message: "Something went wrong"
        });
      }
    },
    async sendNewName() {
      try {
        const res = await usersApi.editUser(this.user.data.id, {
          name: this.editNameForm.value
        })
        this.user.data.name = res.data.name
        this.editNameForm.visible = false
        this.$forceUpdate();
        this.$notify({
          title: "Success",
          message: "Account updated",
          type: "success"
        });
      } catch (error) {
        this.$notify.error({
          title: "Error",
          message: "Something went wrong"
        });
      }
    },
    openTimezoneDialog() {
      this.editTimezoneForm.visible = true,
      this.editTimezoneForm.value = this.clientData.timezone
    },
    async sendNewTimezone() {
      try {
        const res = await clientsApi.updateClient({
        email: this.clientData.email,
        name: this.clientData.name,
        timezone: this.editTimezoneForm.value
        }, this.clientData.id)
        this.clientData.timezone = res.data.timezone
        this.editTimezoneForm.visible = false
        this.$forceUpdate();
        this.$notify({
          title: "Success",
          message: "Account updated",
          type: "success"
        });
      } catch (error) {
        this.$notify.error({
          title: "Error",
          message: "Something went wrong"
        });
      }

    }
  }
};
</script>

<style lang="scss">
.user-account-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.user-account {
  padding: 24px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .hr {
    width: 821px;
  }

  &__container {
    display: flex;
    justify-content: center;

    &__form {
      flex: 0 0 250px;
      padding: 24px;

      @media (min-width: 1200px) {
        flex: 0 0 821px;
      }
      @media (min-width: 580px) and (max-width: 1200px) {
        flex: 0 0 500px;
      }
    }

    .border-bottom {
      border-bottom: 2px solid;
    }
  }

  .delete-input {
    margin-top: 1.5vh;
  }

  .el-form-item label {
    display: block;
    padding: 0;
    padding-left: 2px;
    line-height: 20px;

    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
  }

  .el-input.is-disabled .el-input__inner {
    cursor: text;
    color: $--color-primary;
  }

  .el-form-item__content {
    position: relative;

    span {
      position: absolute;
      right: 2px;
      top: -28px;
      cursor: pointer;
    }
  }

  .el-dialog {
    width: 500px;

    @media (max-width: 860px) {
      width: 400px;
    }
  }
}
</style>
