import axios, { AxiosInstance } from "axios";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";

export class HttpRequest {
  protected axiosClient: AxiosInstance;

  constructor() {
    this.axiosClient = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      withCredentials: true
    });

    this.axiosClient.interceptors.request.use(config => {
      const csrf = window.localStorage.getItem("csrf");
      if (csrf) {
        config.headers.Authorization = `Bearer ${csrf}`;
      }

      if (
        config.headers["Content-Type"] === "application/json" &&
        config.data
      ) {
        config.data = snakecaseKeys(config.data, { deep: true });
      }

      return config;
    });

    this.axiosClient.interceptors.response.use(response => ({
      ...response,
      data: camelcaseKeys(response.data, { deep: true })
    }));
  }
}
