<template>
  <div class="wrapper">
    <dashboard
      :show-anchors="true"
      :interval-update="true"
      :anchorsToShow="anchorsToShow"
    >
      <div class="sidebar">
        <p class="select-desc">
          <b>Select the Gateways to show on the map:</b>
        </p>
        <el-checkbox-group v-model="anchorsToShowIds" class="check-group">
          <template v-for="anchor in anchors.data">
            <el-checkbox
              :label="anchor.anchorId"
              :key="anchor.id"
              border
              class="checkbox-item"
            >
              <div class="anchor-label-wrapper">
                <span
                  class="anchor-label"
                  v-bind:style="{ color: offlineColor(anchor) }"
                >
                  {{ anchor.label }}
                </span>
                <span
                  class="no-wifi-span"
                  v-if="isAnchorOffline(anchor.powerSource, anchor.offlineTs)"
                >
                  <wifi-icon :signal="0" :noConnection="true" />
                </span>
                <span
                  class="no-battery-span"
                  v-if="
                    isAnchorPowerSourceDisconnected(
                      anchor.lastHeartbeat ? anchor.lastHeartbeat : null
                    )
                  "
                >
                  <span>
                    <img src="@/assets/battery_dead.svg" alt="no battery" />
                  </span>
                </span>
                <span v-if="anchor.currentRouteInstance">
                  {{ " - " }}
                  <span
                    v-bind:style="{
                      color: computedColor(anchor),
                      fontWeight: computedWeight(anchor)
                    }"
                  >
                    {{ anchor.currentTime }}
                  </span>
                </span>
                <span
                  v-if="anchor.activeRouteOnTime"
                  v-bind:style="{
                    color: computedDelay(anchor),
                    fontWeight: computedWeight(anchor)
                  }"
                >
                  {{ " (" + getRouteDelay(anchor) + ")" }}
                </span>
              </div>
              <div
                v-bind:style="{
                  background: computedBackground(anchor),
                  position: 'absolute',
                  left: '93%',
                  top: '25%'
                }"
                id="circle"
              ></div>
            </el-checkbox>
          </template>
        </el-checkbox-group>
      </div>
    </dashboard>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import * as actionTypes from "@/store/action-types";
import moment from "moment-timezone";
import Dashboard from "../Dashboard.vue";
import { cloneDeep } from "lodash";
import wifiIcon from "@/components/wifi-icon.vue";

export default {
  components: { Dashboard, wifiIcon },
  computed: {
    ...mapState("clients", {
      anchors: "anchors",
      selectedAnchor: "selectedAnchor",
      clientData: "data"
    }),
    ...mapState("auth", {
      user: "user"
    }),
    ...mapState("user", {
      resources: "resources"
    })
  },
  data() {
    return {
      moment,
      interval: null,
      intervalResources: null,
      anchorsToShow: [],
      anchorsToShowIds: [],
      anchorNumber: 0,
      colors: [
        "#32CD32",
        "#0A75AD",
        "#151517",
        "#CDC115",
        "#5B099F",
        "#FF0033",
        "#CC8400"
      ]
    };
  },
  watch: {
    anchorsToShowIds: {
      handler(newValue) {
        const auxArray = cloneDeep(this.anchorsToShow);
        this.anchorsToShow = [];
        //let currIndex = 0;
        // const tempColors = [];
        newValue.forEach(element => {
          const foundItem = auxArray.find(item => item[0].anchorId === element);
          const foundAnchor = this.anchors.data.find(
            item => item.anchorId === element
          );
          if (foundItem != null) {
            this.anchorsToShow.push([foundAnchor, foundAnchor.color]);
            //tempColors.push(foundItem[1]);
          } else {
            /*let color = this.colors[currIndex];
            while (tempColors.includes(color)) {
              currIndex += 1;
              color = this.colors[currIndex];
            }*/
            this.anchorsToShow.push([foundAnchor, foundAnchor.color]);
            //tempColors.push(color);
            //currIndex += 1;
          }
        });
      }
    },

    "resources.data": {
      deep: true,
      async handler(newValue) {
        if (newValue.anchors.length) {
          this.anchors.data.forEach(element => {
            newValue.anchors.forEach(newElement => {
              if (element.anchorId === newElement.anchorId) {
                element.activeRouteOnTime = newElement.activeRouteOnTime;
                element.activeRouteStart = newElement.activeRouteStart;
                element.lastHeartbeat = newElement.lastHeartbeat;
              }
            });
          });
        }
      }
    },

    "resources.globalAnchors": {
      deep: true,
      async handler(newValue) {
        this.overlappingAnchors = 0;
        const map = new Map();
        this.anchors.data.forEach(anchor => {
          if (
            map.has(anchor.lastPosition?.id) &&
            !this.isAnchorOffline(anchor.powerSource, anchor.offlineTs)
          ) {
            map.set(anchor.lastPosition.id, [anchor]);
          } else if (
            !this.isAnchorOffline(anchor.powerSource, anchor.offlineTs)
          ) {
            map.set(anchor.lastPosition.id, 1);
          }
          newValue.forEach(newAnchor => {
            if (anchor.anchorId === newAnchor.anchorId) {
              const hours = this.getRouteTotalHours(anchor);
              const minutes = this.getRouteTotalMinutes(anchor);
              const seconds = this.getRouteTotalSeconds(anchor);

              anchor.currentTime = hours + minutes + "m" + seconds + "s";
              if (anchor.currentRouteInstance)
                if (anchor.currentRouteInstance.startDate)
                  anchor.currentRouteInstance.startDate = newAnchor.startDate;
                else anchor.currentTime = "";
            }
          });
        });
      }
    }
  },
  methods: {
    ...mapActions("clients", {
      getAnchors: actionTypes.CLIENTS_GET_ANCHORS,
      updateSelectedAnchor: actionTypes.CLIENTS_UPDATE_SELECTED_ANCHOR,
      updateGlobalView: actionTypes.CLIENTS_UPDATE_GLOBAL_VIEW
    }),
    ...mapActions("user", {
      getResources: actionTypes.USER_GET_RESOURCES
    }),
    computedBackground(anchor) {
      const foundItem = this.anchorsToShow.find(
        item => item[0].anchorId === anchor.anchorId
      );
      if (foundItem != null) return foundItem[1];
      return "";
    },
    isAnchorPowerSourceDisconnected(heartbeat) {
      if (heartbeat) if (!heartbeat.powerSource) return true;
      return false;
    },
    isAnchorOffline(powerSource, offlineTs) {
      if (!offlineTs) return false;
      else {
        const sixMinutesAgo = moment()
          .utc()
          .subtract(6, "minutes");

        if (!powerSource) {
          if (
            moment
              .tz(moment.unix(offlineTs), this.clientData.timezone)
              .isBefore(sixMinutesAgo)
          ) {
            return true;
          }
          return false;
        } else {
          const oneMinuteAgo = moment()
            .utc()
            .subtract(60, "seconds");

          if (
            moment
              .tz(moment.unix(offlineTs), this.clientData.timezone)
              .isBefore(oneMinuteAgo)
          ) {
            return true;
          }
        }
      }
      return false;
    },
    offlineColor(anchor) {
      if (this.isAnchorOffline(anchor.powerSource, anchor.offlineTs))
        return "gray";
      else return "#1F4258";
    },
    updateCurrentTime() {
      this.anchors.data.forEach(anchor => {
        const hours = this.getRouteTotalHours(anchor);
        const minutes = this.getRouteTotalMinutes(anchor);
        const seconds = this.getRouteTotalSeconds(anchor);
        anchor.currentTime = hours + minutes + "m" + seconds + "s";
      });
      this.$forceUpdate();
    },
    computedDelay(anchor) {
      if (this.isAnchorOffline(anchor.powerSource, anchor.offlineTs)) {
        if (anchor.activeRouteOnTime == null) return "gray";
        else {
          if (anchor.activeRouteOnTime[0] === 0) return "gray";
          else if (anchor.activeRouteOnTime[0] === -1) return "green";
          else return "red";
        }
      }
      if (anchor.activeRouteOnTime == null) return "#1F4258";
      else {
        if (anchor.activeRouteOnTime[0] === 0) return "#1F4258";
        else if (anchor.activeRouteOnTime[0] === -1) return "green";
        else return "red";
      }
    },
    computedColor(anchor) {
      if (this.isAnchorOffline(anchor.powerSource, anchor.offlineTs))
        return "gray";
      if (anchor.activeRouteOnTime == null) return "#1F4258";
      else {
        if (anchor.activeRouteOnTime[0] === 0) return "#1F4258";
        else if (anchor.activeRouteOnTime[0] === -1) return "green";
        else return "red";
      }
    },
    computedWeight(anchor) {
      if (
        anchor.activeRouteOnTime == null ||
        this.isAnchorOffline(anchor.powerSource, anchor.offlineTs)
      )
        return "normal";
      else {
        return anchor.activeRouteOnTime[0] === 0 ? "normal" : "bold";
      }
    },
    getRouteTotalHours(info) {
      if (info.currentRouteInstance) {
        const currentTime = moment.tz(this.clientData.timezone);

        const routeStart = moment.tz(
          moment.unix(info.currentRouteInstance.startDate),
          this.clientData.timezone
        );

        if (Math.floor(currentTime.diff(routeStart, "seconds") / 3600) >= 1)
          return (
            Math.floor(
              currentTime.diff(routeStart, "seconds") / 3600
            ).toString() + "h"
          );
        else return "";
      } else return "";
    },

    getCurrentTime(info) {
      this.anchors.data.forEach(element => {
        if (info.anchorId === element.anchorId) return element.currentTime;
      });
    },

    getRouteTotalMinutes(info) {
      if (info.currentRouteInstance) {
        const minutes = Math.floor(
          (moment()
            .utc()
            .diff(
              moment.tz(
                moment.unix(info.currentRouteInstance.startDate),
                this.clientData.timezone
              ),
              "seconds"
            ) %
            3600) /
            60
        );

        return minutes < 10 ? "0" + minutes : minutes;
      } else return "";
    },

    getRouteTotalSeconds(info) {
      if (info.currentRouteInstance) {
        const secondsDiff = Math.floor(
          (moment()
            .utc()
            .diff(
              moment.tz(
                moment.unix(info.currentRouteInstance.startDate),
                this.clientData.timezone
              ),
              "seconds"
            ) %
            3600) %
            60
        );
        if (secondsDiff < 10) return "0" + secondsDiff;
        else return secondsDiff;
      } else return "";
    },
    getRouteDelay(info) {
      let hours;
      let minutes;

      if (info.activeRouteOnTime[1] >= 0) {
        hours = Math.floor(info.activeRouteOnTime[1] / 3600);
        minutes = Math.floor((info.activeRouteOnTime[1] % 3600) / 60);
      } else {
        hours = Math.ceil(info.activeRouteOnTime[1] / 3600);
        minutes = Math.ceil((info.activeRouteOnTime[1] % 3600) / 60);
      }

      const minutesString = Math.abs(minutes).toString();

      if (Math.abs(hours) >= 1)
        return (
          (minutes > 0 ? "+" + hours.toString() : hours.toString()) +
          "h:" +
          (minutes < 10 && minutes > -10
            ? "0" + minutesString
            : minutesString) +
          "m"
        );
      else return (minutes > 0 ? "+" + minutesString : minutesString) + "m";
    }
  },
  mounted() {
    let currIndex = 0;
    this.anchors.data.forEach(element => {
      element.color = this.colors[currIndex];
      currIndex += 1;
      if (!this.isAnchorOffline(element.powerSource, element.offlineTs)) {
        this.anchorsToShow.push([element, this.colors[currIndex]]);
      }
    });
    //this.anchorsToShow = cloneDeep(this.anchors.data);
    this.anchorsToShow.forEach(element => {
      if (!this.isAnchorOffline(element.powerSource, element.offlineTs)) {
        this.anchorsToShowIds.push(element[0].anchorId);
        this.anchorNumber++;
      }
    });
    this.updateGlobalView(true);
    this.currentTimeInterval = setInterval(this.updateCurrentTime, 2000);
  }
};
</script>

<style lang="scss">
.wrapper {
  .check-group {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    margin-left: 9%;
  }

  .wifi {
    position: relative;
    display: block;

    &__no-connection {
      left: 0;
    }
  }

  .no-wifi-span {
    position: absolute;
    display: block;
    margin: 0 auto;
    overflow: hidden;
    top: -10px;
    right: 60px;
    scale: 0.7;
  }

  .no-battery-span {
    position: absolute;
    right: 40px;
  }

  .checkbox-item {
    margin-top: 10px;
    width: 85%;
    text-align: left;
    margin-left: 2%;
  }

  .anchor-label-wrapper {
    text-align: center;
    justify-content: center;
    display: flex;
  }

  .anchor-label {
    max-width: 10em;
    margin-right: 5px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  #circle {
    position: absolute;
    width: 20px;
    height: 20px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
  }

  .select-desc {
    margin-top: 5%;
  }

  .sidebar {
    display: block !important;
    position: fixed;
    top: 104px;
    right: 0;
    bottom: 0;
    padding-top: 60px;
    padding-bottom: 20px;
    background: $--border-color-extra-light;
    width: 480px;
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    border-left: 1px solid #dcdfe6;
  }
}
</style>
