import { HttpRequest } from "@/services/api/http";

export default new (class RoutesApi extends HttpRequest {
  constructor() {
    super();
  }

  public async createAnchorRoute(data: any) {
    return this.axiosClient.post(`/routes/anchor`, data);
  }

  public async resetRoute(routeId: number) {
    return this.axiosClient.put(`/routes/reset_route/${routeId}`);
  }

  public async editSettings(routeId: number, payload: any) {
    return this.axiosClient.put(`/routes/settings/${routeId}`, payload);
  }

  public async fetchMostCommonRoute(anchorId: number, tagPositionId: number) {
    return this.axiosClient.get(
      `/routes/route_analyser/${anchorId}/${tagPositionId}`
    );
  }

  public async getAnchorRoutes(anchorId: number, query?: Record<string, any>) {
    let finalQuery = "";
    if (query) {
      finalQuery += "?";
      for (const [key, value] of Object.entries(query)) {
        finalQuery += `${key}=${value}&`;
      }
      finalQuery += "order=desc";
    }

    const res = await this.axiosClient.get(
      `/routes/anchor/${anchorId}${finalQuery || ""}`
    );

    // Add some extra fields to re-use dashboard component
    res.data.forEach((entry: any) => {
      entry.tags.forEach((tag: any) => {
        tag.active = true;
        tag.tagId = tag.tagPosition.tagId;
        tag.lastPosition = tag.tagPosition;
      });
    });

    return res;
  }

  public async getPositionsCSV(
    anchorId: number,
    startTime: number,
    endTime: number,
    startShift: number,
    endShift: number,
    routeInstanceId: number
  ) {
    return this.axiosClient.get(
      `/analytics/positions/${anchorId}/${startTime}/${endTime}/${startShift}/${endShift}${
        routeInstanceId ? "?route_instance_id=" + routeInstanceId : ""
      }`
    );
  }

  public async getRouteInstancesCSV(
    routeId: number,
    startTime: number,
    endTime: number,
    minDuration: number,
    maxDuration: number,
    startShift: number,
    endShift: number,
    valid: boolean,
    anchorIds?: Array<number>
  ) {
    return this.axiosClient.get(
      `/analytics/routes/${routeId}/instances/${startTime}/${endTime}/${startShift}/${endShift}${"?min_duration=" +
        minDuration}${
        maxDuration > 0 ? "&max_duration=" + maxDuration : ""
      }${"&valid=" + valid}${
        anchorIds && anchorIds.length > 0
          ? anchorIds.map(id => `&anchor_ids=${id}`).join("")
          : ""
      }
      `
    );
  }

  public async getRouteDetailsCSV(
    routeId: number,
    startTime: number,
    endTime: number,
    minDuration: number,
    maxDuration: number,
    anchorIds?: Array<number>
  ) {
    return this.axiosClient.get(
      `/analytics/routes/${routeId}/details/${startTime}/${endTime}${"?min_duration=" +
        minDuration}${maxDuration > 0 ? "&max_duration=" + maxDuration : ""}${
        anchorIds && anchorIds.length > 0
          ? anchorIds.map(id => `&anchor_ids=${id}`).join("")
          : ""
      }`
    );
  }

  public async getRouteCheckpoints(
    anchorId: number,
    startDate: number,
    endDate: number,
    minDuration: number,
    maxDuration: number,
    startShift?: number,
    endShift?: number
  ) {
    return this.axiosClient.get(
      `/routes/checkpoints/anchor/${anchorId}/${startDate}/${endDate}${
        startShift ? "/" + startShift : ""
      }${endShift ? "/" + endShift : ""}${"?min_duration=" + minDuration}${
        maxDuration > 0 ? "&max_duration=" + maxDuration : ""
      }`
    );
  }

  public async getRouteInstancesByRouteId(
    routeId: number,
    startDate: number,
    endDate: number,
    minDuration: number,
    maxDuration: number,
    startShift?: number,
    endShift?: number,
    anchorIds?: Array<number>
  ) {
    return this.axiosClient.get(
      `/routes/instances/anchor/${routeId}/${startDate}/${endDate}${
        startShift ? "/" + startShift : ""
      }${endShift ? "/" + endShift : ""}${"?min_duration=" + minDuration}${
        maxDuration > 0 ? "&max_duration=" + maxDuration : ""
      }${
        anchorIds && anchorIds.length > 0
          ? anchorIds.map(id => `&anchor_ids=${id}`).join("")
          : ""
      }`
    );
  }

  public async addAnchorToRoute(routeId: number, anchors: any) {
    return this.axiosClient.put(`/routes/add_anchor/${routeId}`, anchors);
  }

  public async addRouteToAnchor(anchorId: number, routes: any) {
    return this.axiosClient.put(`/routes/add_route/${anchorId}`, routes);
  }

  public async getRouteList() {
    return this.axiosClient.get(`/routes/list`);
  }

  public async getRouteHistory(anchorId: number) {
    return this.axiosClient.get(`/routes/history/${anchorId}`);
  }

  public async deleteRoute(routeId: number) {
    return this.axiosClient.delete(`/routes/${routeId}`);
  }
})();
