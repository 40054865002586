<template>
  <div class="user-layout">
    <div v-if="users.loading" v-loading="true" class="user-users__loading" />
    <div v-else-if="users.error">
      Something went wrong. Contact the admin.
    </div>
    <template v-else>
      <div>
        <h3 class="sidebar-title">
          Update Layout Image
        </h3>
        <div class="file-upload">
          <input
            type="file"
            accept="image/*"
            @change="setImage($event)"
            id="file-input"
          />
        </div>
        <div class="upload-button">
          <el-button type="primary" size="small" @click="uploadImage">
            Upload
          </el-button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import * as actionTypes from "@/store/action-types.ts";
import { mapState, mapActions } from "vuex";
import clientsApi from "@/modules/clients/api/clients.api";

export default {
  computed: {
    ...mapState("clients", {
      users: "users"
    }),
    ...mapState("auth", {
      user: "user"
    }),
    ...mapState("user", {
      discardChanges: "discardChanges"
    })
  },

  data() {
    return {
      imageToUpload: null,
      unsavedChangesDialog: false
    };
  },

  watch: {
    discardChanges(newValue) {
      if (newValue === true) {
        this.unsavedChangesDialog = true;
      }
    }
  },

  methods: {
    ...mapActions("clients", {
      getClient: actionTypes.CLIENTS_GET_CLIENT
    }),
    ...mapActions("user", {
      setIsChanging: actionTypes.USER_SET_IS_CHANGING,
      setDiscard: actionTypes.USER_SET_DISCARD
    }),
    handleDelete(index, row) {
      this.userToDelete = row;
      this.deleteUserDialog.visible = true;
    },
    setImage(event) {
      this.imageToUpload = event.target.files[0];
      this.setIsChanging(true);
    },
    continueEditing() {
      this.unsavedChangesDialog = false;
      this.setDiscard(false);
    },
    discardChangesFunction() {
      this.imageToUpload = null;
      this.setDiscard(false);
      this.setIsChanging(false);
    },
    async uploadImage() {
      const data = new FormData();
      data.append("image", this.imageToUpload);

      try {
        await clientsApi.uploadImage(data, this.user.data.clientId);
        this.$notify({
          title: "Success",
          message: "Image uploaded successfully",
          type: "success"
        });
        this.getClient(this.user.data.clientId);
        this.setIsChanging(false);
      } catch (err) {
        this.$notify.error({
          title: "Error",
          message: "Something went wrong. Try again later."
        });
      }
    }
  }
};
</script>

<style lang="scss">
.user-layout {
  padding: 24px 0;

  .file-upload {
    margin-top: 20px;
  }

  .file-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2%;

    @media (max-width: 840px) {
      margin-top: 15px;
    }

    input {
      display: flex;
      text-align: center;
      justify-content: center;
      margin-left: 3%;
    }
  }

  .upload-button {
    margin-top: 2%;

    @media (max-width: 840px) {
      margin-top: 15px;
    }
  }

  &__container {
    display: flex;
    justify-content: center;

    .el-table {
      flex: 0 0 741px;

      .cell .column-btn {
        display: flex;
        justify-content: center;
      }
    }
  }

  &__new.el-button {
    margin-bottom: 24px;
  }
}
</style>
