import { HttpRequest } from "@/services/api/http";

export default new (class PositionLogsApi extends HttpRequest {
  constructor() {
    super();
  }

  public async createTags(tags: any) {
    return this.axiosClient.put(`/position_logs/tags`, tags);
  }

  public async updateTagPosition(tagId: number, tagInfo: any) {
    return this.axiosClient.put(`/position_logs/tag/${tagId}`, tagInfo);
  }

  public async getAnalyticsLogs(
    anchorId: null,
    timestampStart: number,
    timestampEnd: number,
    shiftStart: number,
    shiftEnd: number,
    routeInstanceId?: number,
    page?: number,
    pageSize?: number
  ) {
    return this.axiosClient.get(
      `/position_logs/analytics/${anchorId}/${timestampStart}/${timestampEnd}/${shiftStart}/${shiftEnd}${"?page=" +
        page}${pageSize ? "&page_size=" + pageSize : ""}${
        routeInstanceId ? "&route_instance_id=" + routeInstanceId : ""
      }`
    );
  }

  public async getTripFrequency(
    anchorId: null,
    timestampStart: number,
    timestampEnd: number,
    routeInstanceId?: number
  ) {
    return this.axiosClient.get(
      `/position_logs/trip_frequency/${anchorId}/${timestampStart}/${timestampEnd}${
        routeInstanceId ? "?route_instance_id=" + routeInstanceId : ""
      }`
    );
  }

  public async getAnchorPositionLogsFromTo(
    anchorId: null,
    startTime: number,
    endTime: number,
    startShift?: number,
    endShift?: number,
    routeInstanceId?: number
  ) {
    return this.axiosClient.get(
      `/position_logs/anchor/${anchorId}/${startTime}/${endTime}${
        startShift ? "/" + startShift : ""
      }${endShift ? "/" + endShift : ""}${
        routeInstanceId ? "?route_instance_id=" + routeInstanceId : ""
      }`
    );
  }
})();
